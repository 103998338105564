import { createSlice } from "@reduxjs/toolkit";
import {
    getCheckoutitems,
    applyCoupon,
    removeCoupon,
    shippingCalculate,
    checkoutshippingCalculate,
    shippingMethodUps,
    generateSecret,
    paypalPayment,
    paypalCapture,
    orderDetailsSecret,
    cancelOrder
} from "../../api";

const initialState = {
    isgetcheckoutitemsLoading: false,
    isgetcheckoutitemsData: null,
    isapplycouponLoading: false,
    isapplycouponData: null,
    isremovecouponLoading: false,
    isremovecouponData: null,
    isshippingcalculateLoading: false,
    isshippingcalculateData: null,
    ischeckoutshippingcalculateLoading: false,
    ischeckoutshippingcalculateData: null,
    isshippingMethodUpsLoading: false,
    isshippingMethodUpsData: null,
    isgeneratesecretLoading: false,
    isgeneratesecretData: null,
    ispaypalPaymentLoading: false,
    ispaypalPaymentData: null,
    ispaypalCaptureLoading: false,
    ispaypalCaptureData: null,
    isorderDetailsSecretLoading: false,
    isorderDetailsSecretData: null,
    iscancelOrderLoading: false,
    iscancelOrderData: null,
};

export const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {},
    extraReducers: {

        [getCheckoutitems.pending]: (state) => {
            state.isgetcheckoutitemsLoading = true;
        },
        [getCheckoutitems.fulfilled]: (state, { payload }) => {
            state.isgetcheckoutitemsLoading = false;
            state.isgetcheckoutitemsData = payload.data;
        },
        [getCheckoutitems.rejected]: (state) => {
            state.isgetcheckoutitemsLoading = false;
        },

        [applyCoupon.pending]: (state) => {
            state.isapplycouponLoading = true;
        },
        [applyCoupon.fulfilled]: (state, { payload }) => {
            state.isapplycouponLoading = false;
            state.isapplycouponData = payload;
        },
        [applyCoupon.rejected]: (state) => {
            state.isapplycouponLoading = false;
        },

        [removeCoupon.pending]: (state) => {
            state.isremovecouponLoading = true;
        },
        [removeCoupon.fulfilled]: (state, { payload }) => {
            state.isremovecouponLoading = false;
            state.isremovecouponData = payload.data;
        },
        [removeCoupon.rejected]: (state) => {
            state.isremovecouponLoading = false;
        },

        [shippingCalculate.pending]: (state) => {
            state.isshippingcalculateLoading = true;
        },
        [shippingCalculate.fulfilled]: (state, { payload }) => {
            state.isshippingcalculateLoading = false;
            state.isshippingcalculateData = payload;
        },
        [shippingCalculate.rejected]: (state) => {
            state.isshippingcalculateLoading = false;
        },

        [checkoutshippingCalculate.pending]: (state) => {
            state.ischeckoutshippingcalculateLoading = true;
        },
        [checkoutshippingCalculate.fulfilled]: (state, { payload }) => {
            state.ischeckoutshippingcalculateLoading = false;
            state.ischeckoutshippingcalculateData = payload;
        },
        [checkoutshippingCalculate.rejected]: (state) => {
            state.ischeckoutshippingcalculateLoading = false;
        },

        [shippingMethodUps.pending]: (state) => {
            state.isshippingMethodUpsLoading = true;
        },
        [shippingMethodUps.fulfilled]: (state, { payload }) => {
            state.isshippingMethodUpsLoading = false;
            state.isshippingMethodUpsData = payload.data;
        },
        [shippingMethodUps.rejected]: (state) => {
            state.isshippingMethodUpsLoading = false;
        },

        [generateSecret.pending]: (state) => {
            state.isgeneratesecretLoading = true;
        },
        [generateSecret.fulfilled]: (state, { payload }) => {
            state.isgeneratesecretLoading = false;
            state.isgeneratesecretData = payload;
            localStorage.setItem('client_secret', payload?.data.client_secret);
        },
        [generateSecret.rejected]: (state) => {
            state.isgeneratesecretLoading = false;
        },

        [paypalPayment.pending]: (state) => {
            state.ispaypalPaymentLoading = true;
        },
        [paypalPayment.fulfilled]: (state, { payload }) => {
            state.ispaypalPaymentLoading = false;
            state.ispaypalPaymentData = payload;
            localStorage.setItem('client_secret', payload?.data.client_secret);
        },
        [paypalPayment.rejected]: (state) => {
            state.ispaypalPaymentLoading = false;
        },

        [paypalCapture.pending]: (state) => {
            state.ispaypalCaptureLoading = true;
        },
        [paypalCapture.fulfilled]: (state, { payload }) => {
            state.ispaypalCaptureLoading = false;
            state.ispaypalCaptureData = payload;
        },
        [paypalCapture.rejected]: (state) => {
            state.ispaypalCaptureLoading = false;
        },

        [cancelOrder.pending]: (state) => {
            state.iscancelOrderLoading = true;
        },
        [cancelOrder.fulfilled]: (state, { payload }) => {
            state.iscancelOrderLoading = false;
            state.iscancelOrderData = payload;
        },
        [cancelOrder.rejected]: (state) => {
            state.iscancelOrderLoading = false;
        },

        [orderDetailsSecret.pending]: (state) => {
            state.isorderDetailsSecretLoading = true;
        },
        [orderDetailsSecret.fulfilled]: (state, { payload }) => {
            state.isorderDetailsSecretLoading = false;
            state.isorderDetailsSecretData = payload;
        },
        [orderDetailsSecret.rejected]: (state) => {
            state.isorderDetailsSecretLoading = false;
        },

    },
});

// Action creators are generated for each case reducer function
export const { } = checkoutSlice.actions;

export default checkoutSlice.reducer;
