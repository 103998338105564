import React from 'react'
import { relatedProductLoading, getCreateCartLoading, relatedProductData, addRemoveCartItemLoading } from '../../selector'
import { addRemoveCartItem, createCart, getProductDetails, relatedProduct } from '../../api'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material'
import Loader from '../Loader/Loader'

const RelatedProducts = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isrelatedProductData = useSelector(relatedProductData)
    const isaddRemoveCartItemLoading = useSelector(addRemoveCartItemLoading)
    const isrelatedProductLoading = useSelector(relatedProductLoading)
    const CreateCartLoading = useSelector(getCreateCartLoading)
    const currency_id = localStorage.getItem('currency_id');

    const AddToCart = async (product) => {
        const cart_key = localStorage.getItem('cart_key');
        if (!cart_key) {
            await dispatch(createCart());
        }
        await dispatch(addRemoveCartItem({ product_id: product?.id, quantity: 1 }))

    }

    const getRelatedProductDetails = async (prod) => {
        await navigate(`/product/${prod?.slug}`)
        dispatch(getProductDetails({ params: { currency: currency_id } }))
    }

    React.useEffect(() => {
        dispatch(relatedProduct({ params: { currency: currency_id } }))
    }, [])


    return (
        <>
            {isrelatedProductLoading ? <Loader /> :
                <>
                    {isrelatedProductData?.products?.length !== 0 &&
                        <Box className='recent-view-products'>
                            <Container className='container'>
                                <h1 className='howUse-heading'>Related Products</h1>
                                <Grid container spacing={2} className='filter-products'>
                                    <>
                                        {isrelatedProductData?.products?.map((prod, indx) => (
                                            <Grid item xs={12} sm={6} md={3} className='filter-products-grid' key={indx}>
                                                <Box className='productImage'>
                                                    <Box
                                                        onClick={() => getRelatedProductDetails(prod)} className='recent-product'>
                                                        <img className='grid-image' src={prod?.image_path} />
                                                    </Box>
                                                    <div className='filter-quick-btn'>
                                                        {prod.has_sizes === true ?
                                                            <Link to={`/product/${prod?.slug}`}>
                                                                <button>
                                                                    <Typography> Select Size </Typography>
                                                                    <span className='quick-btn-span'>
                                                                        <i className='fa-solid fa-plus'></i>
                                                                    </span>
                                                                </button>
                                                            </Link>
                                                            :
                                                            isaddRemoveCartItemLoading || CreateCartLoading ?
                                                                <button className='quick-cart-loader'>
                                                                    <CircularProgress color="inherit" />
                                                                </button>
                                                                :
                                                                <button onClick={() => AddToCart(prod)}>
                                                                    <Typography> Quick Add</Typography>
                                                                    <span className='quick-btn-span'>
                                                                        <i className='fa-solid fa-plus'></i>
                                                                    </span>
                                                                </button>
                                                        }
                                                    </div>
                                                </Box>
                                                <Box className='product-details'>
                                                    <h3>{prod?.name}</h3>
                                                    {prod.has_sizes === true ?
                                                        <p style={{ fontSize: '16px' }}>{prod?.currency}{prod?.size_price?.min} - {prod?.currency}{prod?.size_price?.max}</p>
                                                        :
                                                        <p style={{ fontSize: '16px' }}>{prod?.currency}{prod?.price}</p>
                                                    }
                                                </Box>
                                                <Box className='product-text'>
                                                    <span>{prod?.category_name}</span>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </>
                                </Grid>
                            </Container>
                        </Box>
                    }
                </>
            }
        </>
    )
}

export default RelatedProducts 