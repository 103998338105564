import { Box } from '@mui/system';
import * as React from 'react';
import './whyCustomer.css';
import Grid from '@mui/material/Grid'; // Grid version 1
import customerImage1 from './img/img1.png';
import customerImage2 from './img/img2.png';
import customerImage3 from './img/img3.png';
import customerImage4 from './img/img4.png';
import customerImage5 from './img/img5.png';
import { getWhyCustomerLoveUs } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { getWhyCustomerLoveUsData } from '../../selector';
import { Container } from '@mui/material';
import ViewImageModal from './ViewImageModal';
import EditIcon from '@mui/icons-material/Edit';

export default function WhyCustomer() {
  const dispatch = useDispatch();
  const [visiblemodal, setVisibleModal] = React.useState(false);
  const [image, setImage] = React.useState(null); // Initialize with null

  const getData = useSelector(getWhyCustomerLoveUsData);

  React.useEffect(() => {
    dispatch(getWhyCustomerLoveUs());
  }, [dispatch]);

  const handleOpenModal = (data) => {
    setVisibleModal(true);
    setImage(data);
  };

  const handleClose = () => {
    setImage(null); // Reset image to null when closing modal
    setVisibleModal(false);
  };

  return (
    <>
      <Box className='WhyCustomer'>
        <h1 className='heading' style={{ paddingLeft: '30px', paddingRight: '30px' }}>
          Why Customers Love us
          <span>
            <i className='fa-solid fa-heart'></i>
          </span>
        </h1>
        <Box className='Whycustomer-images'>
          <Grid container spacing={2} className='Whycustomer-images-content'>
            {getData ? (
              getData.map((ele, i) => (
                <Grid key={i} item xs={10} md={3} className='customer-images-details'>
                  <div className='image-container' onClick={() => handleOpenModal(ele)}>
                    <img src={ele} alt={`Customer Image ${i}`} />
                    <div className='image-overlay'>

                      <EditIcon className='pencil-icon' alt='Pencil Icon' />
                    </div>
                  </div>
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Box>

      </Box>
      {visiblemodal && image && (
        <ViewImageModal image={image} handleClose={handleClose} visiblemodal={visiblemodal} />
      )}
    </>
  );
}
