import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getisorderDetailsData, getisorderDetailsLoading } from '../../selector';
import { orderDetails } from '../../api';
import Loader from '../Loader/Loader';
import './OrderHistory.css'
import { Helmet } from 'react-helmet';

export default function OrderDetails() {
    const isgetisorderDetailsData = useSelector(getisorderDetailsData)
    const isgetisorderDetailsLoading = useSelector(getisorderDetailsLoading)
    const orderDeatilProduct = isgetisorderDetailsData?.order_cartitems?.products

    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(orderDetails())
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <Helmet>
                <title>Order Details</title>
        </Helmet>
            {isgetisorderDetailsLoading ? <Loader /> :
                <Container className='container ctm-order-detail' sx={{ minHeight: '100vh' }}>
                    <Box className='order-nav'>
                        <ul>
                            <li>
                                <Link to='/' className='category-list'>
                                    Home
                                </Link>
                            </li>
                            <span> / </span>
                            <li>
                                <Link to={`/order-history/${isgetisorderDetailsData?.cart}`} className='category-list active'>
                                    Order-Detail
                                </Link>
                            </li>
                        </ul>
                    </Box>
                    <h3 className='main-title' style={{ textTransform: 'uppercase', marginTop: '10px' }}>Order Details</h3>
                    <div className="ctm-order-outer">
                        <Box sx={{ width: 'fit-content' }} className='ctm-box'>
                            <Paper elevation={3} sx={{ p: '20px' }}>
                                <h3 className='small-title' style={{ textTransform: 'uppercase' }}>Order Information</h3>
                                <Typography variant='div' component='div' sx={{ my: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
                                    <h3>Order Invoice Id</h3><span>{isgetisorderDetailsData?.cart}</span>
                                </Typography>
                                <Typography variant='div' component='div' sx={{ my: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
                                    <h3>Order Date</h3><span>{isgetisorderDetailsData?.date}</span>
                                </Typography>
                                <Typography variant='div' component='div' sx={{ my: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
                                    <h3>Order Status</h3><span>{isgetisorderDetailsData?.order_status_text}</span>
                                </Typography>
                                <Typography variant='div' component='div' sx={{ my: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
                                    <h3>Shipping Method</h3><span>{isgetisorderDetailsData?.shipping_information?.shipping_name}</span>
                                </Typography>
                                <Typography variant='div' component='div' sx={{ my: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
                                    <h3>Payment Method</h3><span>{isgetisorderDetailsData?.payment_method}</span>
                                </Typography>
                            </Paper>
                        </Box>
                        <Box sx={{ width: 'fit-content' }} className='ctm-box'>
                            <Paper elevation={3} sx={{ p: '20px' }}>
                                <h3 className='small-title' style={{ textTransform: 'uppercase' }}>Basic Information</h3>
                                <Typography variant='div' component='div' sx={{ my: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
                                    <h3>Name</h3><span>{isgetisorderDetailsData?.shipping_information?.name}</span>
                                </Typography>
                                <Typography variant='div' component='div' sx={{ my: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
                                    <h3>Shipping Address</h3><span><span>{isgetisorderDetailsData?.shipping_information?.address1}</span> , <span>{isgetisorderDetailsData?.shipping_information?.city}</span> , <span>{isgetisorderDetailsData?.shipping_information?.state}</span> - <span>{isgetisorderDetailsData?.shipping_information?.zip}</span>.</span>
                                </Typography>
                                <Typography variant='div' component='div' sx={{ my: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
                                    <h3>Phone</h3><span>{isgetisorderDetailsData?.shipping_information?.phone}</span>
                                </Typography>
                                <Typography variant='div' component='div' sx={{ my: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
                                    <h3>Email</h3><span>{isgetisorderDetailsData?.shipping_information?.email}</span>
                                </Typography>
                            </Paper>
                        </Box>

                    </div>
                    <div className="order-history order-detail-data">
                        <TableContainer>
                            <Table className='order-detail-table' sx={{ minWidth: 640 }} aria-label="simple table">
                                <TableHead className='order-detail-thead'>
                                    <TableRow className='order-history-caption'>
                                        <TableCell sx={{ minWidth: '150px' }} align="center"></TableCell>
                                        <TableCell sx={{ minWidth: '150px' }} align="center">Product Name</TableCell>
                                        <TableCell sx={{ minWidth: '150px' }} align="center">Unit Price</TableCell>
                                        <TableCell sx={{ minWidth: '100px' }} align="center">Qty</TableCell>
                                        <TableCell sx={{ minWidth: '150px' }} align="center">Subtotal</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderDeatilProduct ?
                                        orderDeatilProduct.map((item) =>
                                            item.has_parent ?
                                                <TableRow className="table-body"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                                    // onClick={() => navigate(`/product/${item?.slug}`)}
                                                    onClick={() => navigate(`/product/${item?.slug}?id=${item?.product_id}`)}
                                                >
                                                    <TableCell align='left'>
                                                        <Box className='order-image'>
                                                            <img src={item?.image_path} alt='image' />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell data-title="Product Name" align="center">{item?.name}</TableCell>
                                                    <TableCell data-title="Unit Price" align="center">{isgetisorderDetailsData?.currency} {item?.unitprice}</TableCell>
                                                    <TableCell data-title="Qty" align="center">{item?.quantity}</TableCell>
                                                    <TableCell data-title="Subtotal" align="center">{isgetisorderDetailsData?.currency} {item?.cart_price}</TableCell>
                                                </TableRow>
                                                :
                                                <TableRow className="table-body"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                                    onClick={() => navigate(`/product/${item?.slug}`)}
                                                >
                                                    <TableCell align='left'>
                                                        <Box className='order-image'>
                                                            <img src={item?.image_path} alt='image' />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell data-title="Product Name" align="center">{item?.name}</TableCell>
                                                    <TableCell data-title="Unit Price" align="center">{item?.unitprice == 0 ? 'FREE' : isgetisorderDetailsData?.currency + item?.unitprice}</TableCell>
                                                    <TableCell data-title="Qty" align="center">{item?.quantity}</TableCell>
                                                    <TableCell data-title="Subtotal" align="center"> {item?.cart_price == 0 ? 'FREE' : isgetisorderDetailsData?.currency + item?.cart_price}</TableCell>
                                                </TableRow>
                                        ) : ""}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column', textAlign: 'right' }} className='order-total-box' component={Paper}>
                        <h3 className='small-title' style={{ textTransform: 'uppercase' }}>Order Total</h3>
                        <Box className='order-subtotal'>
                            <div>Sub Total</div>
                            <p>{isgetisorderDetailsData?.currency} {isgetisorderDetailsData?.sub_total}</p>
                        </Box>
                        {isgetisorderDetailsData?.shipping_charge > 0 &&
                            <Box className='order-shipping'>
                                <div>Shipping Charge</div>
                                <p>{isgetisorderDetailsData?.currency} {isgetisorderDetailsData?.shipping_charge}</p>
                            </Box>}
                        {isgetisorderDetailsData?.coupon_discount > 0 &&
                            <Box className='order-shipping'>
                                <div>Coupon Discount</div>
                                <p>- {isgetisorderDetailsData?.currency} {isgetisorderDetailsData?.coupon_discount}</p>
                            </Box>}
                        {isgetisorderDetailsData?.product_discount > 0 &&
                            <Box className='order-shipping'>
                                <div>Product Discount</div>
                                <p>- {isgetisorderDetailsData?.currency} {isgetisorderDetailsData?.product_discount}</p>
                            </Box>}
                        <Box className='order-total' sx={{ fontWeight: '900' }}>
                            <div>Grand Total</div>
                            <p>{isgetisorderDetailsData?.currency} {isgetisorderDetailsData?.total}</p>
                        </Box>
                    </Box>
                </Container>
            }
        </>
    )
}
