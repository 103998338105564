import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import { useSelector } from 'react-redux';
import { getGeneralSettingData } from '../../selector';
import { Link } from 'react-router-dom';
import { ArrowDropDown, Close } from '@mui/icons-material';


const MenuDrawer = () => {
    const [leftDrawer, setLeftDrawer] = React.useState(false);
    const generalSettingData = useSelector(getGeneralSettingData)

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setLeftDrawer(open);
    };

    const list = () => (
        <Box
            role="presentation"
            open={toggleDrawer(true)}

        >
            <Button onClick={toggleDrawer(false)} sx={{'&:hover': {backgroundColor: "unset", }, width: '100%', textAlign: 'right', display: 'block', pr: '16px'}}><Close sx={{color: "#000", mt: '10px'}} /> </Button>
            <List className='ctm-menu-drawer'>
                {generalSettingData?.header_menu?.option_value.map((page, index) => (
                    page.sub_categories.length !== 0 ?
                        <Accordion className='custom-shadow ctm-margin' key={index} expanded={expanded === index} onChange={handleChange(index)}>
                            <AccordionSummary className='ctm-menu' aria-controls="panel1d-content" id="panel1d-header">
                                <Link onClick={toggleDrawer(false)} to={`/${page.slug}`} textAlign='center'>{page.title_bar}</Link>
                                <ArrowDropDown />
                            </AccordionSummary>
                            <AccordionDetails className={`subcategory-responisve-menu ${page.sub_categories.length === 0 ? 'hidden' : 'block'}`}>
                                {page.sub_categories.map((submenu, index) => (
                                    <Link onClick={toggleDrawer(false)} key={index} to={`/${page.slug}/${submenu.slug}`} textAlign='center'>
                                        <MenuItem className='abcxyz' key={index}>
                                            {submenu.name}
                                        </MenuItem>
                                    </Link>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                        :
                        <List key={index}>
                            <Link onClick={toggleDrawer(false)} to={`/${page.slug}`} textAlign='center'>
                                <ListItem key={index} disablePadding>
                                    <ListItemButton>
                                        {page.title_bar}
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        </List>
                ))}
            </List>
        </Box>
    );

    return (
        <div className='header-box'>
            <Button onClick={toggleDrawer(true)}>
                <IconButton
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    color='inherit'
                    className='iconBtn'
                >
                    <MenuIcon />
                </IconButton>
            </Button>
            <Drawer
                onOpen={toggleDrawer(true)}
                open={leftDrawer}
                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>
        </div>
    )
}
export default MenuDrawer