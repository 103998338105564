import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { Box, Button } from "@mui/material";
import './StripeForm.css'
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkoutshippingcalculateData } from "../../selector";
import { useSelector } from "react-redux";

export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const [isLoading, setIsLoading] = useState(false);
    const ischeckoutshippingcalculateData = useSelector(checkoutshippingcalculateData)
    const currency_symbol = localStorage.getItem('currency_symbol');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${process.env.REACT_APP_BASE_URL}/thank-you`,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        
        // if (error.type === "card_error" || error.type === "validation_error") {
        //     toast.error(error.message);
        // } else {
        //     toast.error("An unexpected error occurred.");
        // }

        if (error) {
            if (error.type === "card_error" || error.type === "validation_error") {
                // toast.error(error.message);
            } else {
                toast.error("An unexpected error occurred.");
            }
        } else {
            console.log(paymentIntent.status)
            switch (paymentIntent.status) {
                case "succeeded":
                    toast.success("Payment succeeded!")
                    break;
                case "processing":
                    toast.success("Your payment is processing.")
                    break;
                case "requires_payment_method":
                    toast.error("Your payment was not successful, please try again.")
                    break;
                default:
                    // toast.error("Something went wrong.")
                    break;
            }
        }
        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    // useEffect(() => {
    //     if (!stripe) {
    //         return;
    //     }

    //     const clientSecret = window.location.pathname.split("/").at(-1)
    //     if (!clientSecret) {
    //         return;
    //     }

    //     stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //         // console.log(paymentIntent)
    //         switch (paymentIntent.status) {
    //             case "succeeded":
    //                 toast.success("Payment succeeded!")
    //                 break;
    //             case "processing":
    //                 toast.success("Your payment is processing.")
    //                 break;
    //             // case "requires_payment_method":
    //             //     toast.error("Your payment was not successful, please try again.")
    //             //     break;
    //             default:
    //                 // toast.error("Something went wrong.")
    //                 break;
    //         }
    //     });
    // }, [stripe]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
      
        <div className="payment-main-div">
            <form id="payment-form" onSubmit={handleSubmit} className="strip-form">
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                {ischeckoutshippingcalculateData?.data &&
                    <Box className='Checkout-total-text'>
                        <h4>Total Amount</h4>
                        <p>{currency_symbol} {ischeckoutshippingcalculateData?.data.total}</p>
                    </Box>}
                <div className="payment-action-btns">
                    <Link to='/checkout' className='back-btn return-cart-checkout'>
                        <i className='fa-solid fa-chevron-left'></i> Return to checkout
                    </Link>
                    <Button type="submit" disabled={isLoading || !stripe || !elements} id="submit" className="shipping-btn strip-btn">
                        <span id="button-text">
                            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                        </span>
                    </Button>
                </div>
            </form>
        </div>
    );
}