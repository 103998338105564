import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Container} from '@mui/material';
import Image404 from "./img/404 Error-theme.png";
import "./page404.css";

const Page404 = () => {

    return (
        <>
            <Container>
                <div className='heading-404'>
                    <h1>Sorry, page not found</h1>
                    <p>Sorry, we couldn’t find the page you’re looking for.</p>
                    <p>Perhaps you’ve mistyped the URL? </p>
                </div>
                <div className='img-area'>
                    <img 
                        className='image-404'
                        src={Image404}
                        alt="404"
                    />
                </div>
            </Container>
        </>
    )
}

export default Page404;