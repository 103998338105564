import { CircularProgress, Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../filterProducts/filterProducts.css'
import './Wishlist.css'
import Grid from '@mui/material/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { addRemoveCartItemLoading, getCreateCartLoading, isWishlistLoading, wishlistData, addRemovefavoriteLoading, addRemovefavoriteData } from '../../selector'
import { addRemoveCartItem, addRemovefavorite, createCart, wishlist } from '../../api'
import Loader from '../Loader/Loader'
import AlertDialogBox from '../AlertDialogBox/AlertDialogBox'
import { CompareSharp } from '@mui/icons-material'

export default function Wishlist(props) {
    const dispatch = useDispatch()
    let getWishlistData = useSelector(wishlistData)
    const WishlistLoading = useSelector(isWishlistLoading)
    const [allData, setAllData] = React.useState()
    const [product_id, setProduct_id] = React.useState()
    const [open, setOpen] = React.useState(false);
    const [inx, setInx] = React.useState()
    const [prod_id, setProd_id] = React.useState()

    const currency_id = localStorage.getItem('currency_id');
    const navigate = useNavigate();
    const isaddRemoveCartItemLoading = useSelector(addRemoveCartItemLoading)
    const CreateCartLoading = useSelector(getCreateCartLoading)
    const isaddRemovefavoriteLoading = useSelector(addRemovefavoriteLoading)
    const isaddRemovefavoriteData = useSelector(addRemovefavoriteData)
    const [dataArray, setDataArray] = React.useState([])
    const [message, setMessage] = React.useState('');


    const removeWishlist = async () => {
        await dispatch(addRemovefavorite({
            product_id: product_id,
            remove: true
        }))

        if (isaddRemovefavoriteData?.status) {
            const productRemove = allData?.filter((item) => item?.id !== product_id);
            setAllData(productRemove);

            if (allData === product_id) {
                setAllData(allData[inx]?.splice)
            }
        }
        setOpen(false);
    }

    const AddToCart = async (product) => {
        setProd_id(product?.id)
        const cart_key = localStorage.getItem('cart_key');
        if (!cart_key) {
            await dispatch(createCart());
        }
        await dispatch(addRemoveCartItem({ product_id: product?.id, quantity: 1 }))
        navigate('/shopping-cart')
    }

    const addFav = async (product) => {
        setAllData(allData.map(item => (item.id === product.id ? { ...item, favorite: !item.favorite } : item)))

        if (product?.favorite) {
            await dispatch(addRemovefavorite({
                product_id: product.id,
                remove: true
            }))
            await dispatch(wishlist({ params: { currency: currency_id } }));

        } else {
            dispatch(addRemovefavorite({
                product_id: product.id,
                add: true
            }))
        }
    }

    // const handleCompareClick = (comproid) => {
    //     props.onChildData(comproid);
    // };

    const CompareProduct = async (productId) => {
        setMessage(productId);
        const newData = [...dataArray, productId];
        let uniqueArray = [...new Set(newData)];
        await setDataArray(uniqueArray);
        navigate('/compare-product')
    };

    React.useEffect(() => {
        const storedData = localStorage.getItem('compare_id');
        if (storedData) {
            setDataArray(JSON.parse(storedData));
        }
    }, []);

    React.useEffect(() => {
        if (dataArray) {
            localStorage.setItem('compare_id', JSON.stringify(dataArray))
        }
    }, [dataArray]);

    React.useEffect(() => {
        setAllData(getWishlistData)
    }, [getWishlistData])

    React.useEffect(() => {
        dispatch(wishlist({ params: { currency: currency_id } }));
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {WishlistLoading && <Loader />}
            <Box className='filter-products-details wishlist-custom'>
                <Container className='container'>
                    <Grid container spacing={2} className='filter-products'>
                        <Grid item xs={12} md={12} className='filter-products-right'>
                            <Grid item xs={12} md={12} className='filter-products_content'>
                                <Box className='Category-top' sx={{ mb: '20px' }}>
                                    <Box className='category-breadcrumb'>
                                        <ul>
                                            <li>
                                                <Link to='/' className='category-list'>
                                                    Home
                                                </Link>
                                            </li>
                                            <span>/</span>
                                            <li>
                                                <Link to='/account/wishlist' className='category-list active'>
                                                    Wishlist
                                                </Link>
                                            </li>
                                        </ul>
                                        <h1 className='category-list-heading'>Wishlist</h1>
                                        <span>{allData?.length} Products</span>
                                    </Box>
                                </Box>
                            </Grid>
                            {allData?.length === 0 ?
                                <Box height='37vw' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography component='h5' variant='h5'> No products available </Typography>
                                </Box>
                                :
                                <Box className='bestseller-grid popProduct-grid custom-wishlist-grid'>
                                    {allData && allData?.map((item, index) =>
                                        <div>
                                            <Box className='bestSeller-image wishlist-image' id='parent'>
                                                <Link to={`/product/${item?.slug}`}>
                                                    <img src={item?.image_path} className='grid-image' />
                                                </Link>
                                                <span>
                                                    <CompareSharp sx={{ color: '#0f51a1', cursor: 'pointer' }} onClick={() => CompareProduct(item.id)} />
                                                    <button className='fav-btn' onClick={() => addFav(item)}>
                                                        {item?.favorite ?
                                                            <svg width='28' height='24' viewBox='0 0 28 24' fill='red' xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M7.81518 0.498687L7.81739 0.498835C9.89738 0.637366 11.8521 1.51052 13.3166 2.95168L13.6401 3.28707L14.0398 3.70139L14.394 3.24767L14.7076 2.84615C16.0799 1.39703 17.9846 0.530833 20.0108 0.439537C22.0368 0.348244 24.0159 1.03943 25.5199 2.35952C26.2003 3.08639 26.7269 3.93614 27.0702 4.86012C27.4162 5.7912 27.5694 6.7796 27.5212 7.76828C27.4731 8.75696 27.2245 9.72691 26.7894 10.6221C26.3543 11.5173 25.7412 12.3203 24.9849 12.9842L24.9768 12.9913L24.9691 12.9987L23.9032 14.0187L23.9031 14.0187L23.8985 14.0232L14.3903 23.3605C14.2932 23.4524 14.1607 23.5058 14.0205 23.5058C13.88 23.5058 13.7475 23.4523 13.6503 23.3602L4.10373 14.0255L3.02042 12.9286L3.00942 12.9175L2.99774 12.907C1.49633 11.566 0.603286 9.70183 0.509828 7.72375C0.416655 5.75168 1.12551 3.82162 2.48535 2.35392C3.18924 1.70324 4.02483 1.20169 4.93947 0.881822C5.85987 0.559939 6.83947 0.429349 7.81518 0.498687Z'
                                                                />
                                                            </svg>
                                                            :
                                                            <svg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M7.81518 0.498687L7.81739 0.498835C9.89738 0.637366 11.8521 1.51052 13.3166 2.95168L13.6401 3.28707L14.0398 3.70139L14.394 3.24767L14.7076 2.84615C16.0799 1.39703 17.9846 0.530833 20.0108 0.439537C22.0368 0.348244 24.0159 1.03943 25.5199 2.35952C26.2003 3.08639 26.7269 3.93614 27.0702 4.86012C27.4162 5.7912 27.5694 6.7796 27.5212 7.76828C27.4731 8.75696 27.2245 9.72691 26.7894 10.6221C26.3543 11.5173 25.7412 12.3203 24.9849 12.9842L24.9768 12.9913L24.9691 12.9987L23.9032 14.0187L23.9031 14.0187L23.8985 14.0232L14.3903 23.3605C14.2932 23.4524 14.1607 23.5058 14.0205 23.5058C13.88 23.5058 13.7475 23.4523 13.6503 23.3602L4.10373 14.0255L3.02042 12.9286L3.00942 12.9175L2.99774 12.907C1.49633 11.566 0.603286 9.70183 0.509828 7.72375C0.416655 5.75168 1.12551 3.82162 2.48535 2.35392C3.18924 1.70324 4.02483 1.20169 4.93947 0.881822C5.85987 0.559939 6.83947 0.429349 7.81518 0.498687Z'
                                                                    stroke='#0F51A1'
                                                                />
                                                            </svg>
                                                        }
                                                    </button>
                                                </span>
                                            </Box>
                                            <div className='popProduct-grid-content'>
                                                <Box className='bestSeller-text'>
                                                    <h3 style={{ maxWidth: '306px' }}>{item?.name}</h3>
                                                </Box>
                                                <Box className='bestSeller-button'>
                                                    {item.has_sizes ?
                                                        <Link to={`/product/${item?.slug}`} className='cart-btn'>
                                                            Select Size
                                                        </Link>
                                                        :
                                                        isaddRemoveCartItemLoading || CreateCartLoading ?
                                                            <>
                                                                {prod_id === item?.id ?
                                                                    <button className='add-cart-loader'>
                                                                        <CircularProgress color="inherit" sx={{ width: '18px !important', height: '18px !important', color: 'white' }} />
                                                                    </button>
                                                                    :
                                                                    <button className='cart-btn' onClick={() => AddToCart(item)}>ADD To Cart</button>
                                                                }
                                                            </>
                                                            :
                                                            <button className='cart-btn' onClick={() => AddToCart(item)}>ADD To Cart</button>
                                                    }
                                                    {item.has_sizes === true ?
                                                        <p>{item?.currency}{item?.size_price?.min} - {item?.currency}{item?.size_price?.max}</p>
                                                        :
                                                        <p>{item?.currency}{item?.price}</p>
                                                    }
                                                </Box>
                                            </div>
                                        </div>
                                    )}
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Container>

                {/* Remove Dialog  */}
                <AlertDialogBox
                    open={open}
                    setOpen={setOpen}
                    title='Remove Wishlist Item'
                    contenttext='Are you sure you want to remove this item?'
                    isLoader={isaddRemovefavoriteLoading}
                    actionButton='Remove'
                    onActionClick={removeWishlist}
                />
            </Box>
        </>
    )
}
