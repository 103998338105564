import React, { useEffect } from 'react'
import { getdisclaimers } from "../../../api";
import { useDispatch, useSelector } from 'react-redux';
import { disclaimersData, isdisclaimersLoading } from '../../../selector';
import { Container, Typography } from '@mui/material';
import { Helmet } from "react-helmet";
import Loader from '../../Loader/Loader';

export default function Disclaimers() {

    const dispatch = useDispatch();
    const getdisclaimersData = useSelector(disclaimersData)
    const disclaimersLoading = useSelector(isdisclaimersLoading)

    useEffect(() => {
        dispatch(getdisclaimers());
    }, [])

    return (
        <>
            {disclaimersLoading && <Loader />}

            <Container maxWidth="lg" sx={{pt: '60px'}}>
                <Helmet>
                    <title>{getdisclaimersData?.data.browser_title}</title>
                    <meta name="description" content={getdisclaimersData?.data.meta_descr} />
                </Helmet>
                <Typography variant='h4' component='h1'>{getdisclaimersData?.data.page}</Typography>
                <div dangerouslySetInnerHTML={{ __html: getdisclaimersData?.data.content }} />
            </Container>
        </>
    )
}
