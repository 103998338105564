import React, { useEffect } from 'react'
import { getfaceBook } from "../../../api";
import { useDispatch, useSelector } from 'react-redux';
import { faceBookData, isfaceBookLoading } from '../../../selector';
import { Container, Typography } from '@mui/material';
import { Helmet } from "react-helmet";
import Loader from '../../Loader/Loader';

export default function FaceBook() {

    const dispatch = useDispatch();
    const getfaceBookData = useSelector(faceBookData)
    const faceBookLoading = useSelector(isfaceBookLoading)

    useEffect(() => {
        dispatch(getfaceBook());
    }, [])

    return (
        <>
            {faceBookLoading && <Loader />}

            <Container maxWidth="lg" sx={{pt: '60px'}}>
                <Helmet>
                    <title>{getfaceBookData?.data.browser_title}</title>
                    <meta name="description" content={getfaceBookData?.data.meta_descr} />
                </Helmet>
                <Typography variant='h4' component='h1'>{getfaceBookData?.data.page}</Typography>
                <div dangerouslySetInnerHTML={{ __html: getfaceBookData?.data.content }} />
            </Container>
        </>
    )
}
