import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react'
import StripeForm from './StripeForm'
import { loadStripe } from '@stripe/stripe-js/pure';
import axios from 'axios';

const StripePath = () => {

    const [stripeKey, setStripeKey] = useState('');
    const [loading, setLoading] = useState(true);
    const [stripePromise, setStripePromise] = useState (null);

    const clientSecret = localStorage.getItem('client_secret')

    const options = {
        clientSecret,
    };

    // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);


    // return (
    //     <>
    //         {clientSecret && (
    //             <Elements options={options} stripe={stripePromise}>
    //                 <StripeForm />
    //             </Elements>
    //         )}
    //     </>
    // )

  
    useEffect(() => {
        const fetchStripeKey = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-settings/paymentgatewaykey/stripe`);
                if (response.data.key) {
                    setStripeKey(response.data.key);
                } else {
                    console.error('Error fetching Stripe key');
                }
            } catch (error) {
                console.error('Error fetching Stripe key:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStripeKey();
    }, []);


    useEffect(() => {
        if (stripeKey) {
            const promise = loadStripe(stripeKey);
            setStripePromise(() => promise); // Set the promise correctly
        }
    }, [stripeKey])

   return (
        <>
            {clientSecret && stripePromise && (
                <Elements options={options} stripe={stripePromise}>
                    <StripeForm />
                </Elements>
            )}
        </>
    )
}

export default StripePath;
