import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import './HowToUse.css'
import Grid from '@mui/material/Grid'
import { useSelector } from 'react-redux'
import { getProductdetailsData } from '../../selector'

export default function HowToUse() {
  const ProductdetailsData = useSelector(getProductdetailsData)
  const product = ProductdetailsData?.product

  return (
    <>
    {product?.how_to_use.length?
    <Box className='How-to-use' >
      <Container className='container'>
        <h1 className='howUse-heading'>How To Use</h1>
        <Grid container spacing={2} className='How-use-details'>
          {product?.how_to_use?.map((item, index) => (
            <Grid item xs={12} md={4} className='How-use-image' key={index}>
              <Box className='How-use-content'>
                {item?.image ? <img src={item?.image} alt='how to use' /> : ''}
                <h3>Step {index + 1}</h3>
                <div dangerouslySetInnerHTML={{ __html: item?.description }} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>:""}
    </>
  )
}
