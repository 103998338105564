import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import profileReducer from "./features/profile/profileSlice";
import staticReducer from "./features/static/staticSlice";
import homeReducer from "./features/home/homeSlice";
import productReducer from "./features/product/productSlice";
import cartReducer from "./features/cart/cartSlice";
import checkoutReducer from "./features/checkout/checkoutSlice";
import orderHistoryReducer from "./features/orderHistory/orderHistory";
import compareProductReducer from "./features/compareProduct/compareProductSlice";

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    static: staticReducer,
    home: homeReducer,
    product: productReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    orderHistory: orderHistoryReducer,
    compareProduct: compareProductReducer
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
