import { Button, Container, Divider, InputLabel, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import './Login.css'
import { Player } from 'video-react'
import loginPosterImage from './img/login-thumbnail.png'
import { useFormik } from 'formik'
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { forgotCheckOTPData, forgotCheckOTPLoading, forgotPasswordPhoneData, forgotPasswordPhoneLoading, forgotResendOTPData, forgotResendOTPLoading, getProfileStatus, isResetPasswordLoading } from '../../selector'
import { forgotCheckOTP, forgotPassword, forgotPasswordPhone, getCartItems, getProfile, forgotResendOTP } from '../../api'
import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PhoneInput from 'react-phone-input-2'
import ReactPlayer from 'react-player'
import ReCAPTCHA from "react-google-recaptcha";


export default function Login() {
  const [showPassword, setShowPassword] = React.useState(false)
  const [openForgotPassword, setOpenForgotPassword] = React.useState(false);
  const [conf_showPassword, setconf_ShowPassword] = React.useState(false)
  const [new_showPassword, setNew_ShowPassword] = React.useState(false)
  const [handlePhoneNumber, setHandlePhoneNumber] = React.useState()
  const [handleCountryCode, setHandleCountryCode] = React.useState()
  const [handleGetForgotPassword, setHandleGetForgotPassword] = React.useState(false)
  const [isResendButton, setIsResendButton] = React.useState(false)
  const [timer, setTimer] = React.useState(65);

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = localStorage.getItem("token")
  const getUserStatus = useSelector(getProfileStatus)
  const getLoadingPassword = useSelector(isResetPasswordLoading)
  const getForgotPasswordPhone = useSelector(forgotPasswordPhoneData)
  const getForgotPasswordPhoneLoading = useSelector(forgotPasswordPhoneLoading)
  const getOTPLoading = useSelector(forgotCheckOTPLoading)
  const getOTPData = useSelector(forgotCheckOTPData)
  const getResendOTP = useSelector(forgotResendOTPData)
  const getResendOTPDataLoading = useSelector(forgotResendOTPLoading)

  const google_sitekey =
  process.env.REACT_APP_BASE_URL === "https://pro.prohairlabs.com"
    ? process.env.REACT_APP_RECAPTCHA_KEY
    : process.env.REACT_APP_RECAPTCHA_LOCAL_KEY

    
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleClickOpen = () => {
    setOpenForgotPassword(true);
  };

  const handleClose = () => {
    setOpenForgotPassword(false);
    setHandleGetForgotPassword(false)
    formikphone.resetForm()
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: '',
      captcha: ""
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid email address').required('Email Required'),
      password: yup.string().min(6, "Must be 6 characters or more").required('Password Required'),
      captcha: yup.string().required('Required'),
    }),
    onSubmit: values => {
      dispatch(getProfile({ email: values.email, password: values.password, captcha: values.captcha })).then((res) => {
        if (res?.payload?.data) {
          dispatch(getCartItems({ params: { currency: res.payload.data.currency_id } }))
        }
      })
    }
  });

  React.useEffect(() => {
    const counter = timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
    return () => clearTimeout(counter);
  }, [timer]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsResendButton(true);
    }, 65000)
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [getUserStatus])

  useEffect(() => {
    if (getForgotPasswordPhone?.status === true) {
      setHandleGetForgotPassword(true)
    }
  }, [!getForgotPasswordPhoneLoading])

  const sendMail = (data) => {
    dispatch(forgotPassword({ email: data.email }))
  }

  const sendOTP = (data) => {
    dispatch(forgotPasswordPhone({ country_code: "+" + data.phone.slice(0, 2), phone: data.phone.slice(2, data.phone.length + 1) }))
    setHandlePhoneNumber(data.phone.slice(2, data.phone.length + 1))
    setHandleCountryCode("+" + data.phone.slice(0, 2))
  }

  const checkOTP = (data) => {
    dispatch(forgotCheckOTP({ phone: handlePhoneNumber, otp: data.otp, password: data.password, password_confirmation: data.password }))
      .then((res) => {
        if (res?.payload?.data?.status === true) {
          navigate("/login")
          setOpenForgotPassword(false);
          formikemail.resetForm();
          formikphone.resetForm();
          formikOTP.resetForm();
        }
      })
  }

  useEffect(() => {
    if (!getOTPLoading) {
      handleClose()
      formikemail.resetForm();
      formikphone.resetForm();
      formikOTP.resetForm();
    }
  }, [getOTPLoading])

  const validationEmail = yup.object({
    email: yup.string().email('Invalid email address')
  })

  const validationPhone = yup.object({
    email: yup.string('Enter your phone number')
  })

  const validationOtp = yup.object({
    otp: yup.number('Enter your OTP number'),
    password: yup.string().required('Password is required'),
    password_confirmation: yup.string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  })

  const initialEmail = {
    email: '',
  }

  const initialPhone = {
    phone: '',
  }

  const initialOtp = {
    otp: '',
    password: '',
    password_confirmation: '',
  }

  const formikemail = useFormik({
    initialValues: initialEmail,
    validationSchema: validationEmail,
    onSubmit: sendMail
  });

  const formikphone = useFormik({
    initialValues: initialPhone,
    validationSchema: validationPhone,
    onSubmit: sendOTP
  });

  const formikOTP = useFormik({
    initialValues: initialOtp,
    validationSchema: validationOtp,
    onSubmit: checkOTP
  });

  const resendOtpHandler = () => {
    dispatch(forgotResendOTP({ phone: handlePhoneNumber, country_code: handleCountryCode }))
  }

  useEffect(() => {
    if (!getLoadingPassword) {
      handleClose()
      formikemail.resetForm()
    }
  }, [getLoadingPassword])

  const handlePhone = (e) => {
    formikphone.setFieldValue("phone", e)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Box className='LoginForm-details'>
        <Container className='container'>
          <Grid container spacing={2} className='LoginForm-content'>
            <Grid item xs={12} md={6} className='LoginForm-form'>
              <Box className='Login-heading'>
                <h3>Registered Professionals</h3>
                <p>If you have an account with us. please log in.</p>
              </Box>
              <Box className='login-form-content'>
                <form onSubmit={formik.handleSubmit}>
                  <div className='login-form'>
                    <FormControl className='form-control-field' fullWidth>
                      <label className='form-label'>Email Address</label>
                      <TextField
                        id='demo-helper-text-aligned'
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email} placeholder='Email' />
                      {formik.touched.email && formik.errors.email ? (
                        <div className='error'>{formik.errors.email}</div>
                      ) : null}
                    </FormControl>
                    <FormControl className='form-control-field' fullWidth>
                      <Box className="forgot-password-wrapper">
                        <label>Password</label>
                        <Typography className='forgot-password' onClick={handleClickOpen}>Forgot Password?</Typography>
                      </Box>
                      <OutlinedInput
                        id='outlined-adornment-password'
                        placeholder='Password'
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className='error'>{formik.errors.password}</div>
                      ) : null}
                    </FormControl>
                  </div>
                  <span className='forgot-password'>
                    {/* FORGOT PASSWORD EMAIL */}
                    <Dialog open={openForgotPassword} onClose={handleClose} className='reset-pwd-dialog'>
                      <DialogTitle className='forgot-pwd-title'>Forgot Password</DialogTitle>
                      <form onSubmit={formikemail.handleSubmit}>
                        <DialogContent className='reset-pwd-dialog'>
                          <DialogContentText>
                            Please enter the email address you'd like your password reset information sent to
                          </DialogContentText>

                          <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="Email Address"
                            type="email"
                            name="email"
                            autoComplete="off"
                            onChange={formikemail.handleChange}
                            onBlur={formikemail.handleBlur}
                            value={formikemail.values.email}
                            fullWidth
                            variant="standard"
                            required
                          />
                          {formikemail.touched.email && formikemail.errors.email ? (
                            <div className='error'>{formikemail.errors.email}</div>
                          ) : null}
                        </DialogContent>
                        <DialogActions>
                          {getLoadingPassword ?
                            <Button className='reset-link-btn' sx={{ my: '15px', mx: '10px' }}>
                              <CircularProgress color="inherit" />
                            </Button>
                            :
                            <Button type='submit' className='reset-link-btn' sx={{ my: '15px', mx: '10px' }}>
                              Send Email
                            </Button>
                          }
                        </DialogActions>
                      </form>
                      <>
                        <div>
                          <Divider />
                          <Typography className='forgot-or'>OR</Typography>
                          {!handleGetForgotPassword ?
                            <>
                              <form onSubmit={formikphone.handleSubmit}>
                                <DialogContentText sx={{ px: '20px', py: '18px' }}>
                                  Enter your registered phone number to receive a unique code to change your password.
                                </DialogContentText>
                                <div className='forgot-phn'>
                                  <InputLabel htmlFor='Phone' id="standard-basic" variant="standard" sx={{ mb: '10px' }} className='phone-label'>Phone</InputLabel>
                                  <PhoneInput
                                    inputProps={{
                                      name: 'phone',
                                      require: true,
                                      autoFocus: true,
                                    }}
                                    inputComponent={<TextField />}
                                    country={'us'}
                                    name="phone"
                                    onBlur={formikphone.handleBlur}
                                    value={formikphone.values.phone}
                                    specialLabel="Phone"
                                    onChange={handlePhone}
                                    className="select-phone"
                                  />
                                </div>
                                <DialogActions>
                                  {getForgotPasswordPhoneLoading ?
                                    <Button className='reset-link-btn' sx={{ my: '15px', mx: '10px' }}>
                                      <CircularProgress color="inherit" />
                                    </Button>
                                    :
                                    <Button type='submit' className='reset-link-btn' sx={{ my: '15px', mx: '10px' }}>
                                      Send OTP
                                    </Button>
                                  }
                                </DialogActions>
                              </form>
                            </>
                            :
                            <>
                              <form onSubmit={formikOTP.handleSubmit} className='forgot-password-otp'>
                                <Typography variant='div' component='div' sx={{ px: '20px' }}>

                                  {/* new password */}
                                  <TextField
                                    sx={{ mb: '20px' }}
                                    autoFocus
                                    margin="dense"
                                    label="New Password"
                                    placeholder="Type New Password"
                                    type={new_showPassword ? 'text' : 'password'}
                                    name="password"
                                    autoComplete="off"
                                    onChange={formikOTP.handleChange}
                                    onBlur={formikOTP.handleBlur}
                                    value={formikOTP.values.password}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    fullWidth
                                    variant="standard"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={() => setNew_ShowPassword((show) => !show)}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                          >
                                            {new_showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                  {/* retype new password */}
                                  <TextField
                                    fullWidth
                                    sx={{ mb: '20px' }}
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    label="Confirm Password "
                                    type={conf_showPassword ? 'text' : 'password'}
                                    value={formik.values.password_confirmation}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                    helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                    variant='standard'
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={() => setconf_ShowPassword((show) => !show)}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                          >
                                            {conf_showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                  <TextField
                                    sx={{ mb: '20px' }}
                                    autoFocus
                                    margin="dense"
                                    label="OTP"
                                    placeholder="Enter OTP"
                                    type="text"
                                    name="otp"
                                    autoComplete="off"
                                    onChange={formikOTP.handleChange}
                                    onBlur={formikOTP.handleBlur}
                                    value={formikOTP.values.otp}
                                    fullWidth
                                    variant="standard"
                                  />
                                  <Typography sx={{ display: 'flex' }}>
                                    <div className={`${timer === 0 ? 'hidden' : 'block'}`}>00 : {timer}</div>
                                    <Button className='resend-button' disabled={!isResendButton} onClick={() => resendOtpHandler()} >Resend Otp</Button>
                                  </Typography>
                                  <DialogActions sx={{ p: "0px", mb: '20px' }}>
                                    {getOTPLoading || getResendOTPDataLoading ?
                                      <Button className='reset-link-btn'>
                                        <CircularProgress color="inherit" />
                                      </Button>
                                      :
                                      <Button type='submit' className='reset-link-btn'>
                                        Changed Password
                                      </Button>
                                    }
                                  </DialogActions>
                                </Typography>
                              </form>
                            </>
                          }
                        </div>
                      </>

                    </Dialog>
                  </span>
                  <Box sx={{ paddingTop: "30px" }}>
                    <ReCAPTCHA
                      sitekey={google_sitekey}
                      onChange={(res) => formik.setFieldValue("captcha", res)}

                    />
                    {formik.touched.captcha && formik.errors.captcha ? (
                      <div className='error'>{formik.errors.captcha}</div>
                    ) : null}
                  </Box>
                  {getUserStatus ?
                    <Box className='login-loader'>
                      <CircularProgress color="inherit" />
                    </Box>
                    :
                    <Box className='login-btn'>
                      <button type='submit'>Log In</button>
                    </Box>
                  }
                </form>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} className='LoginForm-right-content'>
              <Box className='LoginForm-video' sx={{ position: 'relative' }}>
                {/* <ReactPlayer
                  style={{ marginTop: "0px" }}
                  className='custom-login videoPoster'
                  url='https://www.youtube.com/watch?v=sRAxVDf7EnI'
                  width="100%"
                  alt="login image"
                  playing={true}
                  loop={true}
                  controls={true}
                  light={<img src={loginPosterImage} className='login-video-image' width="572" height="321" alt='Thumbnail' />}
                /> */}
                <h3>New Professional Account</h3>
                <p>
                  PLEASE NOTE: As this is a professional partial dedicated strictly to licensed cosmetologists and registeref businesses. It
                  will be required to enter all of your business details dr cosmetology license information when signing up for an account.
                </p>
                <h4  className='new-account'>
                  {`Don't have an account? `} <Link to='https://application.prohairlabs.com/' target='_blank' style={{color:"#0f51a1"}} >&nbsp;Sign up</Link>
                </h4>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}
