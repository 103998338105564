import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React from 'react'

export default function AlertDialogBox({ open, setOpen, title, contenttext, isLoader, actionButton, onActionClick }) {
    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="wishlist-remove-dialog"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant='span' component='span' className='dialog-content-text'> {contenttext} </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ py: '16px', px: '24px' }}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
                    {isLoader ?
                        <Button variant="outlined" color="error">
                            <CircularProgress color="inherit" sx={{ width: '25px !important', height: '25px !important' }} />
                        </Button>
                        :
                        <Button variant="outlined" color="error" onClick={onActionClick}>
                            <Typography>{actionButton}</Typography>
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}
