import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link } from 'react-router-dom'
import './waterBased.css'
import Grid from '@mui/material/Grid' // Grid version 1
import waterImage1 from './img/product.png'

export default function WaterBased() {
  return (
    <Box className='WaterBased-details waterbase-custom'>
      <Container className='container left-container'>
        <Grid container spacing={2} className='WaterBased-right'>
          <Grid item xs={12} md={6} className='WaterBased_content-des'>
            <Box className='WaterBased-text'>
              <span>DISCOUNT ON</span>
              <h3>ALL WATER BASED ADHESIVES</h3>
              <p>When you order 128oz or more</p>
              <Link to='/category/adhesives' className='WaterMore-btn'>
                Learn More
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className='WaterBased_content'>
            <Box className='water-based-image'>
              <img src={waterImage1} width={"100%"} height={"100%"} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
