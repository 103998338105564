import * as React from 'react'
import ThankyouText from '../ThankyouText/ThankYouText'
import { useDispatch, useSelector } from 'react-redux';
import { orderDetailsSecret, paypalCapture } from '../../api';
import { orderDetailsSecretData, paypalCaptureData } from './../../selector';
import { useState } from 'react';

export default function ThankYou() {
  const dispatch = useDispatch();
  const [checkPath, setCheckPath] = useState();

  const paypalCaptureResponse = useSelector(paypalCaptureData)
  const orderDetailsSecretResponce = useSelector(orderDetailsSecretData)
  const orderId = paypalCaptureResponse?.data?.cart;
  const ccOrderId = orderDetailsSecretResponce?.data?.cart;
  const location = window.location.search

  React.useEffect(() => {
    // setCheckPath(location.href)
    const parts = location.split('=');
    const paymentId = parts[1];
    const final_PayId = paymentId?.split('&')
    const paymenttoken = parts[2];
    const final_token = paymenttoken?.split('&');
    const PayerID = parts[3];
    localStorage.removeItem("cart_key");
    const getfirstpera = location?.split('=')
    const geturl = getfirstpera[0]
    setCheckPath(geturl)

    if (geturl !== '?payment_intent') {
      dispatch(paypalCapture({ payment_id: final_PayId?.[0], payer_id: PayerID, token: final_token?.[0] }))
    }
    else {
      dispatch(orderDetailsSecret({ params: { payment_intent: final_PayId?.[0], client_secret: final_token?.[0] } }))
    }

  }, [])

  return (
    <div>
      <ThankyouText orderId={orderId} checkPath={checkPath} ccOrderId={ccOrderId} />
    </div>
  )
}
