import React, { useEffect } from 'react'
import { gettermsConditions } from "../../../api";
import { useDispatch, useSelector } from 'react-redux';
import { termsConditionsData, istermsConditionsLoading } from '../../../selector';
import { Container, Typography } from '@mui/material';
import { Helmet } from "react-helmet";
import Loader from '../../Loader/Loader';

export default function TermsConditions() {

    const dispatch = useDispatch();
    const gettermsConditionsData = useSelector(termsConditionsData)
    const termsConditionsLoading = useSelector(istermsConditionsLoading)

    useEffect(() => {
        dispatch(gettermsConditions());
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {termsConditionsLoading && <Loader />}

            <Container maxWidth="lg" sx={{pt: "60px"}}>
                <Helmet>
                    <title>{gettermsConditionsData?.data.browser_title}</title>
                    <meta name="description" content={gettermsConditionsData?.data.meta_descr} />
                </Helmet>
                <Typography variant='h4' component='h1'>{gettermsConditionsData?.data.page}</Typography>
                <div dangerouslySetInnerHTML={{ __html: gettermsConditionsData?.data.content }} />
            </Container>
        </>
    )
}
