import { Autocomplete, Button, CircularProgress, Container, FormHelperText, FormLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import './CheckoutForm.css'
import PhoneInput from 'react-phone-input-2';
import { getCheckoutitems, applyCoupon, removeCoupon, shippingCalculate, checkoutshippingCalculate, shippingMethodUps, getCountry, getState, getCity, generateSecret, paypalPayment } from '../../api'
import { getcheckoutitemsData, getcheckoutitemsLoading, applycouponLoading, removecouponLoading, shippingcalculateData, shippingcalculateLoading, checkoutshippingcalculateLoading, checkoutshippingcalculateData, shippingMethodUpsData, getCountrySelector, isgetCityData, isgetStateSelector, applycouponData } from '../../selector'
import { useDispatch, useSelector } from 'react-redux'
import { ShoppingCartCheckout } from '@mui/icons-material'
import { useFormik } from 'formik';
import * as yup from 'yup';
import Loader from '../Loader/Loader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShopPay from './img/debit-card.png'
import Gpay from './img/paypal.png'
import { Helmet } from 'react-helmet'

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .min(3)
    .email('Enter a valid email')
    .required('Email Required'),
  country: yup
    .string('Enter your country')
    .required('Country Required'),
  fname: yup
    .string('Enter your first name')
    .min(3).nullable()
    .required('FirstName Required'),
  lname: yup
    .string('Enter your last name')
    .min(3)
    .required('LastName Required'),
  // company: yup
  //   .string('Enter your Company Name'),
  company: yup
    .string().nullable().default(null),
  address: yup
    .string('Enter your address')
    .required('Address Required'),
  city: yup
    .string('Enter your city')
    .required('City Required'),
  state: yup
    .string('Enter your State')
    .required('State Required'),
  phone: yup
    .number('Enter your phone number')
    .min(10)
    .required('Phone Number Required'),
  special_instructions: yup
    .string('Write Special Instructions')
    .min(3)
});

export default function CheckoutForm() {
  const currency_id = localStorage.getItem('currency_id');
  const currency_symbol_local = localStorage.getItem('currency_symbol')
  const navigate = useNavigate();

  const [cart_no, setCart_no] = React.useState(0)
  const [sub_total, setSub_total] = React.useState(0)
  const [shipping_charge, setshipping_charge] = React.useState(0)
  const [shippingError, setShippingError] = React.useState(false)
  const [coupon_disc, setcoupon_disc] = React.useState(0)
  const [prod_disc, setprod_disc] = React.useState(0)
  const [total, settotal] = React.useState(0)
  const [currencySymbol, setCurrencySymbol] = React.useState(0)
  const [checkedRadio, setCheckedRadio] = React.useState(false);
  const [checkOutItemData, setCheckOutItemData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [methods, setMethods] = React.useState();
  const [value, setValue] = React.useState('credit card');

  const formik = useFormik({
    initialValues: {
      country: '',
      country_code: '',
      email: '',
      fname: '',
      lname: '',
      company: '',
      address: '',
      address2: '',
      appartment: '',
      shippingMethod: '',
      city: '',
      state: '',
      phone: '',
      zip_code: '',
      special_instructions: '',

    },
    validationSchema: validationSchema,
    onSubmit: (val) => otherShippingCalc(val),
    validateOnMount: true
  });

  const handlePhone = (e) => {
    formik.setFieldValue("phone", e)
  }

  const [discountCode, setdiscountCode] = React.useState('')
  const [isFormDataChange, setIsFormDataChange] = React.useState(true)
  const [handleCountryId, setHandleCountryId] = React.useState()
  const [cityData, setCityData] = React.useState([])
  const [stateData, setStateData] = React.useState([])
  const dispatch = useDispatch();
  let isgetcheckoutitemsData = useSelector(getcheckoutitemsData)
  const isgetcheckoutitemsLoading = useSelector(getcheckoutitemsLoading)
  const isapplycouponLoading = useSelector(applycouponLoading)
  const isremovecouponLoading = useSelector(removecouponLoading)
  const isshippingcalculateLoading = useSelector(shippingcalculateLoading)
  const isshippingcalculateData = useSelector(shippingcalculateData)
  const ischeckoutshippingcalculateData = useSelector(checkoutshippingcalculateData)
  const ischeckoutshippingcalculateLoading = useSelector(checkoutshippingcalculateLoading)
  const getshippingMethodUpsData = useSelector(shippingMethodUpsData)
  const CheckoutProduct = checkOutItemData?.items?.products;
  const userInfo = checkOutItemData?.user_details;
  const getCountryData = useSelector(getCountrySelector)
  const getCityData = useSelector(isgetCityData)
  const getStateSelector = useSelector(isgetStateSelector)

  React.useEffect(() => {
    if (getCityData) {
      setCityData(getCityData)
    }
  }, [getCityData])

  React.useEffect(() => {
    if (getStateSelector) {
      setStateData(getStateSelector)
    }
  }, [getStateSelector])


  const applyCouponHandler = async () => {
    await dispatch(applyCoupon({ coupon_code: discountCode, currency: parseInt(currency_id) })).then(async (res) => {

      if (checkedRadio && ischeckoutshippingcalculateData?.data) {
        setshipping_charge(ischeckoutshippingcalculateData?.data?.shipping_charge)
      }
      await dispatch(getCheckoutitems({ params: { currency: currency_id } })).then((res) => {
        setcoupon_disc(res?.payload?.data.coupon_discount)
        setCheckOutItemData(res?.payload?.data)
      })
    })
  }

  const removeCouponHandler = async () => {
    await dispatch(removeCoupon({ coupon_code: discountCode, currency: parseInt(currency_id) })).then(async (res) => {

      if (checkedRadio && ischeckoutshippingcalculateData?.data) {
        setshipping_charge(ischeckoutshippingcalculateData?.data?.shipping_charge)
      }
      await dispatch(getCheckoutitems({ params: { currency: currency_id } })).then((res) => {
        setcoupon_disc(res?.payload?.data.coupon_discount)
        setCheckOutItemData(res?.payload?.data)
        setdiscountCode('')
      })
    })
  }

  // const checkoutshippingCalculateHandler = (data, service) => {
  //   if (data) {
  //     if (currency_id === '2') {
  //       setshipping_charge(data?.available_shippings?.price);
  //     }
  //   }
  // };

  // const handleShippingOptionChange = (data, service, shipping) => {

  //   const availableShippings = data?.available_shippings;
  //   const isCurrencyId2 = currency_id === '2';

  //   const payload = {
  //     shipping_service_code: isCurrencyId2 ? service : 0,
  //     shipping_method: isCurrencyId2 ? availableShippings?.product_name : availableShippings?.[0]?.product_name,
  //     shipping_price: isCurrencyId2 ? availableShippings?.price : availableShippings?.[0]?.price,
  //     shipping_del_date: isCurrencyId2 ? 0 : availableShippings?.[0]?.delivery_capabilities,
  //     shipping_name: data?.shipping_name,
  //     currency: parseInt(currency_id)
  //   };

  //   // const payload = createPayload(checkOutItemData, shipping);
  //   dispatch(checkoutshippingCalculate(payload)).then((res) => {
  //     if (currency_id === '2' && res?.payload?.data) {
  //       addShippingCost(res?.payload?.data);
  //     }
  //   });
  // };

  const checkoutshippingCalculateHandler = (data, service) => {
    if (data) {
      if (currency_id === '2') {
        setshipping_charge(data?.available_shippings?.price)
      }
    }
  }


  const handleShippingOptionChange = (shipping) => {

    addShippingCost(checkOutItemData, shipping);

    const payload = {
      shipping_service_code: currency_id === '2' ? shipping.service : 0,
      shipping_method: shipping.product_name,
      shipping_price: shipping.price,
      shipping_del_date: shipping.delivery_capabilities,
      shipping_name: checkOutItemData?.shipping_name,
      currency: parseInt(currency_id)
    };

    dispatch(checkoutshippingCalculate(payload)).then((res) => {
      if (currency_id === '2' && res?.payload?.data) {
        addShippingCost(res?.payload?.data)
      }
    })
  };

  const generateSecretHandler = async (data) => {
    setLoading(true)
    if (value === "credit card") {
      await dispatch(generateSecret({
        first_name: formik.values?.fname,
        last_name: formik.values?.lname,
        email: formik.values?.email,
        phone: Number(formik.values?.phone),
        company: formik.values?.company,
        address: formik.values?.address,
        address_2: formik.values?.address2,
        city: formik.values?.city,
        state: formik.values?.state,
        zip: formik.values?.zip_code,
        country: formik.values?.country,
        currency: Number(currency_id),
        final_total: total.toFixed(2),
        special_instructions: formik.values.special_instructions,
      }))
        .then(async (data) => {
          setLoading(false)
          if (data?.payload?.data) {
            navigate(`/payment/${data?.payload?.data?.client_secret}`)
          }
        })
    }
    if (value === "paypal") {
      await dispatch(paypalPayment({
        first_name: formik.values?.fname,
        last_name: formik.values?.lname,
        email: formik.values?.email,
        phone: Number(formik.values?.phone),
        company: formik.values?.company,
        address: formik.values?.address,
        address_2: formik.values?.address2,
        city: formik.values?.city,
        state: formik.values?.state,
        zip: formik.values?.zip_code,
        special_instructions: formik.values.special_instructions,
        country: formik.values?.country,
        currency: Number(currency_id),
        final_total: total.toFixed(2),
      }))
        .then(async (data) => {
          setLoading(false)
          if (data?.payload?.data) {
            window.location.href = (data.payload.data.approval_url)
          } else {
            setLoading(false)
          }
        })
    }
  }

  const getgenerateSecret = async (data) => {
    if (currency_id === '2' || currency_id === '1') {
      if (ischeckoutshippingcalculateData?.data === undefined) {
        toast.error('Please calculate shipping')
      } else {
        generateSecretHandler()
      }
    } else {
      if (checkedRadio) {
        generateSecretHandler(data)
      } else {
        toast.error('Please Calculate Shipping and Select Shipping Method')
      }
    }
  }

  // const handleGetCity = (value) => {
  //   if (value) {
  //     formik.setFieldValue('city', '')
  //     setIsFormDataChange(true)
  //     const getSelectedState = getStateSelector && getStateSelector?.filter((item) => item.name === value)
  //     dispatch(getCity({ params: { country_code: formik.values?.country_code, state_code: getSelectedState[0]?.iso2 } }))
  //   }
  // }

  const handleState = async (value) => {
    if (getCountryData) {
      setIsFormDataChange(true)
      const state = getCountryData?.filter((item) => item.name === value)
    }
  }

  const addShippingCost = (data, shipping) => {
    setCart_no(data?.cart)
    setSub_total(data?.sub_total)
    setprod_disc(data?.product_discount)
    setshipping_charge(data?.shipping_charge)
    setcoupon_disc(data?.coupon_discount)
    settotal(data?.total)
    setCheckedRadio(true)
    if (currency_id !== "2") {
      setshipping_charge(shipping?.price)
      settotal(data?.total + shipping?.price )
    }
  }

  const usShippingCalc = (data, service) => {
    const usd = { country: data.country, zip_code: parseInt(data.zip), service, currency: parseInt(currency_id) }
    if (usd === '') {
      dispatch(shippingCalculate(usd)).then((res) => {
        if (res.payload.data) {
          checkoutshippingCalculateHandler(res.payload.data, service)
        }
      })
    }
  }

  const otherShippingCalc = (values) => {
    if (currency_id !== '2') {
      let countryId = getCountryData?.filter(obj => obj.id == values.country && obj.id)
      // const other = { country: values.country, zip_code: values.zip_code, city: values.city, currency: currency_id }

      setHandleCountryId(values.country)
      const other = { country: countryId[0]?.id, zip_code: values.zip_code, city: values.city, currency: currency_id }
      dispatch(shippingCalculate(other)).then((res) => {
        if (res.payload.data) {
          checkoutshippingCalculateHandler(res.payload.data, "")
          setCheckedRadio(false)
        }
      })
      setIsFormDataChange(false)
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  React.useEffect(() => {
    if (isFormDataChange) {
      setCheckedRadio(false)
    }
  }, [isFormDataChange])

  React.useEffect(() => {
    if (userInfo) {
      let selectedCountry = getCountryData?.find((opt) => opt.id === userInfo?.country)?.id;
      handleState(selectedCountry);
      formik.setFieldValue("country", selectedCountry)
      formik.setFieldValue("state", userInfo?.state)
      formik.setFieldValue("city", userInfo?.city)
      formik.setFieldValue("email", userInfo?.email)
      formik.setFieldValue("country_code", userInfo?.country_code)
      formik.setFieldValue("fname", userInfo?.fname)
      formik.setFieldValue("lname", userInfo?.lname)
      formik.setFieldValue("company", userInfo?.company)
      formik.setFieldValue("address", userInfo?.address)
      formik.setFieldValue("address2", userInfo?.address2)
      formik.setFieldValue("shippingMethod", methods?.length && methods[1]?.service_code)
      formik.setFieldValue("phone", userInfo?.phone)
      formik.setFieldValue("zip_code", userInfo?.zip)
      if (methods?.length && methods[1]?.service_code && selectedCountry) {

        const usd = { country: selectedCountry, zip_code: parseInt(userInfo?.zip), service: methods[1]?.service_code, currency: parseInt(currency_id) }
        // if (usd === '') {
        dispatch(shippingCalculate(usd)).then((res) => {
          if (res.payload.data) {
            checkoutshippingCalculateHandler(res.payload.data, methods[1]?.service_code)
            setShippingError(false)
          }
          else {
            setShippingError(true)
            setshipping_charge(0)
          }
        })
      }
    }

  }, [getCountryData, userInfo, methods])

  React.useEffect(() => {
    const getSelectedCountry = getCountryData && getCountryData?.filter((item) => item.id === userInfo?.country)
    if (userInfo?.country && getSelectedCountry) {
      dispatch(getState({ params: { country_code: getSelectedCountry[0]?.code } }))
    }
  }, [getCountryData])

  React.useEffect(() => {
    const getSelectedCountry = getCountryData && getCountryData?.filter((item) => item.id === userInfo?.country)
    const getSelectedState = getStateSelector && getStateSelector?.filter((item) => item.name === userInfo?.state)
    if (userInfo?.state && getSelectedState && getSelectedCountry) {
      if (formik?.values?.state?.length !== 0) {
        dispatch(getCity({ params: { country_code: getSelectedCountry[0]?.code, state_code: getSelectedState[0]?.iso2 } }))
      }
    }
  }, [getStateSelector])

  React.useEffect(() => {
    setCart_no(checkOutItemData?.cart)
    setSub_total(checkOutItemData?.sub_total)
    setprod_disc(checkOutItemData?.product_discount)
    setcoupon_disc(checkOutItemData?.coupon_discount)
    settotal(checkOutItemData?.total)
    setCurrencySymbol(checkOutItemData?.currency_symbol)
  }, [checkOutItemData])


  React.useEffect(() => {
    dispatch(getCheckoutitems({ params: { currency: currency_id } })).then((res) => {
      if (res?.payload?.data) {
        setCheckOutItemData(res?.payload?.data)
        dispatch(getCountry())
        if (currency_id === '2') {
          dispatch(shippingMethodUps()).then((item) => {
            if (item?.payload?.data) {
              setMethods(item?.payload?.data.methods)
              usShippingCalc(res.payload.data.user_details, item?.payload?.data?.methods?.[1].service_code)
            }
          })
        }
      }
    })

  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [isgetcheckoutitemsLoading])

  React.useEffect(() => {
    dispatch(getCheckoutitems({ params: { currency: currency_id } }))
  }, [currency_id])

  React.useEffect(() => {
    if (isgetcheckoutitemsData === undefined) {
      navigate('/category')
    }
  }, [isgetcheckoutitemsData])

  return (
    <>
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      {isgetcheckoutitemsLoading ? <Loader /> :
        <Box className='Checkout-details'>
          <Container className='container checkout-responsive'>
            <Grid container spacing={2} className='Checkout-information-content'>
              <Grid item xs={12} md={6} className='Checkout-information-form'>
                <Box className='Checkout-nav'>
                  <ul>
                    <li>
                      <Link to='/' className='category-list'>
                        Home
                      </Link>
                    </li>
                    <span>/</span>
                    <li>
                      <Link to='/shopping-cart' className='category-list active'>
                        Shopping Cart
                      </Link>
                    </li>
                    <span>/</span>
                    <li>
                      <Link to='' className='category-list active'>
                        Checkout
                      </Link>
                    </li>
                  </ul>
                </Box>
                <Box className='contact-form-details'>
                  <Box component='form' onSubmit={formik.handleSubmit} className='shipping-form-details' noValidate autoComplete='off'>
                    <div className='shipping-acc'>
                      <span>Shipping Address</span>
                    </div>

                    <Box className='form-input-box'>
                      {/* fname */}
                      <FormControl sx={{ width: '49%' }} variant='standard'>
                        <TextField
                          className='input-field'
                          fullWidth
                          id="fname"
                          name="fname"
                          label="First Name"
                          variant="outlined"
                          value={formik.values.fname ?? ""}
                          onChange={formik.handleChange}
                          error={formik.touched.fname && Boolean(formik.errors.fname)}
                          helperText={formik.touched.fname && formik.errors.fname}
                        />
                      </FormControl>

                      {/* lname */}
                      <FormControl sx={{ width: '49%' }} variant='standard'>
                        <TextField
                          className='input-field'
                          fullWidth
                          id="lname"
                          name="lname"
                          label="Last Name"
                          variant="outlined"
                          value={formik.values.lname ?? ''}
                          onChange={formik.handleChange}
                          error={formik.touched.lname && Boolean(formik.errors.lname)}
                          helperText={formik.touched.lname && formik.errors.lname}
                        />
                      </FormControl>
                    </Box>
                    {/* email */}
                    <FormControl sx={{ width: '100%' }} variant='standard'>
                      <TextField
                        className='input-field email-input'
                        fullWidth
                        id="email"
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        value={formik.values.email ?? ''}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </FormControl>
                    {/* phone */}
                    <InputLabel htmlFor='Phone' id="standard-basic" variant="standard" sx={{ mb: '10px' }} className='phone-label'>Phone</InputLabel>
                    <FormControl fullWidth className='select-form-content checkout-phone' sx={{ p: '7px', mb: '20px' }}>
                      <PhoneInput
                        inputProps={{
                          name: 'phone',
                          require: true,
                          autoFocus: true,
                        }}
                        value={formik.values.phone ?? ''}
                        inputComponent={<TextField required name='phone' onChange={handlePhone} style={{ border: 0 }} />}
                        country={'in'}
                        specialLabel="Phone"
                        className="select-phone-checkout"
                      />
                    </FormControl>
                    {/* Company(Optional) */}
                    <FormControl fullWidth className='select-form-fields'>
                      <TextField
                        className='input-field'
                        fullWidth
                        id="companyName"
                        variant="outlined"
                        name="company"
                        label="Company Name(Optional)"
                        value={formik.values.company ?? ''}
                        onChange={formik.handleChange}
                        error={formik.touched.company && Boolean(formik.errors.company)}
                        helperText={formik.touched.company && formik.errors.company}
                      />
                    </FormControl>

                    {/* Address */}
                    <FormControl fullWidth className='select-form-fields'>
                      <TextField
                        className='input-field'
                        fullWidth
                        id="address"
                        name="address"
                        variant="outlined"
                        label="Address"
                        value={formik.values.address ?? ''}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                      />
                    </FormControl>
                    {/* Address 2 */}
                    <FormControl fullWidth className='select-form-fields'>
                      <TextField
                        className='input-field'
                        fullWidth
                        id="address2"
                        name="address2"
                        variant="outlined"
                        label="Address 2"
                        value={formik.values.address2 ?? ''}
                        onChange={formik.handleChange}
                        error={formik.touched.address2 && Boolean(formik.errors.address2)}
                        helperText={formik.touched.address2 && formik.errors.address2}
                      />
                    </FormControl>
                    {/* country */}
                    <FormControl fullWidth className='select-form-fields' variant="outlined">

                      <InputLabel id="country">Country/Region</InputLabel>
                      <Select
                        labelId="country"
                        name='country'
                        label="Country/Region"
                        onChange={(e) => {
                          formik.setFieldValue("country", e.target.value);
                          formik.setFieldValue("state", '');
                          formik.setFieldValue("city", '');
                          const code = getCountryData?.filter((item) => item.id === e.target.value)?.[0]?.code;
                          dispatch(shippingMethodUps())
                          // if (code) {
                          dispatch(getCity({ params: { country_code: code } }));
                          dispatch(getState({ params: { country_code: code } }));
                          // }
                        }}
                        value={formik.values.country ?? ''}
                      >{
                          getCountryData && getCountryData.map((element) =>
                            <MenuItem value={element?.id}>{element?.name}</MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>
                    {/*  */}
                    <Box className='form-input-box'>
                      {/* State */}
                      <FormControl variant="outlined" sx={{ minWidth: '49%' }}>
                        <Autocomplete
                          sx={{ mb: '20px' }}
                          id="state"
                          name="state"
                          disableClearable
                          freeSolo
                          onChange={(event, newValue) => {
                            const countryCode = getCountryData?.filter((item) => item.id === formik.values.country)?.[0]?.code
                            const code = stateData?.filter((item) => item.name === newValue)?.[0]?.iso2
                            dispatch(getCity({ params: { country_code: countryCode, state_code: code } }))
                            formik.setFieldValue("state", newValue)
                            formik.setFieldValue("city", '')
                            dispatch(shippingMethodUps())
                          }}
                          value={formik.values.state ?? ''}
                          options={stateData && stateData?.filter((v, i, a) => a.findIndex(v2 => (v2.name === v.name)) === i)?.map((option) => option.name)}
                          renderInput={(params) => <TextField name='state'
                            error={formik.touched.state && Boolean(formik.errors.state)} onChange={(e) => {
                              formik.setFieldValue("state", e.target.value)
                              formik.setFieldValue("city", '')
                              setCityData([])
                            }} {...params} label="State/Province" />}
                        />
                      </FormControl>

                      {/* over state */}

                      {/* City */}
                      <FormControl variant="outlined" sx={{ minWidth: '49%' }}>
                        <Autocomplete
                          sx={{ mb: '20px' }}
                          id="city"
                          name="city"
                          freeSolo
                          disableClearable
                          onChange={(e, newValue) => {
                            dispatch(shippingMethodUps())
                            formik?.setFieldValue("city", newValue)
                          }}
                          value={formik.values.city ?? ''}
                          options={cityData?.filter((v, i, a) => a.findIndex(v2 => (v2.name === v.name)) === i)?.map((option) => option?.name)}
                          renderInput={(params) => <TextField name='city'
                            error={formik.touched.city && Boolean(formik.errors.city)} onChange={(e) => {
                              formik?.setFieldValue("city", e.target.value)
                            }} {...params} label="City" />}
                        />
                      </FormControl>
                    </Box>

                    {/* Zip Code */}
                    <FormControl fullWidth className='select-form-fields'>
                      <TextField
                        className='input-field'
                        fullWidth
                        id="zip_code"
                        name="zip_code"
                        variant="outlined"
                        // type='number'
                        label="Zip/Postal Code"
                        value={formik.values.zip_code ?? ''}
                        onChange={(e) => {
                          formik.setFieldValue("zip_code", e.target.value)
                          dispatch(shippingMethodUps())
                        }}
                        error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                        helperText={formik.touched.zip_code && formik.errors.zip_code}
                      />
                      <Typography variant='span' sx={{ fontSize: '14px', pl: '15px', pb: '20px' }}>If required, click <Link target='_blank' to="https://worldpostalcode.com/lookup" style={{ color: '#1976d2' }}>here</Link> for zipcode references</Typography>
                    </FormControl>
                    <FormControl fullWidth className='select-form-fields'>
                      <TextField
                        id="special_instructions"
                        name='special_instructions'
                        label="Special Instructions"
                        multiline
                        rows={4}
                        value={formik.special_instructions}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                    {currency_id !== "2" ?
                      <>

                        <Box className='checkout-shipping-btn'>
                          <div className="isshippingcalculate">
                            <Link to='/shopping-cart' className='cart-btn return-cart-btn'>
                              <i className='fa-solid fa-chevron-left'></i> Return to cart
                            </Link>
                          </div>
                          <div className="isshippingcalculate">
                            {isshippingcalculateLoading ?
                              <Button type="submit" className='shipping-btn btn-primary' >
                                <CircularProgress color="inherit" sx={{ width: '18px !important', height: '18px !important' }} />
                              </Button>
                              :
                              <Button className='shipping-btn btn-primary'
                                type='submit'
                              >
                                Calculate Shipping
                              </Button>
                            }
                          </div>
                        </Box>
                        <Box>
                          {isshippingcalculateData?.data && !isFormDataChange && !isshippingcalculateLoading &&
                            <FormControl className='checkout-page' variant="outlined" sx={{ minWidth: '100%', m: '20px 0' }}>
                              {isshippingcalculateData.data.available_shippings.length > 1 &&
                                <FormLabel id="demo-radio-buttons-group-label">Shipping Method</FormLabel>
                              }
                              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                {Array.isArray(isshippingcalculateData.data.available_shippings) && isshippingcalculateData.data.available_shippings.map((shipping, index) => (
                                  <div key={index}>
                                    <FormControlLabel className='shippingMethodRadio'
                                      value={shipping.product_name}
                                      control={<Radio onChange={() => handleShippingOptionChange(shipping)} />} // Pass the correct shipping option
                                      label={shipping.product_name}
                                    />
                                    <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <span style={{ margin: '0px' }}>Price</span>
                                      <span>{currency_symbol_local} {shipping.price.toFixed(2)}</span>
                                    </Typography>
                                    <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <span style={{ margin: '0px' }}>Expected Delivery Date</span>
                                      <span>{shipping.delivery_capabilities}</span>
                                    </Typography>
                                  </div>
                                ))}
                              </RadioGroup>
                            </FormControl>
                          }
                        </Box>
                      </>

                      :
                      <>
                        <FormControl variant="outlined" sx={{ minWidth: '100%', mt: '20px' }}>
                          <InputLabel variant='outlined' name='country' htmlFor='uncontrolled-native'>
                            Shipping Method
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select"
                            value={formik.values.shippingMethod}
                            label="Shipping Method"
                            onChange={async (e) => {
                              await formik.setFieldValue("shippingMethod", e.target.value)
                              const usd = { country: formik.values.country, zip_code: parseInt(formik.values.zip_code), service: e.target.value, currency: parseInt(currency_id) }
                              // if (usd === '') {
                              dispatch(shippingCalculate(usd)).then((res) => {
                                if (res.payload.data) {
                                  checkoutshippingCalculateHandler(res.payload.data, e.target.value)
                                  setShippingError(false)
                                }
                                else {
                                  setShippingError(true)
                                  setshipping_charge(0)
                                }
                              })
                              // }
                            }}
                          >
                            {getshippingMethodUpsData?.methods?.map((item, index) => (
                              <MenuItem value={item?.service_code} key={index}>{item?.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Box className='checkout-shipping-btn'>
                          <Link to='/shopping-cart' className='cart-btn  return-cart-btn'>
                            <i className='fa-solid fa-chevron-left'></i> Return to cart
                          </Link>
                        </Box>
                      </>
                    }
                  </Box>


                </Box>
              </Grid>

              <Grid item xs={12} md={6} className='Checkout-information-products'>
                <Box className='Checkout-product-card'>

                  {CheckoutProduct && CheckoutProduct.map((item, index) => (
                    <Box className='Checkout-product-details unique' key={index}>
                      <Box className='Checkout-product-information'>
                        <Box className='Checkout-product-image'>
                          <img src={item?.image_path} alt='image' />
                          <span>{item?.quantity}</span>
                        </Box>
                        <Box className='checkout-product-content'>
                          <h3>{item?.name}</h3>
                          <p>5coz</p>
                        </Box>
                      </Box>
                      <Box className='Checkout-price-details'>
                        <p> {item?.price == 0 ? 'FREE' : item?.currency + item?.price} </p>
                        {/* <p>{item?.currency} {item?.price}</p> */}
                      </Box>
                    </Box>
                  ))}
                  <Box component='form' className='Checkout-gift-card'>
                    <FormControl fullWidth>
                      <TextField
                        id='outlined-basic'
                        label='Gift card or discount code'
                        placeholder='Gift card or discount code'
                        autoComplete="off"
                        variant='outlined'
                        value={checkOutItemData?.is_coupon_applied === true ? checkOutItemData?.applied_coupon_code : discountCode}
                        onChange={(e) => setdiscountCode(e.target.value)}
                        required
                        type='text'
                      />
                    </FormControl>
                    {(checkOutItemData?.is_coupon_applied === true) ?
                      <>
                        {isremovecouponLoading
                          ?
                          <Button variant='outlined' color='error' sx={{ ml: '10px', borderRadius: '10px' }}>
                            <CircularProgress color="inherit" sx={{ width: '18px !important', height: '18px !important' }} />
                          </Button>
                          :
                          <Button type='submit' variant='outlined' color='error' sx={{ ml: '10px', borderRadius: 'unset' }} onClick={() => removeCouponHandler()}>
                            Remove
                          </Button>
                        }
                      </>
                      :
                      <>
                        {isapplycouponLoading ?
                          <Button className='chcekout-apply'>
                            <CircularProgress color="inherit" sx={{ width: '18px !important', height: '18px !important' }} />
                          </Button>
                          :
                          <Button type='submit' className='chcekout-apply' onClick={() => { discountCode && applyCouponHandler() }}>Apply</Button>
                        }
                      </>
                    }
                  </Box>
                  <Box className='Checkout-total'>
                    <Box className='Checkout-total-content'>
                      <h2>Sub Total</h2>
                      <p>{currencySymbol}{sub_total}</p>
                    </Box>
                    {shipping_charge > 0 &&
                      <Box className='Checkout-total-content'>
                        <h2>Shipping Charge</h2>
                        <p>{currencySymbol}{shipping_charge}</p>
                      </Box>
                    }
                    {coupon_disc !== 0 &&
                      <Box className='Checkout-total-content'>
                        <h2>Coupon Discount</h2>
                        <p>- {currencySymbol}{coupon_disc}</p>
                      </Box>
                    }
                    {prod_disc !== 0 &&
                      <Box className='Checkout-total-content'>
                        <h2>Product Discount</h2>
                        <p>- {currencySymbol}{prod_disc}</p>
                      </Box>
                    }
                  </Box>
                  <Box className='Checkout-total-text'>
                    <h4>Total</h4>
                    <p>
                      {currencySymbol} {total?.toFixed(2)}
                    </p>
                  </Box>
                  <Box className='express-checkout-details'>
                    <h3 className='express-heading'>
                      <span>PAYMENT INFORMATION</span>
                    </h3>
                    <FormControl sx={{ width: '100%' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        sx={{ justifyContent: 'space-evenly', flexDirection: 'unset' }}
                      >
                        <span className='pay-label'>
                          <FormControlLabel value="credit card" className='pay-option-label' control={<Radio />} label="Credit card" />
                          <img src={ShopPay} alt='shoppay' height='75px' width='75px' />
                        </span>
                        <span className='pay-label'>
                          <FormControlLabel value="paypal" className='pay-option-label' control={<Radio />} label="Paypal" />
                          <img src={Gpay} alt='gpay' height='75px' width='75px' />
                        </span>
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box className='checkout-btn'>
                    {loading ?
                      <Button className='shipping-btn btn-primary' >
                        <CircularProgress color="inherit" sx={{ width: '18px !important', height: '18px !important' }} />
                      </Button>
                      :
                      <Button type="submit" className='shipping-btn btn-primary' onClick={() => {
                        formik.validateForm()
                        if (Object.keys(formik.errors).length === 0 && !shippingError) {
                          getgenerateSecret()
                        }

                        formik.validateField('fname');
                        if (!formik.values.fname) {
                          toast.error('Please fill the first name field')
                        }

                        formik.validateField('address');
                        if (!formik.values.address) {
                          toast.error('Please fill the address field')
                        }

                        formik.validateField('email');
                        if (!formik.values.email) {
                          toast.error('Please fill the email field')
                        }

                        formik.validateField('city');
                        if (!formik.values.city) {
                          toast.error('Please fill the city field')
                        }

                      }} sx={{ textTransform: 'capitalize' }}>
                        <ShoppingCartCheckout sx={{ mr: '20px' }} />
                        Checkout
                      </Button>
                    }
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box className='policy-details'>
              <ul className='policy-list-content'>
                <li>
                  <Link to=''>Refund Policy</Link>
                </li>
                <li>
                  <Link to='/privacy-policy'>Privacy</Link>
                </li>
                <li>
                  <Link to='/terms-conditions'>Terms of Service</Link>
                </li>
              </ul>
            </Box>
          </Container>
        </Box >
      }
    </>
  )
}
