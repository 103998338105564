import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const api = process.env.REACT_APP_API_URL;

export const getProfile = createAsyncThunk(
  "getProfile",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${api}/api/login`, values);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getGeneralSetting = createAsyncThunk(
  "getGeneralSetting",
  async ({ rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${api}/api/general-settings`);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getWhyCustomerLoveUs = createAsyncThunk(
  "getWhyCustomerLoveUs",
  async () => {
    try {
      const { data } = await axios.get(
        `${api}/api/images/why-customer-love-us`
      );
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return error.message;
    }
  }
);

export const getBestSeller = createAsyncThunk("bestSeller", async (values) => {
  try {
    const { data } = await axios.get(`${api}/api/best-seller`, values);
    return data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const getUserProfile = createAsyncThunk(
  "profile/getUserProfile",
  async () => {
    try {
      const data = await axios.get(`${api}/api/user`);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return error.message;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "profile/updateUserProfile",
  async (values) => {
    try {
      const data = await axios.post(`${api}/api/update-profile`, values);
      toast.success("Profile Updated");
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return error.message;
    }
  }
);

export const getCountry = createAsyncThunk("profile/getCountry", async () => {
  try {
    const data = await axios.get(`${api}/api/country-list`);
    return data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const getState = createAsyncThunk("profile/getState", async (values) => {
  try {
    const data = await axios.get(`${api}/api/state-list`, values);
    return data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const getCity = createAsyncThunk("profile/getCity", async (values) => {
  try {
    const data = await axios.get(`${api}/api/city-list`, values);
    return data;
  } catch (error) {
    return error.message;
  }
});

export const changePassword = createAsyncThunk(
  "profile/changePassword",
  async (values) => {
    try {
      const data = await axios.post(`${api}/api/change-password`, values);
      toast.success(data.data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors.current_password);
      return error.message;
    }
  }
);

export const logOut = createAsyncThunk("profile/logout", async () => {
  try {
    const data = await axios.get(`${api}/api/logout`);
    toast.success("Logged out successfully");
    if (data.data.status) {
      localStorage.clear();
    }
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getCurrency = createAsyncThunk("profile/currency", async () => {
  try {
    const data = await axios.get(`${api}/api/general-settings/currency`);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getPopularProduct = createAsyncThunk(
  "profile/popularProd",
  async (values) => {
    try {
      const data = await axios.get(`${api}/api/popular-products`, values);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error.message;
    }
  }
);

export const gettermsConditions = createAsyncThunk(
  "termsConditions",
  async () => {
    try {
      const data = await axios.get(`${api}/api/page/terms-conditions`);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error.message;
    }
  }
);

export const getprivacyPolicy = createAsyncThunk("privacyPolicy", async () => {
  try {
    const data = await axios.get(`${api}/api/page/privacy-policy`);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getaboutUs = createAsyncThunk("aboutUs", async () => {
  try {
    const data = await axios.get(`${api}/api/page/about-us`);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getaccessibility = createAsyncThunk("accessibility", async () => {
  try {
    const data = await axios.get(`${api}/api/page/accessibility`);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getdisclaimers = createAsyncThunk("disclaimers", async () => {
  try {
    const data = await axios.get(`${api}/api/page/disclaimers`);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getfaceBook = createAsyncThunk("getfaceBook", async () => {
  try {
    const data = await axios.get(`${api}/api/page/face-book`);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getshippingPolicy = createAsyncThunk(
  "getshippingPolicy",
  async () => {
    try {
      const data = await axios.get(`${api}/api/page/shipping-policy`);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error.message;
    }
  }
);

export const getcookiesPolicy = createAsyncThunk(
  "getcookiesPolicy",
  async () => {
    try {
      const data = await axios.get(`${api}/api/page/cookies-policy`);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error.message;
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axios.post(`${api}/api/forgot-password`, values);
      toast.success(data.data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.message);
    }
  }
);

export const contactRequest = createAsyncThunk(
  "contactRequest",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axios.post(`${api}/api/contactrequest`, values);
      toast.success(data.data.message);
      return data;
    } catch (error) {
      toast.error("Please Fill All Required Fields.");
      return rejectWithValue(error.message);
    }
  }
);

export const addRemovefavorite = createAsyncThunk(
  "addRemovefavorite",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axios.post(`${api}/api/add-remove/favorite`, values);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return rejectWithValue(error.message);
    }
  }
);

export const getTestimonial = createAsyncThunk("getTestimonial", async () => {
  try {
    const { data } = await axios.get(`${api}/api/testimonials`);
    return data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const wishlist = createAsyncThunk("wishlist", async (values) => {
  try {
    const { data } = await axios.get(`${api}/api/favorites`, values);
    return data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const getCategories = createAsyncThunk("categories", async () => {
  try {
    const data = await axios.get(`${api}/api/categories`);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getProducts = createAsyncThunk("getProducts", async (values) => {
  try {
    const data = await axios.get(`${api}/api/products`, values);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getProductDetails = createAsyncThunk(
  "getProductDetails",
  async (values) => {
    let product = window.location.pathname.split("/").filter(Boolean).pop();
    try {
      const data = await axios.get(`${api}/api/product/${product}`, values);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error.message;
    }
  }
);

export const createCart = createAsyncThunk("createCart", async () => {
  try {
    const data = await axios.post(`${api}/api/create-cart`);
    if (data?.data?.cart_id !== "") {
      localStorage.setItem("cart_key", data?.data?.cart_id);
    }
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getCartItems = createAsyncThunk("getCartItems", async (values) => {
  try {
    const cart_key = localStorage.getItem("cart_key");
    const data = await axios.get(`${api}/api/cart-items/${cart_key}`, values);
    toast.success(data?.data?.message);
    return data;
  } catch (error) {
    localStorage.removeItem("cart_key");
    try {
      const data = await axios.post(`${api}/api/create-cart`);
      if (data?.data?.cart_id !== "") {
        localStorage.setItem("cart_key", data?.data?.cart_id);
      }
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error.message;
    }
  }
});

export const addRemoveCartItem = createAsyncThunk(
  "addRemoveCartItem",
  async (values) => {
    try {
      const cart_key = localStorage.getItem("cart_key");
      const data = await axios.post(
        `${api}/api/cart/add-remove-item/${cart_key}`,
        values
      );
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors[0]);
      return error?.message;
    }
  }
);

export const destroyCart = createAsyncThunk("destroyCart", async () => {
  try {
    const cart_key = localStorage.getItem("cart_key");
    const data = await axios.post(`${api}/api/destroy-cart/${cart_key}`);
    localStorage.removeItem("cart_key");
    toast.success(data?.data?.message);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return error.message;
  }
});

export const getCheckoutitems = createAsyncThunk(
  "getCheckoutitems",
  async (values) => {
    try {
      const cart_key = localStorage.getItem("cart_key");
      const data = await axios.get(`${api}/api/checkout/${cart_key}`, values);
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error.message;
    }
  }
);

export const applyCoupon = createAsyncThunk("applyCoupon", async (values) => {
  try {
    const cart_key = localStorage.getItem("cart_key");
    const data = await axios.post(
      `${api}/api/apply-coupon/${cart_key}`,
      values
    );
    toast.success(data?.data?.message);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors[0]);
    return error.message;
  }
});

export const removeCoupon = createAsyncThunk("removeCoupon", async (values) => {
  try {
    const cart_key = localStorage.getItem("cart_key");
    const data = await axios.post(
      `${api}/api/remove-coupon/${cart_key}`,
      values
    );
    toast.success(data?.data?.message);
    return data;
  } catch (error) {
    toast.error(error.response.data.errors[0]);
    return error.message;
  }
});

export const shippingCalculate = createAsyncThunk(
  "shippingCalculate",
  async (values) => {
    try {
      const cart_key = localStorage.getItem("cart_key");
      const data = await axios.post(
        `${api}/api/shipping/${cart_key}/calculate`,
        values
      );
      toast.success("Shipping calculated successfully.");
      return data;
    } catch (error) {
      toast.error(error?.response?.data.message, { autoClose: false });
      return error?.response?.data.message;
    }
  }
);

export const checkoutshippingCalculate = createAsyncThunk(
  "checkoutshippingCalculate",
  async (values) => {
    try {
      const cart_key = localStorage.getItem("cart_key");
      const data = await axios.post(
        `${api}/api/checkout/${cart_key}/shipping`,
        values
      );
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error.message;
    }
  }
);

export const recentlyViewedProducts = createAsyncThunk(
  "recentlyViewedProducts",
  async () => {
    try {
      const data = await axios.get(`${api}/api/recently-viewed/products`);
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors[0]);
      return error.message;
    }
  }
);

export const shippingMethodUps = createAsyncThunk(
  "shippingMethodUps",
  async () => {
    try {
      const data = await axios.get(`${api}/api/shipping/method/ups`);
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors[0]);
      return error.message;
    }
  }
);

export const generateSecret = createAsyncThunk(
  "generateSecret",
  async (values) => {
    try {
      const cart_key = localStorage.getItem("cart_key");
      const data = await axios.post(
        `${api}/api/checkout/${cart_key}/generate-secret`,
        values
      );
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response.data.message);
      return error;
    }
  }
);

export const orderList = createAsyncThunk("orderList", async (values) => {
  try {
    const data = await axios.get(`${api}/api/order/list`, values);
    toast.success(data?.data?.message);
    return data;
  } catch (error) {
    toast.error(error?.response.data.message);
    return error;
  }
});

export const orderDetails = createAsyncThunk("orderDetails", async (values) => {
  try {
    const cart_key = window.location.pathname.split("/").at(-1);
    const data = await axios.get(`${api}/api/order/${cart_key}`, values);
    toast.success(data?.data?.message);
    return data;
  } catch (error) {
    toast.error(error?.response.data.errors[0]);
    return error;
  }
});

export const compareProduct = createAsyncThunk(
  "compareProduct",
  async (values) => {
    try {
      const data = await axios.post(`${api}/api/compare/products`, values);
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response.data.errors[0]);
      return error;
    }
  }
);

export const relatedProduct = createAsyncThunk(
  "relatedProduct",
  async (values) => {
    try {
      const productName = window.location.pathname.split("/").at(-1);
      const data = await axios.get(
        `${api}/api/related-products/${productName}`,
        values
      );
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response.data.errors[0]);
      return error;
    }
  }
);

export const searchProduct = createAsyncThunk(
  "searchProduct",
  async (values) => {
    try {
      const data = await axios.get(`${api}/api/search-products`, values);
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response.data.errors[0]);
      return error;
    }
  }
);

export const paypalPayment = createAsyncThunk(
  "paypalPayment",
  async (values) => {
    try {
      const cart_key = localStorage.getItem("cart_key");
      const data = await axios.post(
        `${api}/api/checkout/${cart_key}/paypal-payment`,
        values
      );
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response.data.errors[0]);
      return error;
    }
  }
);

export const paypalCapture = createAsyncThunk(
  "paypalCapture",
  async (values) => {
    try {
      const data = await axios.post(
        `${api}/api/checkout/paypal-payment/capture`,
        values
      );
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response.data.errors[0]);
      return error;
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "cancelOrder",
  async (values) => {
    try {
      const data = await axios.post(
        `${api}/api/cancel-cart`,
        values
      );
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response.data.errors[0]);
      return error;
    }
  }
);

export const orderDetailsSecret = createAsyncThunk(
  "orderDetailsSecret",
  async (values) => {
    try {
      const data = await axios.get(`${api}/api/order-details`, values);
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response.data.errors[0]);
      return error;
    }
  }
);

export const forgotResendOTP = createAsyncThunk(
  "forgotResendOTP",
  async (values) => {
    try {
      const data = await axios.post(`${api}/api/forgot-resend-otp`, values);
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response.data.errors[0]);
      return error;
    }
  }
);

export const forgotPasswordPhone = createAsyncThunk(
  "forgotPasswordPhone",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axios.post(`${api}/api/forgot-password-phone`, values);
      toast.success(data.data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return error;
    }
  }
);

export const forgotCheckOTP = createAsyncThunk(
  "forgotCheckOTP",
  async (values) => {
    try {
      const data = await axios.post(`${api}/api/forgot-check-otp`, values);
      toast.success(data?.data?.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors.otp);
      return error;
    }
  }
);

export const fileterPrices = createAsyncThunk(
  "fileterPrices",
  async (values) => {
    try {
      const { data } = await axios.get(`${api}/api/filter/prices`, values);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors.otp);
      return error;
    }
  }
);

export const fileterSize = createAsyncThunk(
  "fileterSize",
  async (values) => {
    try {
      const { data } = await axios.get(`${api}/api/filter/sizes`, values);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error;
    }
  }
);

export const price_low_to_high = createAsyncThunk(
  "price_low_to_high",
  async (values) => {
    try {
      const { data } = await axios.get(`${api}/api/products`, values);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error;
    }
  }
);

export const price_high_to_low = createAsyncThunk(
  "price_high_to_low",
  async (values) => {
    try {
      const { data } = await axios.get(`${api}/api/products`, values);
      return data;
    } catch (error) {
      toast.error(error.response.data.errors);
      return error;
    }
  }
);
