import { createSlice } from "@reduxjs/toolkit";
import {
    createCart,
    getCartItems,
    addRemoveCartItem,
    destroyCart,
} from "../../api";

const initialState = {
    isCreateCartLoading: false,
    isCreateCartData: null,
    isgetCartItemsLoading: false,
    isgetCartItemsData: null,
    isaddRemoveCartItemLoading: false,
    isaddRemoveCartItemData: null,
    isdestroyCartItemLoading: false,
    isdestroyCartItemData: null,

};

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {},
    extraReducers: {


        [createCart.pending]: (state) => {
            state.isCreateCartLoading = true;
        },
        [createCart.fulfilled]: (state, { payload }) => {
            state.isCreateCartLoading = false;
            state.isCreateCartData = payload?.data;
        },
        [createCart.rejected]: (state) => {
            state.isCreateCartLoading = false;
        },

        [getCartItems.pending]: (state) => {
            state.isgetCartItemsLoading = true;
        },
        [getCartItems.fulfilled]: (state, { payload }) => {
            state.isgetCartItemsLoading = false;
            state.isgetCartItemsData = payload.data;
        },
        [getCartItems.rejected]: (state) => {
            state.isgetCartItemsLoading = false;
        },

        [addRemoveCartItem.pending]: (state) => {
            state.isaddRemoveCartItemLoading = true;
        },
        [addRemoveCartItem.fulfilled]: (state, { payload }) => {
            state.isaddRemoveCartItemLoading = false;
            state.isgetCartItemsData = payload.data;
        },
        [addRemoveCartItem.rejected]: (state) => {
            state.isaddRemoveCartItemLoading = false;
        },

        [destroyCart.pending]: (state) => {
            state.isdestroyCartItemLoading = true;
            // state.isgetCartItemsLoading = true;
        },
        [destroyCart.fulfilled]: (state, { payload }) => {
            state.isdestroyCartItemLoading = false;
            // state.isdestroyCartItemData = payload.data;
            // state.isgetCartItemsLoading = false;
            state.isgetCartItemsData = null
        },
        [destroyCart.rejected]: (state) => {
            state.isgetCartItemsLoading = false;
        },

    },
});

// Action creators are generated for each case reducer function
export const { } = cartSlice.actions;

export default cartSlice.reducer;
