import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import '../Profile.css'
import { changePassword } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import { getChangePasswordStatus } from '../../../selector';
import { CircularProgress } from '@mui/material';

const validationSchema = yup.object({
    current_password: yup
        .string('Enter your Current Password')
        .required('Current Password Required'),
    password: yup
        .string('Enter your new password')
        .min(6, 'Password should be of minimum 6 characters length')
        .required('Password Required'),
    password_confirmation: yup
        .string('Confirm New Password')
        .oneOf([yup.ref('password'), null], 'Passwords must match with New password')
        .required('Confirm Password Required')
});

const ChangePassword = () => {
    const [curr_showPassword, setcurr_ShowPassword] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)
    const [conf_showPassword, setconf_ShowPassword] = React.useState(false)
    const ChangePasswordStatus = useSelector(getChangePasswordStatus);

    const dispatch = useDispatch()

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleSubmit = (values) => {
        const payload = { ...values }
        dispatch(changePassword(payload))
        formik.resetForm()
    }
    const formik = useFormik({
        initialValues: {
            current_password: '',
            password: '',
            password_confirmation: ''
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit} className="change-password">
                <h3>Change Password</h3>
                <TextField
                    className='input-field'
                    fullWidth
                    id="current_password"
                    name="current_password"
                    label="Current Password"
                    type={curr_showPassword ? 'text' : 'password'}
                    value={formik.values.current_password}
                    onChange={formik.handleChange}
                    error={formik.touched.current_password && Boolean(formik.errors.current_password)}
                    helperText={formik.touched.current_password && formik.errors.current_password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setcurr_ShowPassword((show) => !show)}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {curr_showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    className='input-field'
                    fullWidth
                    id="password"
                    name="password"
                    label="New Password"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setShowPassword((show) => !show)}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    className='input-field'
                    fullWidth
                    id="password_confirmation"
                    name="password_confirmation"
                    label="Confirm Password "
                    type={conf_showPassword ? 'text' : 'password'}
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                    helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setconf_ShowPassword((show) => !show)}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {conf_showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {ChangePasswordStatus ?
                    <Button Button color="primary" variant="contained" fullWidth type="submit" className='changePwd-btn btn-primary' >
                        <CircularProgress color="inherit" />
                    </Button>
                    :
                    <Button color="primary" variant="contained" fullWidth type="submit" className='changePwd-btn btn-primary' >
                        Submit
                    </Button>
                }
            </form>
        </div >
    );
};

export default ChangePassword
