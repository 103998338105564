import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { checkoutshippingcalculateData } from '../../selector';
import { useSelector } from 'react-redux';

export default function Paypal() {
    const ischeckoutshippingcalculateData = useSelector(checkoutshippingcalculateData)
    const amount = ischeckoutshippingcalculateData?.data.total

    return (
        <>
            <PayPalScriptProvider
                options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, "disable-funding": "card" }}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: amount,
                                },
                            },
                        ],
                    });
                }}
                onApprove={async (data, actions) => {
                    const details = await actions.order.capture();
                    const name = details.payer.name.given_name;
                    alert("Transaction completed by " + name);
                }}
            >

            </PayPalScriptProvider>
        </>
    )
}
