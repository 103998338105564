import { createSlice } from "@reduxjs/toolkit";
import {
    getCategories,
    getProducts,
    getProductDetails,
    recentlyViewedProducts,
    relatedProduct,
    fileterPrices,
    fileterSize
} from "../../api";

const initialState = {
    isCategoriesLoading: false,
    isCategoriesData: null,
    isProductsLoading: false,
    isProductsData: null,
    isProductdetailsLoading: false,
    isProductdetailsData: null,
    isrecentlyViewedProductsLoading: false,
    isrecentlyViewedProductsData: null,
    isrelatedProductLoading: false,
    isrelatedProductData: null,
    isFilterPricesLoading: false,
    isFilterPricesData: null,
    isFilterSizeLoading: false,
    isFilterSizeData: null
};

export const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {},
    extraReducers: {

        [getCategories.pending]: (state) => {
            state.isCategoriesLoading = true;
        },
        [getCategories.fulfilled]: (state, { payload }) => {
            state.isCategoriesLoading = false;
            state.isCategoriesData = payload?.data.products;
        },
        [getCategories.rejected]: (state) => {
            state.isCategoriesLoading = false;
        },

        [getProducts.pending]: (state) => {
            state.isProductsLoading = true;
        },
        [getProducts.fulfilled]: (state, { payload }) => {
            state.isProductsLoading = false;
            state.isProductsData = payload?.data;
        },
        [getProducts.rejected]: (state) => {
            state.isProductsLoading = false;
        },

        [getProductDetails.pending]: (state) => {
            state.isProductdetailsLoading = true;
        },
        [getProductDetails.fulfilled]: (state, { payload }) => {
            state.isProductdetailsLoading = false;
            state.isProductdetailsData = payload?.data;
        },
        [getProductDetails.rejected]: (state) => {
            state.isProductdetailsLoading = false;
        },

        [recentlyViewedProducts.pending]: (state) => {
            state.isrecentlyViewedProductsLoading = true;
        },
        [recentlyViewedProducts.fulfilled]: (state, { payload }) => {
            state.isrecentlyViewedProductsLoading = false;
            state.isrecentlyViewedProductsData = payload?.data;
        },
        [recentlyViewedProducts.rejected]: (state) => {
            state.isrecentlyViewedProductsLoading = false;
        },

        [relatedProduct.pending]: (state) => {
            state.isrelatedProductLoading = true;
        },
        [relatedProduct.fulfilled]: (state, { payload }) => {
            state.isrelatedProductLoading = false;
            state.isrelatedProductData = payload?.data;
        },
        [relatedProduct.rejected]: (state) => {
            state.isrelatedProductLoading = false;
        },

        [fileterPrices.pending]: (state) => {
            state.isFilterPricesLoading = true;
        },
        [fileterPrices.fulfilled]: (state, { payload }) => {
            state.isFilterPricesLoading = false;
            state.isFilterPricesData = payload.price;
        },
        [fileterPrices.rejected]: (state) => {
            state.isFilterPricesLoading = false;
        },

        [fileterSize.pending]: (state) => {
            state.isFilterSizeLoading = true;
        },
        [fileterSize.fulfilled]: (state, { payload }) => {
            state.isFilterSizeLoading = false;
            state.isFilterSizeData = payload.sizes;
        },
        [fileterSize.rejected]: (state) => {
            state.isFilterSizeLoading = false;
        },

    },
});

// Action creators are generated for each case reducer function
export const { } = productSlice.actions;

export default productSlice.reducer;
