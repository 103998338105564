import "./App.css";
import Home from "./Components/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useOutlet,
  useLocation,
} from "react-router-dom";
import Categories from "./Components/Categories/categories";
import Products from "./Components/Products/products";
import Checkout from "./Components/Checkout/Checkout";
import Login from "./Components/Login/Login";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import ShippingCart from "./Components/ShippingCart/ShippingCart";
import ThankYou from "./Components/ThankYou/ThankYou";
import axios from "axios";
import TopMenu from "./Components/Header/header";
import Footer from "./Components/Footer/footer";
import Profile from "./Components/Profile/Profile";
import ChangePassword from "./Components/Profile/changePassword/ChangePassword";
import PrivacyPolicy from "./Components/StaticPages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./Components/StaticPages/TermsConditions/TermsConditions";
import AboutUs from "./Components/StaticPages/AboutUs/AboutUs";
import Accessibility from "./Components/StaticPages/Accessibility/Accessibility";
import Disclaimers from "./Components/StaticPages/Disclaimers/Disclaimers";
import { generatesecretLoading, isGeneralSettingLoading } from "./selector";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Components/Loader/Loader";
import { getGeneralSetting } from "./api";
import { useEffect } from "react";
import FaceBook from "./Components/StaticPages/FaceBook/FaceBook";
import ShippingPolicy from "./Components/StaticPages/ShippingPolicy/ShippingPolicy";
import CookiesPolicy from "./Components/StaticPages/CookiesPolicy/CookiesPolicy";
import ContactForm from "./Components/contactForm/ContactForm";
import Wishlist from "./Components/wishlist/Wishlist";
import ScrollToTop from "./ScrollToTop";
import OrderHistory from "./Components/OrderHistory/OrderHistory";
import OrderDetails from "./Components/OrderHistory/OrderDetails";
import CompareProducts from "./Components/CompareProduct/CompareProducts";
// import Chatbox from "./Components/ChatBox/Chatbox";
import Paypal from "./Components/Paypal/Paypal";
import { ToastContainer } from "react-toastify";
import CancelOrder from "./Components/CancelOrder/CancelOrder";
import StripePath from "./Components/StripeForm/StripePath";
import Page404 from "./Components/Page404/Page404";

axios.interceptors.request.use(
  (config) => {
    const url = config.url.split("/").includes(["country-list", "login"]);
    const token = localStorage.getItem("token");
    if (token && !url) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.message === "Unauthenticated."
    ) {
      localStorage.clear("token"); //place your reentry code
      window.location.assign(`${process.env.REACT_APP_BASE_URL}/login`);
    }

    return Promise.reject(error);
  }
);

export const ProtectedLayout = () => {
  const token = localStorage.getItem("token");
  const outlet = useOutlet();

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <>{outlet}</>;
};

function App() {
  const dispatch = useDispatch();
  const GeneralSettingLoading = useSelector(isGeneralSettingLoading);
  const isgeneratesecretLoading = useSelector(generatesecretLoading);

  useEffect(() => {
    dispatch(getGeneralSetting({}));
  }, []);

  return (
    <>
      <ToastContainer />
      {(GeneralSettingLoading || isgeneratesecretLoading) && <Loader />}
      <Router>
        <TopMenu />
        <ScrollToTop />
        <Routes>
          <Route path="/404" element={<Page404 />}></Route>
          <Route path="/" element={<ProtectedLayout />}>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="/category" element={<Categories />} />
            <Route
              path="/payment/:client_secret"
              exact
              element={
                <>
                  <StripePath />
                </>
              }
            />
            <Route path="/payment/paypal" exact element={<Paypal />} />
            <Route path="/category/:categoryname" element={<Categories />} />
            <Route
              path="/category/:categoryname/:subcategoryname"
              element={<Categories />}
            />
            <Route exact path="/product/:product" element={<Products />} />
            <Route exact path="/product/:product/:id" element={<Products />} />
            <Route exact path="/checkout" element={<Checkout />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/order-history" element={<OrderHistory />} />
            <Route exact path="/order-history/:id" element={<OrderDetails />} />
          </Route>
          <Route path="/login" element={<Login />}></Route>
          <Route
            exact
            path="/resetpassword"
            element={<ResetPassword />}
          ></Route>
          <Route exact path="/shopping-cart" element={<ShippingCart />}></Route>
          <Route exact path="/account/wishlist" element={<Wishlist />}></Route>
          <Route exact path="/thank-you" element={<ThankYou />}></Route>
          <Route
            exact
            path="/privacy-policy"
            element={<PrivacyPolicy />}
          ></Route>
          <Route
            exact
            path="/terms-conditions"
            element={<TermsConditions />}
          ></Route>
          <Route exact path="/about-us" element={<AboutUs />}></Route>
          <Route
            exact
            path="/accessibility"
            element={<Accessibility />}
          ></Route>
          <Route exact path="/disclaimers" element={<Disclaimers />}></Route>
          <Route exact path="/face-book" element={<FaceBook />}></Route>
          <Route
            exact
            path="/shipping-policy"
            element={<ShippingPolicy />}
          ></Route>
          <Route
            exact
            path="/cookies-policy"
            element={<CookiesPolicy />}
          ></Route>
          <Route exact path="/contact-us" element={<ContactForm title={true} />}></Route>
          <Route
            exact
            path="/compare-product"
            element={<CompareProducts />}
          ></Route>
          <Route exact path="/cancel" element={<CancelOrder />}></Route>
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
