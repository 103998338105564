import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import 'react-phone-input-2/lib/style.css'
import './Profile.css'
import PhoneInput from 'react-phone-input-2';
import { FormHelperText, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCountry, getState, getCity, getUserProfile, updateUserProfile } from '../../api';
import { getCountrySelector, getUserProfileData, isLoadingUpdateProfile, isgetCityData, isgetStateSelector } from '../../selector';
import CircularProgress from '@mui/material/CircularProgress';

const validationSchema = yup.object({
  fname: yup
    .string('Enter your first name')
    .min(3).nullable()
    .required('FirstName Required'),
  lname: yup
    .string('Enter your last name')
    .min(3)
    .required('LastName Required'),
  email: yup
    .string('Enter your email')
    .min(3)
    .email('Enter a valid email')
    .required('Email Required'),
  phone: yup
    .number('Enter your phone number')
    .min(10)
    .required('Phone Number Required'),
  your_address: yup
    .string('Enter your address')
    .required('Address Required'),
  city: yup
    .string('Enter your city')
    .required('City Required'),
  state: yup
    .string('Enter your State')
    .required('State Required'),
  zip: yup
    .string('Enter your Zipcode')
    .required('Zipcode Required'),
  country: yup
    .string('Enter your country')
    .required('Country Required'),
});

const Profile = () => {
  const dispatch = useDispatch()
  const UserProfileData = useSelector(getUserProfileData)
  const getCountryData = useSelector(getCountrySelector)
  const getCityData = useSelector(isgetCityData)
  const getStateSelector = useSelector(isgetStateSelector)
  const LoadingUpdateProfile = useSelector(isLoadingUpdateProfile);
  const getData = UserProfileData?.data
  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      your_address: '',
      // address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      country_code_id: '',
      country_code: '',
      company: '',
      website: '',
      lr_number: '',
      issuing_state: '',
      where_use: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = { ...values, country_code_id: values.phone.slice(0, 2), phone: values.phone.slice(2, values.phone.length + 1) }
      delete payload.company;
      delete payload.website;
      delete payload.lr_number;
      delete payload.issuing_state;
      delete payload.where_use;
      dispatch(updateUserProfile(payload))
    },
  });

  const handlePhone = (e) => {
    formik.setFieldValue("phone", e)
  }

  useEffect(() => {
    if (!getData) return
    formik.setFieldValue("fname", getData?.fname)
    formik.setFieldValue("lname", getData?.lname)
    formik.setFieldValue("email", getData?.email)
    formik.setFieldValue("phone", getData?.country_code_id + getData?.phone)
    formik.setFieldValue("your_address", getData?.your_address)
    // formik.setFieldValue("address1", getData?.address1)
    formik.setFieldValue("address2", getData?.address2)
    formik.setFieldValue("city", getData?.city)
    formik.setFieldValue("zip", getData?.zip)
    formik.setFieldValue("state", getData?.state)
    formik.setFieldValue("company", getData?.company)
    formik.setFieldValue("website", getData?.website)
    formik.setFieldValue("lr_number", getData?.lr_number)
    formik.setFieldValue("issuing_state", getData?.issuing_state)
    formik.setFieldValue("where_use", getData?.where_use)
    formik.setFieldValue("subject", getData?.sebject)
    if (getData && getCountryData) {
      const state = getCountryData?.filter((item) => item.id === getData?.country)
      formik.setFieldValue("country_code", state[0]?.code)
      formik.setFieldValue("country", state[0]?.id)
    }
  }, [getData, getCountryData])

  useEffect(() => {
    const getSelectedCountry = getCountryData && getCountryData?.filter((item) => item.id === getData?.country)
    if (getData?.country && getSelectedCountry) {
      dispatch(getState({ params: { country_code: getSelectedCountry[0]?.code } }))
    }
  }, [getCountryData, getData])

  useEffect(() => {
    const getSelectedCountry = getCountryData && getCountryData?.filter((item) => item.id === getData?.country)
    const getSelectedState = getStateSelector && getStateSelector?.filter((item) => item.name === getData?.state)
    if (getData?.state && getSelectedState && getSelectedCountry) {
      if (formik?.values.state.length !== 0 && formik?.values.country_code.length !== 0) {
        dispatch(getCity({ params: { country_code: formik?.values.country_code, state_code: getSelectedState[0]?.iso2 } }))
      }
    }
  }, [getCountryData, getStateSelector])


  const handleState = async (value) => {
    if (getCountryData) {
      const state = getCountryData?.filter((item) => item.id === value)
      await formik?.setFieldValue("country_code", state[0]?.code)
      dispatch(getState({ params: { country_code: state[0]?.code } }))
      formik.setFieldValue("city", "")
    }
  }
  const handleGetCity = (value) => {
    const getSelectedState = getStateSelector && getStateSelector?.filter((item) => item.name === value)
    if (value) {
      dispatch(getCity({ params: { country_code: formik?.values?.country_code, state_code: getSelectedState[0]?.iso2 } }))
    }
  }

  useEffect(() => {
    dispatch(getUserProfile())
    dispatch(getCountry())
  }, [])

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="profile-form">
        <Typography variant='h1' component='h1' className='form-title'>PERSONAL INFORMATION</Typography>
        {/* first name */}
        <TextField
          className='input-field'
          fullWidth
          id="fname"
          name="fname"
          label="First Name"
          variant="standard"
          value={formik.values.fname ?? ""}
          onChange={formik.handleChange}
          error={formik.touched.fname && Boolean(formik.errors.fname)}
          helperText={formik.touched.fname && formik.errors.fname}
        />
        {/* last name */}
        <TextField
          className='input-field'
          fullWidth
          id="lname"
          name="lname"
          label="Last Name"
          variant="standard"
          value={formik.values.lname ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.lname && Boolean(formik.errors.lname)}
          helperText={formik.touched.lname && formik.errors.lname}
        />
        {/* email */}
        <TextField
          className='input-field email-input'
          fullWidth
          id="email"
          name="email"
          label="Email Address"
          variant="standard"
          value={formik.values.email ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        {/* phone no */}
        <InputLabel htmlFor='Phone' id="standard-basic" variant="standard" sx={{ mb: '10px' }} className='phone-label'>Phone</InputLabel>
        <PhoneInput
          inputProps={{
            name: 'phone',
            require: true,
            autoFocus: true,
          }}
          inputComponent={<TextField />}
          country={'in'}
          name="phone"
          value={formik.values.phone ?? ''}
          specialLabel="Phone"
          onChange={handlePhone}
          className="select-phone"
        />
        {/* address */}
        <TextField
          className='input-field'
          fullWidth
          id="address"
          name="your_address"
          variant="standard"
          label="Your Address"
          value={formik.values.your_address ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.your_address && Boolean(formik.errors.your_address)}
          helperText={formik.touched.your_address && formik.errors.your_address}
        />
        <TextField
          className='input-field'
          fullWidth
          id="address2"
          name="address2"
          label="Address 2"
          variant="standard"
          value={formik.values.address2 ?? ''}
          onChange={formik.handleChange}
        />
        {/* Country */}
        <FormControl variant="standard" sx={{ minWidth: '100%', mt: '10px' }}>
          <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select"
            value={formik.values.country}
            label="country"
            onChange={(e, newValue) => {
              formik.setFieldValue("country", e.target.value)
              formik.setFieldValue("state", '')
              formik.setFieldValue("city", '')
              handleState(e.target.value);
            }}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          >
            {getCountryData && getCountryData?.map((item) =>
              <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>
            )}
          </Select>
        </FormControl>
        {/* State/Province  */}
        <FormControl variant="standard" sx={{ minWidth: '100%', mt: '10px' }}>
          <InputLabel id="demo-simple-select-standard-label">State</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select"
            value={formik.values.state ?? ''}
            label="state"
            name='state'
            onChange={(e) => {
              formik?.setFieldValue("state", e.target.value)
              formik?.setFieldValue("city", "")
              handleGetCity(e.target.value)
            }}
            error={formik.touched.state && formik.errors.state}
          >
            {(getStateSelector === null || !formik?.values?.country) &&
              <Typography variant='span' component='span' sx={{ color: 'gray', fontSize: '12px', pl: '15px' }}> * select country first</Typography>
            }
            {getStateSelector && getStateSelector?.map((item) =>
              <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
            )}
          </Select>
          {formik.touched.state || formik.errors.state ? (
            <FormHelperText>{formik.errors.state}</FormHelperText>
          ) : null}
        </FormControl>
        {/* city */}
        <FormControl variant="standard" sx={{ minWidth: '100%', mt: '10px' }}>
          <InputLabel id="demo-simple-select-standard-label">City</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select"
            value={formik.values.city ?? ''}
            label="city"
            name="city"
            onChange={(e) => formik?.setFieldValue("city", e.target.value)}
            error={formik.touched.city && formik.errors.city}
          >
            {(getCityData === null || !formik?.values?.state) &&
              <Typography variant='span' component='span' sx={{ color: 'gray', fontSize: '12px', pl: '15px' }}> * select state first</Typography>
            }
            {(formik?.values.state) && getCityData && getCityData?.map((item) =>
              <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
            )}
          </Select>
          {formik.touched.city || formik.errors.city ? (
            <FormHelperText>{formik.errors.city}</FormHelperText>
          ) : null}
        </FormControl>
        {/* Zip/Postal Code * */}
        <TextField
          className='input-field'
          fullWidth
          id="zipcode"
          name="zip"
          // type="number"
          variant="standard"
          label="Zip/Postal Code"
          value={formik.values.zip ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.zip && Boolean(formik.errors.zip)}
          helperText={formik.touched.zip && formik.errors.zip}
        />

        <Typography variant='h1' component='h1' className='form-title'>COMPANY INFORMATION</Typography>
        {/* company name */}
        <TextField
          className='input-field'
          fullWidth
          id="companyName"
          variant="standard"
          name="company"
          label="Company"
          disabled
          value={formik.values.company ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.company && Boolean(formik.errors.company)}
          helperText={formik.touched.company && formik.errors.company}
        />
        {/* website */}
        <TextField
          className='input-field'
          fullWidth
          id="website"
          name="website"
          variant="standard"
          label='Website'
          disabled
          value={formik.values.website ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.website && Boolean(formik.errors.website)}
          helperText={formik.touched.website && formik.errors.website}
        />
        {/* Ir Number  */}
        <TextField
          className='input-field'
          fullWidth
          id="Irno"
          type="text"
          name="lr_number"
          label="LR Number"
          variant="standard"
          disabled
          value={formik.values.lr_number ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.lr_number && Boolean(formik.errors.lr_number)}
          helperText={formik.touched.lr_number && formik.errors.lr_number}
        />
        {/* isuuing state  */}
        <TextField
          className='input-field'
          fullWidth
          id="issuing_state"
          variant="standard"
          name="issuing_state"
          label="Issuing State"
          disabled
          value={formik.values.issuing_state ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.issuing_state && Boolean(formik.errors.issuing_state)}
          helperText={formik.touched.issuing_state && formik.errors.issuing_state}
        />

        {/* Where use */}
        <FormControl variant="standard" sx={{ minWidth: '100%' }}>
          <InputLabel id="demo-simple-select-standard-label">Where Use</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            disabled
            value={formik.values.where_use ?? ""}
            onChange={(e) => formik.setFieldValue("where_use", e.target.value)}
            label="Age"
          >
            <MenuItem value="" disabled>
              <em>Please Select</em>
            </MenuItem>
            <MenuItem value={"Salon Use"}>Salon Use</MenuItem>
            <MenuItem value={"Amazon"}>Amazon</MenuItem>
            <MenuItem value={"Retail Brick & Mortar Store"}>Retail Brick & Mortar Store</MenuItem>
            <MenuItem value={"Your Own eCommerce Store"}>Your Own eCommerce Store</MenuItem>
            <MenuItem value={"eBay or Other 3rd Party Platform"}>eBay or Other 3rd Party Platform</MenuItem>
          </Select>
        </FormControl>

        {LoadingUpdateProfile ?
          <Button color="primary" variant="contained" fullWidth className='profile-btn btn-primary' >
            <CircularProgress color="inherit" sx={{ height: '28px !important', width: '28px !important' }} />
          </Button>
          :
          <Button color="primary" variant="contained" fullWidth type="submit" className='profile-btn btn-primary'>
            Submit
          </Button>}
      </form>
    </>
  )
}

export default Profile