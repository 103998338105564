import { createSlice } from "@reduxjs/toolkit";
import {
    compareProduct
} from "../../api";

const initialState = {
    iscompareProductLoading: false,
    iscompareProductData: null,
};

export const compareProductSlice = createSlice({
    name: "compareproduct",
    initialState,
    reducers: {},
    extraReducers: {

        [compareProduct.pending]: (state) => {
            state.iscompareProductLoading = true;
        },
        [compareProduct.fulfilled]: (state, { payload }) => {
            state.iscompareProductLoading = false;
            state.iscompareProductData = payload?.data?.products;
        },
        [compareProduct.rejected]: (state) => {
            state.iscompareProductLoading = false;
        },

    },
});

// Action creators are generated for each case reducer function
export const { } = compareProductSlice.actions;

export default compareProductSlice.reducer;
