import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import CorrectIcon from './img/correct.png'
import { useDispatch } from 'react-redux'
import { cancelOrder } from '../../api'

const CancelOrder = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const token = window.location.search.split('=')[1]
        if (token) {
            dispatch(cancelOrder({ token: token }))
        }
    }, [])

    return (
        <Box className='Thankyou-details'>
            <Container className='container'>
                <Box className='Thank-you-heading'>
                    <img src={CorrectIcon} className='correcticon' />
                    <h3>Order Cancelled</h3>
                    {/* <h3>Your Order has been recieved</h3> */}
                    {/* {checkPath === '?payment_intent' ? <h4>Order #{ccOrderId}</h4> : <h4>Order #{orderId}</h4>} */}
                    <p>
                        You will receive an invoice with tracking information shortly. Please contact us if you have any questions in relation to your
                        order by visiting our contact page here or by emailing  <Link target='_blank' to='mailto:cs@prohairlabs.com'>cs@prohairlabs.com</Link>. Please ensure to quote your order number when
                        contacting us.
                    </p>
                </Box>
                <Link className='order-detail-return' to='/'>
                    <i className='fa-solid fa-chevron-left'></i> return to Home
                </Link>
            </Container>
        </Box>
    )
}

export default CancelOrder