import { createSlice } from "@reduxjs/toolkit";
import {
    orderList,
    orderDetails
} from "../../api";

const initialState = {
    isorderListLoading: false,
    isorderListData: null,
    isorderDetailsLoading: false,
    isorderDetailsData: null,
};

export const orderHistorySlice = createSlice({
    name: "orderHistory",
    initialState,
    reducers: {},
    extraReducers: {

        [orderList.pending]: (state) => {
            state.isorderListLoading = true;
        },
        [orderList.fulfilled]: (state, { payload }) => {
            state.isorderListLoading = false;
            state.isorderListData = payload.data;
        },
        [orderList.rejected]: (state) => {
            state.isorderListLoading = false;
        },

        [orderDetails.pending]: (state) => {
            state.isorderDetailsLoading = true;
        },
        [orderDetails.fulfilled]: (state, { payload }) => {
            state.isorderDetailsLoading = false;
            state.isorderDetailsData = payload.data;
        },
        [orderDetails.rejected]: (state) => {
            state.isorderDetailsLoading = false;
        },

    },
});

// Action creators are generated for each case reducer function
export const { } = orderHistorySlice.actions;

export default orderHistorySlice.reducer;
