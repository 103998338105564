import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link } from 'react-router-dom'
import './DeliveryIcon.css'
import Grid from '@mui/material/Grid' // Grid version 1
import Icon1 from './img/global-delivery 1.png'
import Icon2 from './img/delivery-truck 1.png'
import Icon3 from './img/delivery-time 1.png'
import Icon4 from './img/safe-payment 1.png'

export default function DeliveryIcon() {
  return (
    <Box className='DeliveryIcon-section'>
      <Container className='container delivery-custom'>
        <Grid container spacing={2} className='DeliveryIcon-details'>
          <Grid item xs={12} md={3} className='DeliveryIcon_content'>
            <Box className='DeliveryIcon-area'>
              <img src={Icon1} alt='delivery icon' />
              <p>Delivery to USA & CANADA</p>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} className='DeliveryIcon_content'>
            <Box className='DeliveryIcon-area'>
              <img src={Icon2} alt='delivery icon' />
              <p>UPS Delivery</p>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} className='DeliveryIcon_content'>
            <Box className='DeliveryIcon-area'>
              <img src={Icon3} alt='delivery icon' />
              <p>Same Day Delivery</p>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} className='DeliveryIcon_content'>
            <Box className='DeliveryIcon-area'>
              <img src={Icon4} alt='delivery icon' />
              <p>Secure Payment</p>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
