import { Button, Checkbox, CircularProgress, Collapse, Container, FormControlLabel, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, MenuList, Pagination, Slider } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import './filterProducts.css'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { addRemoveCartItem, addRemovefavorite, createCart, fileterSize, getCartItems, getCategories, getProducts, recentlyViewedProducts, searchProduct, } from '../../api'
import { useDispatch, useSelector } from 'react-redux'
import { addRemoveCartItemLoading, currencyData, filterPricesData, filterPricesLoading, filterSizeData, filterSizeLoading, getCategoriesData, getCategoriesLoading, getCreateCartLoading, getProductsData, getProductsLoading, recentlyViewedProductsData, recentlyViewedProductsLoading, searchProductData, searchProductLoading } from '../../selector'
import Loader from '../Loader/Loader'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CompareSharp, ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material'
import { useEffect } from 'react'
import { fileterPrices, price_high_to_low, price_low_to_high } from '../../api'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet'
import { toast } from "react-toastify";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

export default function FilterProducts() {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  const FilterPricesLoadingData = useSelector(filterPricesLoading)
  const FilterPricesData = useSelector(filterPricesData)
  const FilterSizeLoadingData = useSelector(filterSizeLoading)
  const FilterSizeData = useSelector(filterSizeData)

  const [prod_id, setProd_id] = React.useState()
  const [page, setPage] = React.useState(1)
  const [order_by, setOrder_by] = React.useState('featured')
  const [price, setPrice] = React.useState([0, 0])
  const [value, setValue] = React.useState([0, 0])
  const [category, setCategory] = React.useState([])
  const [expanded, setExpanded] = React.useState('panel1')
  const [curr_symbol, setCurr_symbol] = React.useState('')
  const [products, seProducts] = React.useState()
  const [subCategory, setSubCategory] = React.useState([])
  const [productsPerPage, setProductsPerPage] = React.useState(5);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isaddRemoveCartItemLoading = useSelector(addRemoveCartItemLoading)
  const CreateCartLoading = useSelector(getCreateCartLoading)
  const CategoriesData = useSelector(getCategoriesData)
  const CategoriesLoading = useSelector(getCategoriesLoading)
  const ProductsData = useSelector(getProductsData)
  const ProductsDataLoading = useSelector(getProductsLoading)
  const isrecentlyViewedProductsData = useSelector(recentlyViewedProductsData)

  useEffect(() => {
    const currency_id = localStorage.getItem('currency_id');
    dispatch(fileterPrices({ params: { currency: currency_id } }))
    dispatch(fileterSize({ params: { currency: currency_id } }))
  }, [])

  useEffect(() => {
    setPrice([FilterPricesData?.min, FilterPricesData?.max])
    setValue([FilterPricesData?.min, FilterPricesData?.max])
  }, [FilterPricesData])

  const [product_id, setProduct_id] = React.useState()
  const [allData, setAllData] = React.useState()
  const [size, setSize] = React.useState([])
  const currency_id = localStorage.getItem('currency_id');
  const path = useParams()

  // const handleChange = (event) => {
  //   setOrder_by(event.target.value)
  // }

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setOrder_by(selectedValue);

    if (selectedValue === 'price_low_to_high') {
      dispatch(price_low_to_high({
        params: {
          order_by: order_by
        }
      }));
    } else if (selectedValue === 'price_high_to_low') {
      dispatch(price_high_to_low({
        params: {
          order_by: order_by
        }
      }));
    } else {
      // Handle other cases if needed
    }
  };



  const handleChangePrice = (event, newValue) => {
    setValue(newValue);
    setPage(1)
  };

  const handleSliderMouseUp = (event) => {
    setPrice(value);
  };

  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };


  const handleProductLength = (event, value) => {
    setProductsPerPage(event.target.value);
    setPage(1);
  }

  const handleCheck = (event, item) => {
    var updatedList = [...category];
    var subCategoryUpdate = [...subCategory];
    var uniqueArray = [...new Set(subCategoryUpdate)];

    setPage(1)
    if (event.target.checked === true) {
      updatedList = [...category, event.target.value];

      subCategoryUpdate = item?.sub_categories.map((sub_cat) => (
        subCategory.push(sub_cat.slug)
      ))
      setCategory(updatedList);
    } else if (event.target.checked === false) {
      let newArr = subCategory
      subCategoryUpdate = item?.sub_categories.map((sub_cat) => {
        if (newArr.includes(sub_cat.slug)) {
          var index = newArr.indexOf(sub_cat.slug);
          if (index !== -1) {
            newArr.splice(index, 1);
          }
        }
      })
      setSubCategory(newArr)

      setCategory(updatedList?.filter((item) => item !== event.target.value))
    }
    else {
      updatedList.fill(category.indexOf(event.target.value), 1);
      setCategory(updatedList);
    }
  };

  // sub categories
  const handleSubCategory = (event, item) => {
    var updatedList = subCategory ? [...subCategory] : [];
    var uniqueSubcategory = updatedList ? [...new Set(updatedList)] : [];

    if (category) {
      setPage(1);
    }
    if (event.target.checked === true) {
      uniqueSubcategory = [...uniqueSubcategory, event.target.value];
      setSubCategory(uniqueSubcategory ?? []);
    } else if (event.target.checked === false) {
      uniqueSubcategory = uniqueSubcategory.filter((item) => item !== event.target.value);
      setSubCategory(uniqueSubcategory ?? [])
    }
    else {
      uniqueSubcategory.fill(subCategory.indexOf(event.target.value), 1);
      setSubCategory(uniqueSubcategory ?? []);
    }
    checkedMainCategory(uniqueSubcategory);
  }

  const isAllSubCategorySelected = (subArray, mainArray) => {
    return subArray?.length > 0 && subArray?.every(i => mainArray?.includes(i));
  }

  const checkedMainCategory = (subCat) => {
    let catList = [];
    CategoriesData?.forEach(item => {
      let selectedSubCategorySlugs = item?.sub_categories?.map(subCat => subCat?.slug);
      let isAdd = isAllSubCategorySelected(selectedSubCategorySlugs, subCat);
      if (isAdd) {
        catList.push(item?.slug);

      } else if (!item.sub_categories.length && category.includes(item.slug)) {
        catList.push(item?.slug);
      }
    });
    setCategory(catList);
  }

  const handleSizeCheck = (event) => {
    var sizeList = [...size];
    if (event.target.checked) {
      sizeList = [...size, event.target.value];
    }
    else {
      sizeList.splice(size.indexOf(event.target.value), 1);
    }
    setSize(sizeList);
  }

  const resetHandler = () => {
    setPage(1)
    setOrder_by("featured")
    setCategory([])
    setSize([]);
    setPrice([parseInt(FilterPricesData.min), parseInt(FilterPricesData.max)])
    setValue([parseInt(FilterPricesData.min), parseInt(FilterPricesData.max)])
    navigate('/category')
  }

  const clearPrice = () => {
    setPrice([0, 0])
    setValue([0, 0])
  }

  const AddToCart = async (product) => {
    setProd_id(product?.id)

    const cart_key = localStorage.getItem('cart_key');
    if (!cart_key) {
      await dispatch(createCart());
    }
    await dispatch(addRemoveCartItem({ product_id: product?.id, quantity: 1 }))

    await dispatch(getCartItems({ params: { currency: currency_id } }))

  }

  const [dataArray, setDataArray] = React.useState([])

  const CompareProduct = async (productId) => {
    const newData = [...dataArray, productId];
    let uniqueArray = [...new Set(newData)];
    await setDataArray(uniqueArray);
    navigate('/compare-product')
  };

  React.useEffect(() => {
    setAllData(ProductsData)
    seProducts(ProductsData?.products)
  }, [ProductsData])

  const addFav = (product) => {
    setProduct_id(product.id)
    seProducts(products?.map(item => (item.id === product.id ? { ...item, favorite: !item.favorite } : item)))

    if (product?.favorite) {
      dispatch(addRemovefavorite({
        product_id: product.id,
        remove: true
      }));
      toast.info("Product removed from wishlist");
    } else {
      dispatch(addRemovefavorite({
        product_id: product.id,
        add: true
      }));
      toast.success("Product Added to wishlist")
    }
  }

  React.useEffect(() => {
    // if (ProductsDataLoading) {
    dispatch(getProducts({ params: { category: category, sub_category: subCategory, page: page, order_by: order_by, min_price: value[0], max_price: value[1], size: size, currency: currency_id, length: productsPerPage } }))
    // }
  }, [category, subCategory, order_by, page, value, size, currency_id, productsPerPage])
  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCategories());
    if (path.categoryname) {
      // dispatch(getProducts({ params: { category: category, currency: currency_id, length: 6, min_price: price[0], max_price: price[1], size: size } }))
    }
    if (path.subcategoryname) {
      setCategory([]);
      setSubCategory([path.subcategoryname])
      // dispatch(getProducts({ params: { category: category, sub_category: subCategory, currency: currency_id, length: 6, min_price: price[0], max_price: price[1], size: size } }))
    }
    dispatch(recentlyViewedProducts())
  }, [])

  React.useEffect(() => {
    if (path.subcategoryname) {
      setCategory([]);
      setSubCategory([path.subcategoryname])
    } else {
      var selectedCat = (CategoriesData?.find((item) => item.slug === path.categoryname));
      var selectedSubCat = selectedCat?.sub_categories?.map(subCat => {
        return subCat.slug;
      });
      setSubCategory(selectedSubCat ?? []);
      setCategory([path?.categoryname])
    }
  }, [path, CategoriesData])

  React.useEffect(() => {
    const storedData = localStorage.getItem('compare_id');
    if (storedData) {
      setDataArray(JSON.parse(storedData));
    }
  }, []);

  React.useEffect(() => {
    if (dataArray) {
      localStorage.setItem('compare_id', JSON.stringify(dataArray))
    }
  }, [dataArray]);

  function capitalizeFirstLetter(str) {
    if (str) {
      return str?.charAt(0).toUpperCase() + str?.slice(1);
    }
  }

  return (
    <>
      <Helmet>
        <title >{category?.length && (category?.[0] || category?.[1]) ? capitalizeFirstLetter(category?.[0] || category?.[1]) + ' Products' : 'Products'}</title>
        {/* <meta name="description" content={getaboutusData?.data.meta_descr} /> */}
      </Helmet>
      {ProductsDataLoading || CategoriesLoading ? <Loader /> : false}
      <Box className='filter-products-details'>
        <Container className='container'>
          <Grid container spacing={2} className='filter-products'>
            <Grid item xs={12} md={4} className='filter-left-content'>
              <Grid item xs={12} md={12} className='filter-products_left'>
                <Box className='filter-details'>
                  <div className='category-breadcrumb breadcrumb-show' style={{ display: 'none' }}>
                    <ul>
                      <li>
                        <Link to='/' className='category-list'>
                          Home
                        </Link>
                      </li>
                      <span>/</span>
                      <li>
                        <Link to='/category' className='category-list active'>
                          Categories
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h1 className='filter-heading'>Filters</h1>
                  <aside className='sidebar'>
                    <ul className='filter ul-reset'>
                      <li className='filter-item'>
                        <section className='filter-item-inner'>
                          <Accordion className='filter-accordion' expanded={expanded === 'panel1'} onChange={handleChange1('panel1')}>
                            <AccordionSummary aria-controls='panel1d-content' id='panel1d-header' className='filter-accordiansummary'>
                              <h1 className='filter-item-inner-heading minus'>
                                <Typography>Categories</Typography>
                              </h1>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className='filter-attribute-list ul-reset'>
                                <div className='filter-attribute-list-inner'>
                                  {CategoriesData?.map((item, index) => (
                                    <li className='filter-attribute-item' key={index} style={{ cursor: 'pointer' }}>
                                      <Typography component='div' variant='div' style={{ display: 'flex', flexDirection: 'column' }} className='filter-accordion-inner-data'>
                                        {/*  */}
                                        {item.sub_categories.length === 0 ?
                                          <>
                                            <FormControlLabel className='filter-attribute-item' control={<Checkbox
                                              onChange={(e) => handleCheck(e, item)}
                                              checked={(category?.includes(item.slug)) ? true : false}
                                            />} label={
                                              <Typography sx={{ fontSize: '18px', fontWeight: 600, maxWidth: '86%' }} className='filter-accordion-inner-txt filter-attribute-label ib-m'> {item.name} </Typography>
                                            } value={item.slug} />

                                            {/* sub Categories */}
                                            {item.sub_categories?.map((subcat, i) => (
                                              <FormControlLabel key={i} className='filter-attribute-item' style={{ paddingLeft: '13%' }}
                                                control={
                                                  <Checkbox
                                                    checked={subCategory?.includes(subcat.slug) ? true : false}
                                                    onChange={(e) => handleSubCategory(e, item)}
                                                  />
                                                } label={
                                                  <Typography component='div' variant='div' sx={{ fontSize: '14px', fontWeight: 600, maxWidth: '86%' }} className='filter-accordion-inner-txt filter-attribute-label ib-m'>
                                                    <MenuItem key={index}>
                                                      <ListItemText className='sub-category-list' style={{ fontSize: '14px' }}>{subcat.name}</ListItemText>
                                                    </MenuItem>
                                                  </Typography>
                                                } value={subcat.slug} />
                                            ))}
                                          </>

                                          :
                                          <Accordion className="ctm-filter-accordion">
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <FormControlLabel className='filter-attribute-item' control={<Checkbox
                                                onChange={(e) => handleCheck(e, item)}
                                                checked={(category?.includes(item.slug)) ? true : false}
                                              />} label={
                                                <Typography sx={{ fontSize: '18px', fontWeight: 600, maxWidth: '86%' }} className='filter-accordion-inner-txt filter-attribute-label ib-m'> {item.name} </Typography>
                                              } value={item.slug} />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              {item.sub_categories?.map((subcat, i) => (
                                                <FormControlLabel key={i} className='filter-attribute-item' style={{ paddingLeft: '13%' }}
                                                  control={
                                                    <Checkbox
                                                      checked={subCategory?.includes(subcat.slug) ? true : false}
                                                      onChange={(e) => handleSubCategory(e, item)}
                                                    />
                                                  } label={
                                                    <Typography component='div' variant='div' sx={{ fontSize: '14px', fontWeight: 600, maxWidth: '86%' }} className='filter-accordion-inner-txt filter-attribute-label ib-m'>
                                                      <MenuItem key={index}>
                                                        <ListItemText className='sub-category-list' style={{ fontSize: '14px' }}>{subcat.name}</ListItemText>
                                                      </MenuItem>
                                                    </Typography>
                                                  } value={subcat.slug} />
                                              ))}
                                            </AccordionDetails>
                                          </Accordion>
                                        }

                                        {/*  */}

                                      </Typography>
                                    </li>
                                  ))}
                                </div>
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        </section>
                      </li>

                      <li className='filter-item'>
                        <section className='filter-item-inner'>
                          <Accordion className='filter-accordion' expanded={expanded === 'panel2'} onChange={handleChange1('panel2')}>
                            <AccordionSummary aria-controls='panel2d-content' id='panel2d-header' className='filter-accordiansummary'>
                              <h1 className='filter-item-inner-heading minus'>
                                <Typography>Size</Typography>
                              </h1>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className='filter-attribute-list ul-reset'>
                                <div className='filter-attribute-list-inner'>
                                  {FilterSizeData && FilterSizeData?.map((item, index) => {
                                    return (
                                      <li className='filter-attribute-item' key={index}>
                                        <Typography className='filter-accordion-inner-data'>
                                          <FormControlLabel className='filter-attribute-item'
                                            control={<Checkbox onChange={handleSizeCheck} checked={size?.includes(item?.value) ? true : false} />}
                                            label={<Typography sx={{ fontSize: '18px', fontWeight: 600, maxWidth: '86%' }}
                                              className='filter-accordion-inner-txt filter-attribute-label ib-m'> {item.value} </Typography>}
                                            value={item.value}
                                          />
                                        </Typography>
                                      </li>
                                    )
                                  })
                                  }
                                </div>
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        </section>
                      </li>

                      <li className='filter-item'>
                        <section className='filter-item-inner'>
                          <Accordion className='filter-accordion' expanded={expanded === 'panel3'} onChange={handleChange1('panel3')}>
                            <AccordionSummary aria-controls='panel3d-content' id='panel3d-header' className='filter-accordiansummary'>
                              <h1 className='filter-item-inner-heading minus'>
                                <Typography> Price</Typography>
                              </h1>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className='filter-attribute-list ul-reset'>
                                <div className='filter-attribute-list-inner'>
                                  <Box sx={{ width: '95%', height: 100, display: 'flex', alignItems: 'center', margin: '5px' }}>
                                    <Slider
                                      getAriaLabel={() => 'Price range'}
                                      value={value}
                                      onChange={handleChangePrice}
                                      valueLabelDisplay="auto"
                                      min={parseInt(FilterPricesData?.min)}
                                      max={parseInt(FilterPricesData?.max)}
                                      onMouseUp={handleSliderMouseUp}
                                    />
                                  </Box>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}> {parseInt(value[0])}  to {parseInt(value[1])} </Typography>
                                    <Button onClick={clearPrice}><Typography sx={{ color: '#000', fontWeight: '600', textTransform: 'capitalize' }}>Clear</Typography></Button>
                                  </div>
                                </div>
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        </section>
                      </li>
                    </ul>
                  </aside>
                  <button className='reset-btn' onClick={resetHandler}>
                    <span>
                      <i className='fa-solid fa-rotate-right'></i>
                    </span>
                    Reset filters
                  </button>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} className='filter-products-right'>
              <Grid item xs={12} md={12} className='filter-products_content'>
                <Box className='Category-top'>
                  <Box className='category-breadcrumb breadcrumb-hide'>
                    <ul>
                      <li>
                        <Link to='/' className='category-list'>
                          Home
                        </Link>
                      </li>
                      <span>/</span>
                      <li>
                        <Link to='/category' className='category-list active'>
                          Categories
                        </Link>
                      </li>
                    </ul>
                    <h3 className='main-title' style={{ textTransform: 'capitalize' }}>{category.length === 1 ? category + ' Products' : 'Products'}</h3>
                    {/* {allData?.total_rows > 0 && <span>{allData?.total_rows} Products</span>} */}

                  </Box>
                  <Box sx={{ width: '96.5%' }}>
                    <div className='product-number-filter'>
                      <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
                        {allData?.total_rows > 0 && <span>{allData?.total_rows} Products</span>}
                      </div>
                      <div className='top-filter'>
                        <Box className='featured-dropdown'>
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <Select value={order_by} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem value='featured'>
                                Featured
                              </MenuItem>
                              <MenuItem value={'best_seller'}>Best Selling</MenuItem>
                              <MenuItem value={'atoz'}>Alphabetically: A-Z</MenuItem>
                              <MenuItem value={'ztoa'}>Alphabetically: Z-A</MenuItem>
                              <MenuItem value={'price_low_to_high'}>Price Low to High</MenuItem>
                              <MenuItem value={'price_high_to_low'}>Price High to Low</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <div>
                          <FormControl className='product-per-page-filter'>
                            {/* <b>Per page</b> */}
                            <Select
                              labelId="products-per-page-label"
                              id="products-per-page"
                              value={productsPerPage}
                              onChange={handleProductLength}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                position: 'fixed'
                              }}
                              sx={{
                                boxShadow: "none",
                                //  ".MuiOutlinedInput-notchedOutline": { border: 'none !important', borderStyle: 'none' },
                                // "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                // {
                                //   border: 0,
                                // },
                                // "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                // {
                                //   border: 0,
                                // },
                              }}
                            >
                              <MenuItem value={5}>5 Per page</MenuItem>
                              <MenuItem value={10}>10 Per page</MenuItem>
                              <MenuItem value={15}>15 Per page</MenuItem>
                              <MenuItem value={20}>20 Per page</MenuItem>
                              <MenuItem value={25}>25 Per page</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
              <>
                {/* <div className="pos-relative">
                  {ProductsDataLoading || CategoriesLoading ? <Loader /> : false}
                </div> */}
                {products?.length === 0 ?
                  <Box className='no-Products-row no-product'>
                    <Typography component={'h3'}> No Products Available</Typography>
                  </Box>
                  :
                  <>
                    <Box className='filter-Products-row' sx={{ minHeight: '100vh' }}>
                      {products?.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index} className='filter-products-grid'>
                          <Box className='productImage'>
                            <button style={{ border: 'none', position: 'relative', background: 'transparent', cursor: 'pointer' }}>
                              <Typography variant='span' component='span' sx={{ position: 'absolute', right: '20px', top: '20px', zIndex: '9' }}>
                                <CompareSharp sx={{ color: '#0f51a1', cursor: 'pointer' }} onClick={() => CompareProduct(item.id)} />
                                <button className='fav-btn' onClick={() => addFav(item)} >
                                  {item?.favorite ?
                                    <svg width='28' height='24' viewBox='0 0 28 24' fill='red' xmlns='http://www.w3.org/2000/svg'>
                                      <path
                                        d='M7.81518 0.498687L7.81739 0.498835C9.89738 0.637366 11.8521 1.51052 13.3166 2.95168L13.6401 3.28707L14.0398 3.70139L14.394 3.24767L14.7076 2.84615C16.0799 1.39703 17.9846 0.530833 20.0108 0.439537C22.0368 0.348244 24.0159 1.03943 25.5199 2.35952C26.2003 3.08639 26.7269 3.93614 27.0702 4.86012C27.4162 5.7912 27.5694 6.7796 27.5212 7.76828C27.4731 8.75696 27.2245 9.72691 26.7894 10.6221C26.3543 11.5173 25.7412 12.3203 24.9849 12.9842L24.9768 12.9913L24.9691 12.9987L23.9032 14.0187L23.9031 14.0187L23.8985 14.0232L14.3903 23.3605C14.2932 23.4524 14.1607 23.5058 14.0205 23.5058C13.88 23.5058 13.7475 23.4523 13.6503 23.3602L4.10373 14.0255L3.02042 12.9286L3.00942 12.9175L2.99774 12.907C1.49633 11.566 0.603286 9.70183 0.509828 7.72375C0.416655 5.75168 1.12551 3.82162 2.48535 2.35392C3.18924 1.70324 4.02483 1.20169 4.93947 0.881822C5.85987 0.559939 6.83947 0.429349 7.81518 0.498687Z'
                                      />
                                    </svg>
                                    :
                                    <svg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                      <path
                                        d='M7.81518 0.498687L7.81739 0.498835C9.89738 0.637366 11.8521 1.51052 13.3166 2.95168L13.6401 3.28707L14.0398 3.70139L14.394 3.24767L14.7076 2.84615C16.0799 1.39703 17.9846 0.530833 20.0108 0.439537C22.0368 0.348244 24.0159 1.03943 25.5199 2.35952C26.2003 3.08639 26.7269 3.93614 27.0702 4.86012C27.4162 5.7912 27.5694 6.7796 27.5212 7.76828C27.4731 8.75696 27.2245 9.72691 26.7894 10.6221C26.3543 11.5173 25.7412 12.3203 24.9849 12.9842L24.9768 12.9913L24.9691 12.9987L23.9032 14.0187L23.9031 14.0187L23.8985 14.0232L14.3903 23.3605C14.2932 23.4524 14.1607 23.5058 14.0205 23.5058C13.88 23.5058 13.7475 23.4523 13.6503 23.3602L4.10373 14.0255L3.02042 12.9286L3.00942 12.9175L2.99774 12.907C1.49633 11.566 0.603286 9.70183 0.509828 7.72375C0.416655 5.75168 1.12551 3.82162 2.48535 2.35392C3.18924 1.70324 4.02483 1.20169 4.93947 0.881822C5.85987 0.559939 6.83947 0.429349 7.81518 0.498687Z'
                                        stroke='#0F51A1'
                                      />
                                    </svg>
                                  }
                                </button>
                              </Typography>
                              <Link to={`/product/${item?.slug}`}>
                                <img className='grid-image' alt='thumbnail' src={item?.image_thumbnail} />
                              </Link>
                            </button>
                            <div className='filter-quick-btn'>
                              {item.has_sizes === true ?
                                <Link to={`/product/${item?.slug}`}>
                                  <button>
                                    <Typography> Select Size </Typography>
                                    <span className='quick-btn-span'>
                                      <i className='fa-solid fa-plus'></i>
                                    </span>
                                  </button>
                                </Link>
                                :
                                isaddRemoveCartItemLoading || CreateCartLoading ?
                                  <>
                                    {prod_id === item?.id ?
                                      <button className='quick-cart-loader'>
                                        <CircularProgress color="inherit" />
                                      </button>
                                      :
                                      <button onClick={() => AddToCart(item)}>
                                        <Typography> Quick Add </Typography>
                                        <span className='quick-btn-span'>
                                          <i className='fa-solid fa-plus'></i>
                                        </span>
                                      </button>}
                                  </>
                                  :
                                  <button onClick={() => AddToCart(item)}>
                                    <Typography> Quick Add </Typography>
                                    <span className='quick-btn-span'>
                                      <i className='fa-solid fa-plus'></i>
                                    </span>
                                  </button>
                              }
                            </div>
                          </Box>
                          <Box className='product-details' sx={{ px: '20px' }}>
                            <Link to={`/product/${item?.slug}`}>
                              <h3>{item?.name}</h3>
                            </Link>
                            {item.has_sizes === true ?
                              <p style={{ fontSize: '16px' }}>{item?.currency}{item?.size_price?.min} - {item?.currency}{item?.size_price?.max}</p>
                              :
                              <p style={{ fontSize: '16px' }}>{item?.currency}{item?.price}</p>
                            }
                          </Box>
                          <Box className='product-text' sx={{ px: '20px' }}>
                            <span>{item?.category_name}</span>
                          </Box>
                        </Grid>
                      ))}
                    </Box>

                    {allData?.total_pages > 1 ?
                      <Box className='filter-Products-pagination'>
                        <Pagination className='product_pagination ctm-pagination' page={page} size='large' count={allData?.total_pages} variant="outlined" shape="rounded"
                          onChange={handlePageChange} />
                      </Box> : ""}
                  </>
                }
              </>
            </Grid>
          </Grid>
        </Container>
        {isrecentlyViewedProductsData?.products?.length !== 0 &&
          <Box className='recent-view-products'>
            <Container className='container'>
              <h1 className='recent-heading'>Recently Viewed</h1>
              <Grid container spacing={2} className='filter-products'>
                <>
                  {isrecentlyViewedProductsData?.products.map((prod, indx) => (
                    <Grid item xs={12} sm={6} md={3} className='filter-products-grid' key={indx}>
                      <Box className='productImage'>
                        <Link to={`/product/${prod?.slug}`} className='recent-product'>
                          <img className='grid-image' alt='product-thumb' src={prod?.image_thumbnail} />
                        </Link>
                        <div className='filter-quick-btn'>
                          {prod.has_sizes === true ?
                            <Link to={`/product/${prod?.slug}`}>
                              <button>
                                <Typography> Select Size </Typography>
                                <span className='quick-btn-span'>
                                  <i className='fa-solid fa-plus'></i>
                                </span>
                              </button>
                            </Link>
                            :
                            isaddRemoveCartItemLoading || CreateCartLoading ?
                              <button className='quick-cart-loader'>
                                <CircularProgress color="inherit" />
                              </button>
                              :
                              <button onClick={() => AddToCart(prod)}>
                                <Typography> Quick Add </Typography>
                                <span className='quick-btn-span'>
                                  <i className='fa-solid fa-plus'></i>
                                </span>
                              </button>
                          }
                        </div>
                      </Box>
                      <Box className='product-details'>
                        <h3>{prod?.name}</h3>
                        {prod.has_sizes === true ?
                          <p style={{ fontSize: '16px' }}>{prod?.currency}{prod?.size_price?.min} - {prod?.currency}{prod?.size_price?.max}</p>
                          :
                          <p style={{ fontSize: '16px' }}>{prod?.currency}{prod?.price}</p>
                        }
                      </Box>
                      <Box className='product-text'>
                        <span>{prod?.category_name}</span>
                      </Box>
                    </Grid>
                  ))}
                </>
              </Grid>
            </Container>
          </Box>
        }
      </Box>
    </>
  )
}
