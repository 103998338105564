import React, { useEffect } from 'react'
import { getcookiesPolicy } from "../../../api";
import { useDispatch, useSelector } from 'react-redux';
import { cookiesPolicyData, iscookiesPolicyLoading } from '../../../selector';
import { Container, Typography } from '@mui/material';
import { Helmet } from "react-helmet";
import Loader from '../../Loader/Loader';

export default function CookiesPolicy() {

    const dispatch = useDispatch();
    const getcookiesPolicyData = useSelector(cookiesPolicyData)
    const cookiesPolicyLoading = useSelector(iscookiesPolicyLoading)

    useEffect(() => {
        dispatch(getcookiesPolicy());
    }, [])

    return (
        <>
            {cookiesPolicyLoading && <Loader />}

            <Container maxWidth="lg" sx={{pt: "60px"}}>
                <Helmet>
                    <title>{getcookiesPolicyData?.data.browser_title}</title>
                    <meta name="description" content={getcookiesPolicyData?.data.meta_descr} />
                </Helmet>
                <Typography variant='h4' component='h1'>{getcookiesPolicyData?.data.page}</Typography>
                <div dangerouslySetInnerHTML={{ __html: getcookiesPolicyData?.data.content }} />
            </Container>
        </>
    )
}
