import React, { useEffect } from 'react'
import { getaboutUs } from "../../../api";
import { useDispatch, useSelector } from 'react-redux';
import { aboutusData, isaboutusDataLoading } from '../../../selector';
import { Container, Typography } from '@mui/material';
import { Helmet } from "react-helmet";
import Loader from '../../Loader/Loader';

export default function AboutUs() {

    const dispatch = useDispatch();
    const getaboutusData = useSelector(aboutusData)
    const aboutusDataLoading = useSelector(isaboutusDataLoading)

    useEffect(() => {
        dispatch(getaboutUs());
    }, [])

    return (
        <>
            {aboutusDataLoading && <Loader />}
            <Container maxWidth="lg" sx={{pt: '60px'}}>
                <Helmet>
                    <title>{getaboutusData?.data.browser_title}</title>
                    <meta name="description" content={getaboutusData?.data.meta_descr} />
                </Helmet>
                <Typography variant='h4' component='h1'>{getaboutusData?.data.page}</Typography>
                <div dangerouslySetInnerHTML={{ __html: getaboutusData?.data.content }} />
            </Container>
        </>
    )
}
