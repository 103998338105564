import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Container, Paper, Table, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { addRemoveCartItemLoading, compareProductData, compareProductLoading, getCreateCartLoading } from '../../selector'
import { addRemoveCartItem, compareProduct, createCart } from '../../api'
import { Link, useNavigate } from 'react-router-dom'
import AlertDialogBox from '../AlertDialogBox/AlertDialogBox'
import Loader from '../Loader/Loader'
import { Helmet } from 'react-helmet'

const CompareProducts = () => {
    let compare_Id = JSON.parse(localStorage.getItem('compare_id'));
    const [compareData, setCompareData] = useState()
    const [prod_id, setProd_id] = React.useState()
    const [open, setOpen] = React.useState(false);
    const [remove, setRemove] = React.useState(false);
    const [product_id, setProduct_id] = React.useState()
    const [inx, setInx] = React.useState()

    let getCompareData = useSelector(compareProductData)
    const isaddRemoveCartItemLoading = useSelector(addRemoveCartItemLoading)
    const CreateCartLoading = useSelector(getCreateCartLoading)
    const compareCartLoading = useSelector(compareProductLoading)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const AddToCart = async (product) => {
        setProd_id(product?.id)
        const cart_key = localStorage.getItem('cart_key');
        if (!cart_key) {
            await dispatch(createCart());
        }
        await dispatch(addRemoveCartItem({ product_id: product?.id, quantity: 1 }))
        navigate('/shopping-cart')
    }

    const removeHandler = (product, index) => {
        setOpen(true);
        setProduct_id(product.id)
        setInx(index)
        setRemove(true)
    }

    const removeCompareProduct = () => {
        if (remove) {
            const productRemove = compareData?.filter((item) => item?.id !== product_id);
            setCompareData(productRemove);

            if (compareData === compare_Id) {
                setCompareData(compareData[inx]?.splice)
            }
            const index = compare_Id.indexOf(product_id);
            if (index > -1) {
                compare_Id.splice(index, 1);
            }
            localStorage.setItem('compare_id', JSON.stringify(compare_Id));
            setOpen(false);
        }
    }

    React.useEffect(() => {
        dispatch(compareProduct({ products: compare_Id }))
    }, [])

    React.useEffect(() => {
        setCompareData(getCompareData)
    }, [getCompareData])

    const goBack = () => {
        navigate('/category')
    }

    return (
        <>
        <Helmet>
                <title>Compare Products</title>
        </Helmet>
            {compareCartLoading && <Loader />}
            <div className='compare-products'>
                <Container className='container'>
                    <Box className='order-nav'>
                        <ul>
                            <li>
                                <Link to='/' className='category-list'>
                                    Home
                                </Link>
                            </li>
                            <span> / </span>
                            <li>
                                <Link to='/compare-product' className='category-list active'>
                                    Compare Products
                                </Link>
                            </li>
                        </ul>
                    </Box>
                    <h1 className="category-list-heading" style={{ marginBottom: '40px' }}>Compare Products</h1>
                    {(compareData === undefined || compareData === null || compareData?.length === 0) ?
                        <Box height='40vw' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography component='h5' variant='h5'> No products available </Typography>
                        </Box>
                        :
                        <TableContainer component={Paper} sx={{ boxShadow: 'rgb(15 81 161) 0px 5px 25px -13px' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableRow className='images'>
                                    <TableCell align="left" sx={{ background: '#0f51a1' }}></TableCell>
                                    {compareData && compareData?.map((item, i) =>
                                        <TableCell sx={{ minWidth: '300px' }} key={i} align="left">
                                            <Link to={`/product/${item?.slug}`}>
                                                <img src={item.image_path} alt="comapre icon" className='compare-image' />
                                            </Link>
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow className='images'>
                                    <TableCell align="left" className='compare-label'>Product Name</TableCell>
                                    {compareData && compareData?.map((item, i) =>
                                        <TableCell sx={{ minWidth: '300px' }} key={i} align="left">{item.name}</TableCell>
                                    )}
                                </TableRow>
                                <TableRow className='images'>
                                    <TableCell align="left" className='compare-label'>Category Name</TableCell>
                                    {compareData && compareData?.map((item, i) =>
                                        <TableCell sx={{ minWidth: '300px' }} key={i} align="left">{item.category_name}</TableCell>
                                    )}
                                </TableRow>
                                <TableRow className='product-weight'>
                                    <TableCell align='left' className='compare-label'>Product Weight</TableCell>
                                    {compareData && compareData.map((item, i) =>
                                        <TableCell sx={{ minWidth: '300px' }} key={i} align="left"> {item.pro_weight}</TableCell>
                                    )}
                                </TableRow>
                                <TableRow className='description'>
                                    <TableCell align='left' className='compare-label'>Description</TableCell>
                                    {compareData && compareData.map((item, i) =>
                                        <TableCell sx={{ minWidth: '300px', verticalAlign: 'top' }} key={i} align="left">
                                            <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow className='price'>
                                    <TableCell align='left' className='compare-label'>Price</TableCell>
                                    {compareData && compareData.map((item, i) =>
                                        <TableCell sx={{ minWidth: '300px', verticalAlign: 'top' }} key={i} align="left">
                                            {item.currency}{item.price}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow className='add-cart'>
                                    <TableCell align='left' sx={{ background: '#0f51a1', fontWeight: '600' }}></TableCell>
                                    {compareData && compareData.map((item, i) =>
                                        <TableCell sx={{ minWidth: '300px', verticalAlign: 'top' }} key={i} align="left">
                                            <Box className='bestSeller-button' sx={{ gap: '20px' }}>
                                                {isaddRemoveCartItemLoading || CreateCartLoading ?
                                                    <>
                                                        {prod_id === item?.id ?
                                                            <button className='add-cart-loader' style={{ height: '40px', borderRadius: '10px', width: '100%' }}>
                                                                <CircularProgress color="inherit" sx={{ width: '18px !important', height: '18px !important', color: 'white' }} />
                                                            </button>

                                                            :
                                                            <button className='cart-btn' style={{ height: '40px', width: '100%', fontSize: '14px', fontWeight: '500', minWidth: '40%' }} onClick={() => AddToCart(item)}>ADD To Cart</button>
                                                        }
                                                    </>
                                                    :
                                                    <button className='cart-btn' style={{ height: '40px', width: '100%', fontSize: '14px', fontWeight: '500', minWidth: '40%' }} onClick={() => AddToCart(item)}>ADD To Cart</button>
                                                }
                                                <Button component='div' variant="outlined" color="error" className='product-order-btn'
                                                    onClick={() => removeHandler(item)}
                                                    sx={{ borderRadius: 'unset', p: 'unset', height: '40px', flexDirection: 'unset' }} fullWidth>
                                                    <div>Remove</div>
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </Table>
                        </TableContainer>
                    }
                    <button className='return-cart-btn' onClick={goBack} style={{ padding: '40px 0', color: '#0f51a1', fontWeight: '600' }}>
                        <i className='fa-solid fa-chevron-left'></i> Return To Shopping
                    </button>

                </Container>

                {/* Remove compare products  */}
                <AlertDialogBox
                    open={open}
                    setOpen={setOpen}
                    title='Remove Compare Item'
                    contenttext='Are you sure you want to remove this item?'
                    // isLoader={isaddRemovefavoriteLoading}
                    actionButton='Remove'
                    onActionClick={removeCompareProduct}
                />
            </div>
        </>

    )
}

export default CompareProducts