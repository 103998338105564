import { createSlice } from "@reduxjs/toolkit";
import {
    getGeneralSetting,
    logOut,
    getWhyCustomerLoveUs,
    getBestSeller,
    getCurrency,
    getPopularProduct,
    addRemovefavorite,
    getTestimonial,
    searchProduct
} from "../../api";

const initialState = {
    isGeneralSettingLoading: false,
    getGeneralSettingData: null,
    islogoutLoading: false,
    logoutData: null,
    getBestSellerData: null,
    isBestSellerLoading: false,
    isCurrencyLoading: false,
    getAllCurrency: null,
    isPopularProductLoading: false,
    getAllPopularProduct: null,
    isaddRemovefavoriteData: null,
    isaddRemovefavoriteLoading: false,
    isTestimonialData: null,
    isTestimonialLoading: false,
    issearchProductData: null,
    issearchProductLoading: false,
};

export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {},
    extraReducers: {

        [getGeneralSetting.pending]: (state) => {
            state.isGeneralSettingLoading = true;
        },
        [getGeneralSetting.fulfilled]: (state, { payload }) => {
            state.isGeneralSettingLoading = false;
            state.getGeneralSettingData = payload.settings;
        },
        [getGeneralSetting.rejected]: (state) => {
            state.isGeneralSettingLoading = false;
        },

        [getWhyCustomerLoveUs.pending]: (state) => {
            state.isWhyCustomerLoveUsLoading = true;
        },
        [getWhyCustomerLoveUs.fulfilled]: (state, { payload }) => {
            state.isWhyCustomerLoveUsLoading = false;
            state.getWhyCustomerLoveUsData = payload.images;
        },
        [getWhyCustomerLoveUs.rejected]: (state) => {
            state.isWhyCustomerLoveUsLoading = false;
        },

        [logOut.pending]: (state) => {
            state.islogoutLoading = true;
        },
        [logOut.fulfilled]: (state, { payload }) => {
            state.islogoutLoading = false;
            state.logOut = payload;
        },
        [logOut.rejected]: (state) => {
            state.islogoutLoading = false;
        },

        [getBestSeller.pending]: (state) => {
            state.isBestSellerLoading = true;
        },
        [getBestSeller.fulfilled]: (state, { payload }) => {
            state.isBestSellerLoading = false;
            state.getBestSellerData = payload.products;
        },
        [getBestSeller.rejected]: (state) => {
            state.isBestSellerLoading = false;
        },

        [getCurrency.pending]: (state) => {
            state.isCurrencyLoading = true;
        },
        [getCurrency.fulfilled]: (state, { payload }) => {
            state.isCurrencyLoading = false;
            state.getAllCurrency = payload.data?.settings;
        },
        [getCurrency.rejected]: (state) => {
            state.isCurrencyLoading = false;
        },

        [getPopularProduct.pending]: (state) => {
            state.isPopularProductLoading = true;
        },
        [getPopularProduct.fulfilled]: (state, { payload }) => {
            state.isPopularProductLoading = false;
            state.getAllPopularProduct = payload?.data;
        },
        [getPopularProduct.rejected]: (state) => {
            state.isPopularProductLoading = false;
        },

        [addRemovefavorite.pending]: (state) => {
            state.isaddRemovefavoriteLoading = true;
        },
        [addRemovefavorite.fulfilled]: (state, { payload }) => {
            state.isaddRemovefavoriteLoading = false;
            state.isaddRemovefavoriteData = payload?.data;
        },
        [addRemovefavorite.rejected]: (state) => {
            state.isaddRemovefavoriteLoading = false;
        },

        [getTestimonial.pending]: (state) => {
            state.isTestimonialLoading = true;
        },
        [getTestimonial.fulfilled]: (state, { payload }) => {
            state.isTestimonialLoading = false;
            state.isTestimonialData = payload?.data?.testimonials;
        },
        [getTestimonial.rejected]: (state) => {
            state.isTestimonialLoading = false;
        },

        [searchProduct.pending]: (state) => {
            state.issearchProductLoading = true;
        },
        [searchProduct.fulfilled]: (state, { payload }) => {
            state.issearchProductLoading = false;
            state.issearchProductData = payload.data;
        },
        [searchProduct.rejected]: (state) => {
            state.issearchProductLoading = false;
        }
    },
});

// Action creators are generated for each case reducer function
export const { } = homeSlice.actions;

export default homeSlice.reducer;