import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import './ProductInformation.css'
import ProductInfoimg1 from './img/ProductInfo1.png'
import ProductInfoimg2 from './img/ProductInfo2.png'
import ProductInfoimg3 from './img/ProductInfo3.png'
import NoImgFound from './img/No-image-found.jpg'
import { getProductdetailsData } from '../../selector'
import { useSelector } from 'react-redux'

export default function ProductInformation() {
  const ProductdetailsData = useSelector(getProductdetailsData)
  const product = ProductdetailsData?.product;
  const product_msds = ProductdetailsData?.product?.msds_link;
  const product_sds = ProductdetailsData?.product?.sds_link;

  const renderProductImage = (imgSrc, title, link) => (
    <Grid item xs={12} md={4} className='Product-information-image'>
      <Box className='product-info-content'>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src={imgSrc} alt={title} />
          <p>{title}</p>
        </a>
      </Box>
    </Grid>
  );

  if (!product_msds && !product_sds) {
    return null;
  }

  return (
    <>
      {/* {product?.length === 0 ?
        ""
        :
        <Box className='Product-information-details'>
          <Container className='container'>
            <h1 className='howUse-heading'>Product Information</h1>
            <Grid container spacing={2} className='Product-information-content'>
              {product && product?.map((item) =>
                <Grid item xs={12} md={4} className='Product-information-image'>
                  <Box className='product-info-content'>
                    <img src={item?.image ?? NoImgFound} />
                    <p>{item?.title}</p>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      } */}

      <Box className='Product-information-details'>
        <Container className='container'>
          <h1 className='howUse-heading'>Product Information</h1>
          <Grid container spacing={2} className='Product-information-content'>
            {/* Render product image */}
            {product?.image && renderProductImage(product.image, product.title ?? "No Title", "#")}

            {/* Render MSDS and SDS links with images */}
            {product_msds && renderProductImage(ProductInfoimg1, "MSDS", product_msds)}
            {product_sds && renderProductImage(ProductInfoimg2, "SDS", product_sds)}
          </Grid>
        </Container>
      </Box>
    </>
  )
}
