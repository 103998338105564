import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link } from 'react-router-dom'
import CorrectIcon from './img/correct.png'
import './ThankYouText.css'

export default function ThankyouText({ orderId, checkPath, ccOrderId }) {
  localStorage.removeItem("client_secret")
  localStorage.removeItem("cart_key")
  return (
    <Box className='Thankyou-details'>
      <Container className='container'>
        <Box className='Thank-you-heading'>
          <img src={CorrectIcon} />
          <h3>Thank You!</h3>
          <h3>Your order has been recieved.</h3>
          {checkPath === '?payment_intent' ? <h4>Order #{ccOrderId}</h4> : <h4>Order #{orderId}</h4>}
          <p>
            You will receive an invoice with tracking information shortly. Please contact us if you have any questions in relation to your
            order by visiting our contact page here or by emailing <Link target='_blank' to='mailto:cs@prohairlabs.com'>cs@prohairlabs</Link>. Please ensure to quote your order number when
            contacting us.
          </p>
        </Box>
        <Link className='order-detail-return' to='/'>
          <i className='fa-solid fa-chevron-left'></i> return to Home
        </Link>
      </Container>
    </Box>
  )
}
