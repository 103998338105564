import * as React from 'react'
import FilterProducts from '../filterProducts/filterProducts'

export default function Categories() {
  return (
    <div>
      <FilterProducts />
    </div>
  )
}
