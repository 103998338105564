import { Button, CircularProgress, Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import './bestSeller.css'
import Grid from '@mui/material/Grid' // Grid version 1
import { useDispatch, useSelector } from 'react-redux'
import { addRemoveCartItem, addRemovefavorite, createCart, getBestSeller } from '../../api'
import { bestSellerData, BestSellerLoading, addRemovefavoriteLoading, getCreateCartData, addRemoveCartItemLoading, getCreateCartLoading } from '../../selector'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { CompareSharp, DataArray } from '@mui/icons-material'
import { toast } from 'react-toastify'

export default function BestSeller(props) {
  const [prod_id, setProd_id] = React.useState()

  const [allData, setAllData] = React.useState()
  const dispatch = useDispatch()

  let getData = useSelector(bestSellerData)
  const isBestSellerLoading = useSelector(BestSellerLoading)
  const isaddRemovefavoriteLoading = useSelector(addRemovefavoriteLoading)
  const CreateCartData = useSelector(getCreateCartData)
  const navigate = useNavigate();
  const isaddRemoveCartItemLoading = useSelector(addRemoveCartItemLoading)
  const CreateCartLoading = useSelector(getCreateCartLoading)
  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  }


  const AddToCart = async (product) => {
    setProd_id(product?.id)
    const cart_key = localStorage.getItem('cart_key');
    if (!cart_key) {
      await dispatch(createCart());
    }
    await dispatch(addRemoveCartItem({ product_id: product?.id, quantity: 1 }))
    navigate('/shopping-cart')
  }

  const addFav = (product) => {
    setAllData(allData.map(item => (item.id === product.id ? { ...item, favorite: !item.favorite } : item)))

    if (product?.favorite) {
      dispatch(addRemovefavorite({
        product_id: product.id,
        remove: true
      }));
      toast.info("Product removed from wishlist");
    } else {
      dispatch(addRemovefavorite({
        product_id: product.id,
        add: true
      }));
      toast.success("Product Added to wishlist");
    }
  }

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    const currency_id = localStorage.getItem('currency_id');

    if (token) {
      dispatch(getBestSeller({ params: { currency: currency_id } }))
    }
  }, [])

  React.useEffect(() => {
    setAllData(getData)
  }, [getData])

  const handleCompareClick = (comproid) => {
    props.onChildData(comproid);
  };


  return (
    <Box className='bestSeller-details bestseller-custom'>
      {allData && allData.length > 0 &&
        <Container className='container'>
          <h1 className='heading'>Best Seller</h1>
          <div className="bestseller-grid">
            {allData && allData?.map((item, i) =>
              <div>
                <Box className='bestSeller-image' id='parent'>
                  <Link to={`/product/${item?.slug}`}>
                    <img src={item?.image_thumbnail} className='grid-image' alt='best-seller-image' />
                  </Link>
                  <span>
                    <CompareSharp sx={{ color: '#0f51a1', cursor: 'pointer' }} onClick={() => handleCompareClick(item.id)} />
                    <button className='fav-btn' onClick={() => addFav(item)}>
                      {item?.favorite ?
                        <svg width='28' height='24' viewBox='0 0 28 24' fill='red' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M7.81518 0.498687L7.81739 0.498835C9.89738 0.637366 11.8521 1.51052 13.3166 2.95168L13.6401 3.28707L14.0398 3.70139L14.394 3.24767L14.7076 2.84615C16.0799 1.39703 17.9846 0.530833 20.0108 0.439537C22.0368 0.348244 24.0159 1.03943 25.5199 2.35952C26.2003 3.08639 26.7269 3.93614 27.0702 4.86012C27.4162 5.7912 27.5694 6.7796 27.5212 7.76828C27.4731 8.75696 27.2245 9.72691 26.7894 10.6221C26.3543 11.5173 25.7412 12.3203 24.9849 12.9842L24.9768 12.9913L24.9691 12.9987L23.9032 14.0187L23.9031 14.0187L23.8985 14.0232L14.3903 23.3605C14.2932 23.4524 14.1607 23.5058 14.0205 23.5058C13.88 23.5058 13.7475 23.4523 13.6503 23.3602L4.10373 14.0255L3.02042 12.9286L3.00942 12.9175L2.99774 12.907C1.49633 11.566 0.603286 9.70183 0.509828 7.72375C0.416655 5.75168 1.12551 3.82162 2.48535 2.35392C3.18924 1.70324 4.02483 1.20169 4.93947 0.881822C5.85987 0.559939 6.83947 0.429349 7.81518 0.498687Z'
                          />
                        </svg>
                        :
                        <svg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M7.81518 0.498687L7.81739 0.498835C9.89738 0.637366 11.8521 1.51052 13.3166 2.95168L13.6401 3.28707L14.0398 3.70139L14.394 3.24767L14.7076 2.84615C16.0799 1.39703 17.9846 0.530833 20.0108 0.439537C22.0368 0.348244 24.0159 1.03943 25.5199 2.35952C26.2003 3.08639 26.7269 3.93614 27.0702 4.86012C27.4162 5.7912 27.5694 6.7796 27.5212 7.76828C27.4731 8.75696 27.2245 9.72691 26.7894 10.6221C26.3543 11.5173 25.7412 12.3203 24.9849 12.9842L24.9768 12.9913L24.9691 12.9987L23.9032 14.0187L23.9031 14.0187L23.8985 14.0232L14.3903 23.3605C14.2932 23.4524 14.1607 23.5058 14.0205 23.5058C13.88 23.5058 13.7475 23.4523 13.6503 23.3602L4.10373 14.0255L3.02042 12.9286L3.00942 12.9175L2.99774 12.907C1.49633 11.566 0.603286 9.70183 0.509828 7.72375C0.416655 5.75168 1.12551 3.82162 2.48535 2.35392C3.18924 1.70324 4.02483 1.20169 4.93947 0.881822C5.85987 0.559939 6.83947 0.429349 7.81518 0.498687Z'
                            stroke='#0F51A1'
                          />
                        </svg>
                      }
                    </button>
                  </span>
                </Box>
                <div className='grid-bottom-content'>
                  <Box className='bestSeller-text'>
                    <h3 style={{ maxWidth: '306px', cursor: 'pointer' }} title={item?.name}>{item?.name}</h3>
                  </Box>
                  <Box className='bestSeller-button'>
                    {item.has_sizes === true ?
                      <Link to={`/product/${item?.slug}`} className='cart-btn'>
                        Select Size
                      </Link>
                      :
                      isaddRemoveCartItemLoading || CreateCartLoading ?
                        <>
                          {prod_id === item?.id ?
                            <button className='add-cart-loader'>
                              <CircularProgress color="inherit" sx={{ width: '14px !important', height: '14px !important', color: 'white' }} />
                            </button>
                            :
                            <button className='cart-btn' onClick={() => AddToCart(item)}>ADD To Cart</button>
                          }
                        </>
                        :
                        <button className='cart-btn' onClick={() => AddToCart(item)}>ADD To Cart</button>
                    }
                    {item.has_sizes === true ?
                      <p>{item?.currency}{item?.size_price?.min} - {item?.currency}{item?.size_price?.max}</p>
                      :
                      <p>{item?.currency}{item?.price}</p>
                    }
                  </Box>
                </div>
              </div>
            )}
          </div>
        </Container>
      }
    </Box>
  )
}
