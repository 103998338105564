import * as React from 'react'
import { styled } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { Container } from '@mui/system'
import './productAccordion.css'
import { useSelector } from 'react-redux'
import { getProductdetailsData } from '../../selector'
import { Html } from '@mui/icons-material';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "1.5rem"}} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

export default function ProductsAccordions() {
  const [expanded, setExpanded] = React.useState('panel1')
  const ProductdetailsData = useSelector(getProductdetailsData)
  const product = ProductdetailsData?.product

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Container className='container'>
      <div className='product-accordion-details'>
        <Accordion className={`${product?.prod_code === '' || product?.prod_code === undefined ? 'hidden' : 'block'}`} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary className="product-accordian-summary" aria-controls='panel1d-content' id='panel1d-header'>
            <Typography className='product-accordion-title'>Product Code</Typography>
          </AccordionSummary>
          <AccordionDetails className='product-accordion-body'>
            <Typography className='product-accordion-detail'>
              {product?.prod_code}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={`${product?.gtin === '' || product?.gtin === undefined ? 'hidden' : 'block'}`} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary className="product-accordian-summary" aria-controls='panel2d-content' id='panel2d-header'>
            <Typography className='product-accordion-title'>GTIN</Typography>
          </AccordionSummary>
          <AccordionDetails className='product-accordion-body'>
            <Typography className='product-accordion-detail'>
              {product?.gtin}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion  className={`${product?.pro_weight === '' || product?.pro_weight === undefined ? 'hidden' : 'block'}`} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary className="product-accordian-summary" aria-controls='panel3d-content' id='panel3d-header'>
            <Typography className='product-accordion-title'>Product Weight</Typography>
          </AccordionSummary>
          <AccordionDetails className='product-accordion-body'>
            <Typography className='product-accordion-detail'>
              {product?.pro_weight}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={`${product?.pro_size === '' || product?.pro_size === undefined ? 'hidden' : 'block'}`} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary className="product-accordian-summary" aria-controls='panel4d-content' id='panel4d-header'>
            <Typography className='product-accordion-title'>Product Size</Typography>
          </AccordionSummary>
          <AccordionDetails className='product-accordion-body'>
            <Typography className='product-accordion-detail'>
              {product?.pro_size}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={`${product?.sku === '' || product?.sku === undefined ? 'hidden' : 'block'}`} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary className="product-accordian-summary" aria-controls='panel5d-content' id='panel5d-header'>
            <Typography className='product-accordion-title'>SKU</Typography>
          </AccordionSummary>
          <AccordionDetails className='product-accordion-body'>
            <Typography className='product-accordion-detail'>
              {product?.sku}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={`${product?.dimensions === '' || product?.dimensions === undefined ? 'hidden' : 'block'}`} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary className="product-accordian-summary" aria-controls='panel6d-content' id='panel6d-header'>
            <Typography className='product-accordion-title'>Product Dimensions</Typography>
          </AccordionSummary>
          <AccordionDetails className='product-accordion-body'>
            <Typography className='product-accordion-detail'>
              {product?.dimensions}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={`${product?.ingredients?.html === '' || product?.ingredients?.html === undefined ? 'hidden' : 'block'}`} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary className="product-accordian-summary" aria-controls='panel7d-content' id='panel7d-header'>
            <Typography className='product-accordion-title'>Product Ingredients</Typography>
          </AccordionSummary>
          <AccordionDetails className='product-accordion-body'>
            <Typography
        dangerouslySetInnerHTML={{ __html: product?.ingredients?.html }}
      />
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  )
}
