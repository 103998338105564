import * as React from 'react'
import CheckoutForm from '../CheckoutForm/CheckoutForm'
import './Checkout.css'

export default function Checkout() {
  return (
    <div>
      <CheckoutForm />
    </div>
  )
}
