import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link } from 'react-router-dom'
import './BrowseCategory.css'
import Grid from '@mui/material/Grid'
import Categoryicon1 from './img/Frame.png'
import Categoryicon2 from './img/Frame (1).png'
import Categoryicon3 from './img/Frame (2).png'
import Categoryicon4 from './img/Frame (3).png'

export default function BrowseCategory({ BrowseCategoryData, home_browse_by_title }) {
  return (
    <Box className='BrowseCategory-icons'>
      <Container className='container'>
        <h1 className='heading'>{home_browse_by_title?.option_value}</h1>

        <Grid container spacing={2} className='BrowseCategory-details'>
          {BrowseCategoryData?.option_value.map((item, index) => (
            <Grid item xs={12} md={3} className='BrowseCategory_content' key={index}>
              <Link to={`${item.slug}`}>
                <Box className='BrowseIcon-area'>
                  <img src={item?.image_path} alt='browsearea-image' />
                  <p>{item?.name}</p>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
