import { createSlice } from "@reduxjs/toolkit";
import {
  getCountry,
  getProfile,
  getUserProfile,
  updateUserProfile,
  changePassword,
  forgotPassword,
  contactRequest,
  wishlist,
  getCity,
  getState,
  forgotResendOTP,
  forgotPasswordPhone,
  forgotCheckOTP
} from "../../api";

const initialState = {
  isLoadingProfile: false,
  getProfile: null,
  isLoadingUserProfile: false,
  getUserProfileData: null,
  getCountryData: null,
  isCountryLoading: false,
  getStateData: null,
  isStateLoading: false,
  getCityData: null,
  isCityLoading: false,
  isLoadingChangePassword: false,
  getChangePassword: null,
  isGeneralSettingLoading: false,
  getGeneralSettingData: null,
  islogoutLoading: false,
  logoutData: null,
  getBestSellerData: null,
  isBestSellerLoading: false,
  isCurrencyLoading: false,
  getAllCurrency: null,
  isPopularProductLoading: false,
  getAllPopularProduct: null,
  isgetaboutUsLoading: false,
  isResetPassLoading: false,
  contactUsData: null,
  iscontactRequestLoading: false,
  isaddRemovefavoriteData: null,
  isaddRemovefavoriteLoading: false,
  isTestimonialData: null,
  isTestimonialLoading: false,
  isWishlistLoading: false,
  isWishlistData: null,
  isCategoriesLoading: false,
  isCategoriesData: null,
  isProductsLoading: false,
  isProductsData: null,
  isProductdetailsLoading: false,
  isProductdetailsData: null,

  isForgotResendOTP: null,
  isForgotResendOTPLoading: false,
  isForgotPasswordPhone: null,
  isForgotPasswordPhoneLoading: false,
  isForgotCheckOTP: null,
  isForgotCheckOTPLoading: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.isLoadingProfile = true;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.isLoadingProfile = false;
      state.getProfile = payload;
      if (payload) {
        localStorage.setItem("token", payload.token);
        localStorage.setItem("currency_id", payload.currency_id);
        localStorage.setItem("currency_symbol", payload.currency_symbol);
        if (payload.cart_id !== "") {
          localStorage.setItem("cart_key", payload.cart_id);
        }
      }
    },
    [getProfile.rejected]: (state) => {
      state.isLoadingProfile = false;
    },
    [getUserProfile.pending]: (state) => {
      state.isLoadingUserProfile = true;
    },
    [getUserProfile.fulfilled]: (state, { payload }) => {
      state.isLoadingUserProfile = false;
      state.getUserProfileData = payload.data;
      if (payload.data) {
        localStorage.setItem("currency_id", payload.data.currency_id);
        localStorage.setItem("currency_symbol", payload.data.currency_symbol);
      }
    },
    [getUserProfile.rejected]: (state) => {
      state.isLoadingUserProfile = false;
    },
    [updateUserProfile.pending]: (state) => {
      state.isLoadingUpdateProfile = true;
    },
    [updateUserProfile.fulfilled]: (state, { payload }) => {
      state.isLoadingUpdateProfile = false;
      state.getUserProfileData = payload.data?.data;
    },
    [updateUserProfile.rejected]: (state) => {
      state.isLoadingUpdateProfile = false;
    },
    [changePassword.pending]: (state) => {
      state.isLoadingChangePassword = true;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.isLoadingChangePassword = false;
      state.getChangePassword = payload;
    },
    [changePassword.rejected]: (state) => {
      state.isLoadingChangePassword = false;
    },

    [getCountry.pending]: (state) => {
      state.isCountryLoading = true;
      state.getStateData = []
    },
    [getCountry.fulfilled]: (state, { payload }) => {
      state.isCountryLoading = false;
      state.getCountryData = payload.data?.data;
    },
    [getCountry.rejected]: (state) => {
      state.isCountryLoading = false;
    },

    [getState.pending]: (state) => {
      state.isStateLoading = true;
      state.getCityData = []
    },
    [getState.fulfilled]: (state, { payload }) => {
      state.isStateLoading = false;
      state.getStateData = payload.data?.data;
    },
    [getState.rejected]: (state) => {
      state.isStateLoading = false;
    },

    [getCity.pending]: (state) => {
      state.isCityLoading = true;
    },
    [getCity.fulfilled]: (state, { payload }) => {
      state.isCityLoading = false;
      state.getCityData = payload.data?.data;
    },
    [getCity.rejected]: (state) => {
      state.isCityLoading = false;
    },

    [forgotPassword.pending]: (state) => {
      state.isResetPassLoading = true;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.isResetPassLoading = false;
    },
    [forgotPassword.rejected]: (state) => {
      state.isResetPassLoading = false;
    },

    [contactRequest.pending]: (state) => {
      state.iscontactRequestLoading = true;
    },
    [contactRequest.fulfilled]: (state, { payload }) => {
      state.iscontactRequestLoading = false;
      state.contactRequestData = payload?.data;
    },
    [contactRequest.rejected]: (state) => {
      state.iscontactRequestLoading = false;
    },

    [wishlist.pending]: (state) => {
      state.isWishlistLoading = true;
    },
    [wishlist.fulfilled]: (state, { payload }) => {
      state.isWishlistLoading = false;
      state.isWishlistData = payload?.data.products;
    },
    [wishlist.rejected]: (state) => {
      state.isWishlistLoading = false;
    },
    // forgot resend otp
    [forgotResendOTP.pending]: (state) => {
      state.isForgotResendOTPLoading = true;
    },
    [forgotResendOTP.fulfilled]: (state, { payload }) => {
      state.isForgotResendOTPLoading = false;
      state.isForgotResendOTP = payload?.data;
    },
    [forgotResendOTP.rejected]: (state) => {
      state.isForgotResendOTPLoading = false;
    },

    // ForgotPasswordPhone
    [forgotPasswordPhone.pending]: (state) => {
      state.isForgotPasswordPhoneLoading = true;
    },
    [forgotPasswordPhone.fulfilled]: (state, { payload }) => {
      state.isForgotPasswordPhoneLoading = false;
      state.isForgotPasswordPhone = payload.data;
    },
    [forgotPasswordPhone.rejected]: (state, { payload }) => {
      state.isForgotPasswordPhoneLoading = false;
    },

    // ForgotCheckOTP
    [forgotCheckOTP.pending]: (state) => {
      state.isForgotCheckOTPLoading = true;
    },
    [forgotCheckOTP.fulfilled]: (state, { payload }) => {
      state.isForgotCheckOTPLoading = false;
      state.isForgotCheckOTP = payload.response;
    },
    [forgotCheckOTP.rejected]: (state) => {
      state.isForgotCheckOTPLoading = false;
    },

  },
});

// Action creators are generated for each case reducer function
export const { } = profileSlice.actions;

export default profileSlice.reducer;
