import * as React from 'react'
import ProductOrder from '../ProductOrders/ProductOrders'

export default function ShippingCart() {
  return (
    <div>
      <ProductOrder />
    </div>
  )
}
