import { Box, Modal, Typography } from '@material-ui/core';
import React from 'react';

export default function ViewImageModal(props) {
  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Modal
      open={props.visiblemodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          height: '50%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          outline: 'none',
          borderRadius: '5px',
        }}
      >
        <img src={props.image} alt="Customer Image" style={{ width: '100%', height: '100%' }} />
        {/* <Typography variant="h6" component="h2" style={{ marginTop: '10px' }}>
          Customer Image
        </Typography> */}
      </Box>
    </Modal>
  );
}
