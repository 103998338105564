import { createSlice } from "@reduxjs/toolkit";
import {
    gettermsConditions,
    getprivacyPolicy,
    getaboutUs,
    getaccessibility,
    getdisclaimers,
    getfaceBook,
    getshippingPolicy,
    getcookiesPolicy
} from "../../api";

const initialState = {
    gettermsConditionsData: null,
    isgettermsConditionsLoading: false,
    getprivacyPolicyData: null,
    isgetprivacyPolicyLoading: false,
    getaboutusData: null,
    isgetaboutusDataLoading: false,
    getaccessibilityData: null,
    isgetaccessibilityLoading: false,
    getdisclaimersData: null,
    isgetdisclaimersLoading: false,
    getfaceBookData: null,
    isgetfaceBookLoading: false,
    getshippingPolicyData: null,
    isgetshippingPolicyLoading: false,
    getcookiesPolicyData: null,
    isgetcookiesPolicyLoading: false,
}

export const staticSlice = createSlice({
    name: "static",
    initialState,
    reducers: {},
    extraReducers: {
        [gettermsConditions.pending]: (state) => {
            state.isgettermsConditionsLoading = true;
        },
        [gettermsConditions.fulfilled]: (state, { payload }) => {
            state.isgettermsConditionsLoading = false;
            state.gettermsConditionsData = payload?.data;
        },
        [gettermsConditions.rejected]: (state) => {
            state.isgettermsConditionsLoading = false;
        },

        [getprivacyPolicy.pending]: (state) => {
            state.isgetprivacyPolicyLoading = true;
        },
        [getprivacyPolicy.fulfilled]: (state, { payload }) => {
            state.isgetprivacyPolicyLoading = false;
            state.getprivacyPolicyData = payload?.data;
        },
        [getprivacyPolicy.rejected]: (state) => {
            state.isgetprivacyPolicyLoading = false;
        },

        [getaboutUs.pending]: (state) => {
            state.isgetaboutusDataLoading = true;
        },
        [getaboutUs.fulfilled]: (state, { payload }) => {
            state.isgetaboutusDataLoading = false;
            state.getaboutusData = payload?.data;
        },
        [getaboutUs.rejected]: (state) => {
            state.isgetaboutusDataLoading = false;
        },

        [getaccessibility.pending]: (state) => {
            state.isgetaccessibilityLoading = true;
        },
        [getaccessibility.fulfilled]: (state, { payload }) => {
            state.isgetaccessibilityLoading = false;
            state.getaccessibilityData = payload?.data;
        },
        [getaccessibility.rejected]: (state) => {
            state.isgetaccessibilityLoading = false;
        },

        [getdisclaimers.pending]: (state) => {
            state.isgetdisclaimersLoading = true;
        },
        [getdisclaimers.fulfilled]: (state, { payload }) => {
            state.isgetdisclaimersLoading = false;
            state.getdisclaimersData = payload?.data;
        },
        [getdisclaimers.rejected]: (state) => {
            state.isgetdisclaimersLoading = false;
        },

        [getfaceBook.pending]: (state) => {
            state.isgetfaceBookLoading = true;
        },
        [getfaceBook.fulfilled]: (state, { payload }) => {
            state.isgetfaceBookLoading = false;
            state.getfaceBookData = payload?.data;
        },
        [getfaceBook.rejected]: (state) => {
            state.isgetfaceBookLoading = false;
        },

        [getshippingPolicy.pending]: (state) => {
            state.isgetshippingPolicyLoading = true;
        },
        [getshippingPolicy.fulfilled]: (state, { payload }) => {
            state.isgetshippingPolicyLoading = false;
            state.getshippingPolicyData = payload?.data;
        },
        [getshippingPolicy.rejected]: (state) => {
            state.isgetshippingPolicyLoading = false;
        },

        [getcookiesPolicy.pending]: (state) => {
            state.isgetcookiesPolicyLoading = true;
        },
        [getcookiesPolicy.fulfilled]: (state, { payload }) => {
            state.isgetcookiesPolicyLoading = false;
            state.getcookiesPolicyData = payload?.data;
        },
        [getcookiesPolicy.rejected]: (state) => {
            state.isgetcookiesPolicyLoading = false;
        },

    }
})


export default staticSlice.reducer;
