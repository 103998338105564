import { useFormik, ErrorMessage, Formik, Form } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextArea from '@material-ui/core/TextField';
import './ContactForm.css'
import { CircularProgress, InputLabel, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { contactRequest } from "../../api";
import { useDispatch, useSelector } from 'react-redux';
import { contactRequestData, iscontactRequestLoading } from '../../selector';
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet'
const validationSchema = yup.object({
  name: yup
    .string('Enter your Name')
    .required('Name Required')
    .min(3, "The name must be at least 3 characters."),
  email: yup
    .string('Enter your email')
    .min(6)
    .email('Enter a valid email')
    .required('Email Required'),
  company: yup
    .string('Enter your Company Name')
    .required('Company Name Required')
    .min(2),
  telephone: yup
    .number('Enter your telephone number')
    .min(10, 'telephone number must be at least 10 digits')
    .required('Telephone Number Required'),
  message: yup
    .string('Enter your message')
    .min(10, 'The message must be at least 10 characters.')
    .required('Message Required'),
});

const ContactForm = ({ product_id ,title=false}) => {
  const dispatch = useDispatch();
  const contactRequestLoading = useSelector(iscontactRequestLoading)
  const contactRequestDataForm = useSelector(contactRequestData)

  const submitHandler = (data) => {
    dispatch(contactRequest({
      email: data.email,
      name: data.name,
      company: data.company,
      telephone: data.telephone,
      message: data.message,
      product_id: product_id
    })).then(response => {
      if (response?.error?.message !== "Rejected") {
        formik.resetForm()
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      company: '',
      telephone: '',
      message: ''
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler
  });

  const handlePhone = (e) => {
    formik.setFieldValue("telephone", e)
  }

  useEffect(() => {
    if (!contactRequestLoading) {
      formik.resetForm()
    }
  }, [contactRequestLoading])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Helmet>
        {title?<title>Contact Us</title>:""}
        {/* <meta name="description" content={getaboutusData?.data.meta_descr} /> */}
      </Helmet>
      <form onSubmit={formik.handleSubmit} className="Contact-form ctm-contact-form">
        <Typography variant='h2' component='h2' className='form-title'>CONTACT US</Typography>
        {/* name */}
        <TextField
          className='input-field'
          fullWidth
          id="name"
          name="name"
          label="Name"
          variant="standard"
          value={formik.values.name ?? ""}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        {/* email */}
        <TextField
          className='input-field email-input'
          fullWidth
          id="email"
          name="email"
          label="Email Address"
          variant="standard"
          value={formik.values.email ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        {/* companay */}
        <TextField
          className='input-field'
          fullWidth
          id="companyName"
          variant="standard"
          name="company"
          label="Company"
          value={formik.values.company ?? ''}
          onChange={formik.handleChange}
          style={{ marginBottom: '10px' }}
          error={formik.touched.company && Boolean(formik.errors.company)}
          helperText={formik.touched.company && formik.errors.company}
        />
        {/* phone number */}
        <div className='form-phone'>
          <InputLabel htmlFor='Phone' id="standard-basic" variant="standard" sx={{ mb: '10px', mt: '25px' }} className='phone-label'>
            {formik.errors.telephone && formik.touched.telephone ? (
              <div className='phone-error' style={{ color: 'red', fontSize: '17px' }}>Telephone</div>
            ) : <div className='telephone-label' style={{ color: '#1c1b1aa1' }}>Telephone</div>}
          </InputLabel>
          <PhoneInput
            inputProps={{
              name: 'telephone',
              require: true,
              // autoFocus: true,
            }}
            className="select-phone"
            inputComponent={<TextField name='telephone' required />}
            country={'in'}
            name="telephone"
            value={formik.values.telephone ?? ''}
            onChange={handlePhone}
          />
          {(formik.values.telephone) === '' ?
            <>
              {formik.errors.telephone && formik.touched.telephone ?
                <div className='phone-error'>{formik.errors.telephone}</div> : null}
            </>
            : null}
        </div>
        {/* message */}
        <TextArea
          className='input-field'
          fullWidth
          id="message"
          name='message'
          label="Message"
          multiline
          minRows={3}
          variant="standard"
          value={formik.values.message ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />
        {contactRequestLoading ?
          <Button color="primary" variant="contained" fullWidth className='profile-btn btn-primary' >
            <CircularProgress color="inherit" />
          </Button>
          :
          <Button color="primary" variant="contained" fullWidth type="submit" className='profile-btn btn-primary' >
            Submit
          </Button>
        }

      </form>
    </>
  )
}

export default ContactForm