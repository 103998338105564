import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link } from 'react-router-dom'
import './whoWE.css'
import Grid from '@mui/material/Grid' // Grid version 1
import videoImages from './img/bg.png'
import { Player, PosterImage } from 'video-react'
import ReactPlayer from 'react-player'

export default function WhoWeAre() {
  return (
    <Box className='WhoWe-details whoWe-custom'>
      <Container className='container right-container'>
        <Box className="WhoWe-right">
          <Grid container spacing={2} className='WhoWe-right-inner'>
            <Grid item xs={12} md={6} className='WhoWe_content'>
              <Box className='video'>
                <Box className='videoWrapper whoWer-custom videoWrapper169 js-videoWrapper'>
                  <ReactPlayer
                    className='videoPoster whoweposter'
                    url="https://www.youtube.com/watch?v=sRAxVDf7EnI"
                    width="100%"
                    height="400px"
                    playing={true}
                    loop={true}
                    controls={true}
                    light={<img src={videoImages} alt='Thumbnail' className='whowe-video' />}
                  />
                  {/* <Player className='videoPoster video-custom' playsInline poster={videoImages} src='https://media.w3.org/2010/05/sintel/trailer_hd.mp4' /> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className='WhoWe_content-des'>
              <Box className='WhoWe-text'>
                <h3 className='whowe-custom-title'>Who We Are</h3>
                <p>High-quality cosmetic design and manufacturing require scientific expertise, vision, and innovation. </p>
                <p>
                  We provide a global supply of innovative, science-backed products that follow a safety-first approach, offering clients
                  custom formulations and contract manufacturing that help their brands thrive.From product development to brand
                  conceptualisation, we are by your side every step of the way to building your cosmetic empire.
                </p>
                <Link to='/about-us' className='learnMore-btn'>
                  Learn More
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box className="whoWe-custom-overlay"></Box>
    </Box>
  )
}
