export const getProfileData = (state) => {
  return state.profile.getProfile;
};

export const getProfileStatus = (state) => {
  return state.profile.isLoadingProfile;
};

export const getUserProfileData = (state) => {
  return state.profile.getUserProfileData;
};

export const getCountrySelector = (state) => {
  return state.profile.getCountryData;
};
export const CountryLoading = (state) => {
  return state.profile.isCountryLoading;
};

export const isgetStateSelector = (state) => {
  return state.profile.getStateData;
};
export const StateLoading = (state) => {
  return state.profile.isStateLoading;
};

export const isgetCityData = (state) => {
  return state.profile.getCityData;
};
export const CityLoading = (state) => {
  return state.profile.isCityLoading;
};

export const getChangePasswordStatus = (state) => {
  return state.profile.isLoadingChangePassword;
};

export const getChangePassword = (state) => {
  return state.profile.getChangePassword;
};

export const isLoadingUpdateProfile = (state) => {
  return state.profile.isLoadingUpdateProfile;
};

// Home
export const getGeneralSettingData = (state) => {
  return state.home.getGeneralSettingData;
};

export const isGeneralSettingLoading = (state) => {
  return state.home.isGeneralSettingLoading;
};
export const getWhyCustomerLoveUsData = (state) => {
  return state.home.getWhyCustomerLoveUsData;
};

export const isWhyCustomerLoveUsLoading = (state) => {
  return state.home.isWhyCustomerLoveUsLoading;
};

export const logoutData = (state) => {
  return state.home.logoutData;
};

export const islogoutLoading = (state) => {
  return state.home.islogoutLoading;
};

export const bestSellerData = (state) => {
  return state.home.getBestSellerData;
};

export const BestSellerLoading = (state) => {
  return state.home.isBestSellerLoading;
};

export const currencyData = (state) => {
  return state.home.getAllCurrency;
};

export const popularProductData = (state) => {
  return state.home.getAllPopularProduct;
};

export const PopularProductLoading = (state) => {
  return state.home.isPopularProductLoading;
};

export const addRemovefavoriteLoading = (state) => {
  return state.home.isaddRemovefavoriteLoading;
};

export const addRemovefavoriteData = (state) => {
  return state.home.isaddRemovefavoriteData;
};

export const isTestimonialLoading = (state) => {
  return state.home.isTestimonialLoading;
};

export const TestimonialsData = (state) => {
  return state.home.isTestimonialData;
};

export const searchProductLoading = (state) => {
  return state.home.issearchProductLoading;
};

export const searchProductData = (state) => {
  return state.home.issearchProductData;
};

// Static
export const termsConditionsData = (state) => {
  return state.static.gettermsConditionsData;
};

export const istermsConditionsLoading = (state) => {
  return state.static.isgettermsConditionsLoading;
};

export const privacyPolicyData = (state) => {
  return state.static.getprivacyPolicyData;
};

export const isprivacyPolicyLoading = (state) => {
  return state.static.isgetprivacyPolicyLoading;
};

export const aboutusData = (state) => {
  return state.static.getaboutusData;
};

export const isaboutusDataLoading = (state) => {
  return state.static.isgetaboutusDataLoading;
};

export const accessibilityData = (state) => {
  return state.static.getaccessibilityData;
};

export const isaccessibilityLoading = (state) => {
  return state.static.isgetaccessibilityLoading;
};

export const disclaimersData = (state) => {
  return state.static.getdisclaimersData;
};

export const isdisclaimersLoading = (state) => {
  return state.static.isgetdisclaimersLoading;
};

export const faceBookData = (state) => {
  return state.static.getfaceBookData;
};
export const isfaceBookLoading = (state) => {
  return state.static.getisgetfaceBookLoading;
};

export const shippingPolicyData = (state) => {
  return state.static.getshippingPolicyData;
};

export const isshippingPolicyLoading = (state) => {
  return state.static.isgetshippingPolicyLoading;
};

export const cookiesPolicyData = (state) => {
  return state.static.getcookiesPolicyData;
};

export const iscookiesPolicyLoading = (state) => {
  return state.static.isgetcookiesPolicyLoading;
};

export const contactRequestData = (state) => {
  return state.static.contactRequestData;
};

export const iscontactRequestLoading = (state) => {
  return state.static.iscontactRequestLoading;
};

// Profile
export const isResetPasswordLoading = (state) => {
  return state.profile.isResetPassLoading;
};

export const isWishlistLoading = (state) => {
  return state.profile.isWishlistLoading;
};

export const wishlistData = (state) => {
  return state.profile.isWishlistData;
};

// Product
export const getCategoriesData = (state) => {
  return state.product.isCategoriesData;
};

export const getCategoriesLoading = (state) => {
  return state.product.isCategoriesLoading;
};

export const getProductsData = (state) => {
  return state.product.isProductsData;
};

export const getProductsLoading = (state) => {
  return state.product.isProductsLoading;
};

export const getProductdetailsData = (state) => {
  return state.product.isProductdetailsData;
};

export const getProductdetailsLoading = (state) => {
  return state.product.isProductdetailsLoading;
};

export const recentlyViewedProductsData = (state) => {
  return state.product.isrecentlyViewedProductsData;
};

export const recentlyViewedProductsLoading = (state) => {
  return state.product.isrecentlyViewedProductsLoading;
};

export const relatedProductData = (state) => {
  return state.product.isrelatedProductData;
};

export const relatedProductLoading = (state) => {
  return state.product.isrelatedProductLoading;
};

// Cart
export const getCreateCartData = (state) => {
  return state.cart.isCreateCartData;
};

export const getCreateCartLoading = (state) => {
  return state.cart.isCreateCartLoading;
};

export const getCartItemsData = (state) => {
  return state.cart.isgetCartItemsData;
};

export const getCartItemsLoading = (state) => {
  return state.cart.isgetCartItemsLoading;
};

export const addRemoveCartItemData = (state) => {
  return state.cart.isaddRemoveCartItemData;
};

export const addRemoveCartItemLoading = (state) => {
  return state.cart.isaddRemoveCartItemLoading;
};

export const destroyCartItemData = (state) => {
  return state.cart.isdestroyCartItemData;
};

export const destroyCartItemLoading = (state) => {
  return state.cart.isdestroyCartItemLoading;
};

// Checkout
export const getcheckoutitemsData = (state) => {
  return state.checkout.isgetcheckoutitemsData;
};

export const getcheckoutitemsLoading = (state) => {
  return state.checkout.isgetcheckoutitemsLoading;
};

export const applycouponData = (state) => {
  return state.checkout.isapplycouponData;
};

export const applycouponLoading = (state) => {
  return state.checkout.isapplycouponLoading;
};

export const removecouponData = (state) => {
  return state.checkout.isremovecouponData;
};

export const removecouponLoading = (state) => {
  return state.checkout.isremovecouponLoading;
};

export const shippingcalculateLoading = (state) => {
  return state.checkout.isshippingcalculateLoading;
};

export const shippingcalculateData = (state) => {
  return state.checkout.isshippingcalculateData;
};

export const checkoutshippingcalculateLoading = (state) => {
  return state.checkout.ischeckoutshippingcalculateLoading;
};

export const checkoutshippingcalculateData = (state) => {
  return state.checkout.ischeckoutshippingcalculateData;
};

export const shippingMethodUpsLoading = (state) => {
  return state.checkout.isshippingMethodUpsLoading;
};

export const shippingMethodUpsData = (state) => {
  return state.checkout.isshippingMethodUpsData;
};

export const generatesecretLoading = (state) => {
  return state.checkout.isgeneratesecretLoading;
};

export const generatesecretData = (state) => {
  return state.checkout.isgeneratesecretData;
};

export const paypalPaymentLoading = (state) => {
  return state.checkout.ispaypalPaymentLoading;
};

export const paypalPaymentData = (state) => {
  return state.checkout.ispaypalPaymentData;
};

export const paypalCaptureLoading = (state) => {
  return state.checkout.ispaypalCaptureLoading;
};

export const paypalCaptureData = (state) => {
  return state.checkout.ispaypalCaptureData;
};

export const orderDetailsSecretLoading = (state) => {
  return state.checkout.isorderDetailsSecretLoading;
};

export const orderDetailsSecretData = (state) => {
  return state.checkout.isorderDetailsSecretData;
};

// orderHistory

export const getisorderListLoading = (state) => {
  return state.orderHistory.isorderListLoading;
};

export const getisorderListData = (state) => {
  return state.orderHistory.isorderListData;
};

export const getisorderDetailsLoading = (state) => {
  return state.orderHistory.isorderDetailsLoading;
};

export const getisorderDetailsData = (state) => {
  return state.orderHistory.isorderDetailsData;
};

//compareproduct

export const compareProductLoading = (state) => {
  return state.compareProduct.iscompareProductLoading;
};

export const compareProductData = (state) => {
  return state.compareProduct.iscompareProductData;
};

// Forgot Password Phone

export const forgotPasswordPhoneLoading = (state) => {
  return state.profile.isForgotPasswordPhoneLoading;
};

export const forgotPasswordPhoneData = (state) => {
  return state.profile.isForgotPasswordPhone;
};

// Forgot Resend OTP

export const forgotResendOTPLoading = (state) => {
  return state.profile.isForgotResendOTPLoading;
};

export const forgotResendOTPData = (state) => {
  return state.profile.isForgotResendOTP;
};

// Forgot OTP check

export const forgotCheckOTPLoading = (state) => {
  return state.profile.isForgotCheckOTPLoading;
};

export const forgotCheckOTPData = (state) => {
  return state.profile.isForgotCheckOTP;
};
// filter price

export const filterPricesLoading = (state) => {
  return state.product.isFilterPricesLoading;
};

export const filterPricesData = (state) => {
  return state.product.isFilterPricesData;
};
// filter size

export const filterSizeLoading = (state) => {
  return state.product.isFilterSizeLoading;
};

export const filterSizeData = (state) => {
  return state.product.isFilterSizeData;
};
