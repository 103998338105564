import { CircularProgress, Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import './product-detail.css'
import Grid from '@mui/material/Grid'
import Slider from 'react-slick'
import { useState, useEffect } from 'react'
import shippingIcon1 from './img/icon1.png'
import shippingIcon2 from './img/icon2.png'
import shippingIcon3 from './img/icon3.png'
import { addRemoveCartItemData, currencyData, getProductdetailsData } from '../../selector'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { createCart } from '../../api'
import { getCreateCartLoading, addRemoveCartItemLoading } from '../../selector'
import { addRemovefavorite, addRemoveCartItem } from '../../api'
import { CompareSharp } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

export default function ProductDetails() {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [slider1, setSlider1] = useState(null)
  const [slider2, setSlider2] = useState(null)
  const [images, setImages] = useState(null)
  const [price, setPrice] = useState(null)
  const [prodName, setprodName] = useState(null)
  const [product, setProduct] = useState()
  const [sub_product, setSub_product] = useState()
  const [count, setCount] = useState(1);
  const [showInput, setShowInput] = useState(false);
  const [dataArray, setDataArray] = React.useState([])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currency_symbol = localStorage.getItem('currency_symbol');
  const sizeid = location?.search?.slice(4, 10)
  const ref = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowInput(false);
    }
  };

  const CompareProduct = async (productId) => {
    const newData = [...dataArray, productId];
    let uniqueArray = [...new Set(newData)];
    await setDataArray(uniqueArray);
    navigate('/compare-product')
  };

  const ProductdetailsData = useSelector(getProductdetailsData)
  const isaddRemoveCartItemLoading = useSelector(addRemoveCartItemLoading)
  const CreateCartLoading = useSelector(getCreateCartLoading)
  const addRemoveCartItemdata = useSelector(addRemoveCartItemData)


  const settingsMain = {
    slidesToShow: images && 1,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    infinite: true,
    fade: true,
    asNavFor: '.slider-nav'
  }

  const settingsThumbs = {
    // slidesToShow: 2,
    slidesToShow: (images?.length > 1) ? 4 : 1,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: (images?.length > 1) ? 3 : 1,
        }
      }
    ]
  }

  const AddToCart = async () => {
    if (ProductdetailsData?.product?.has_sizes === true && (sub_product == undefined || sub_product == '')) {
      toast.info("Please Select Product Size")
    }
    else {
      const cart_key = localStorage.getItem('cart_key');
      if (!cart_key) {
        await dispatch(createCart());
      }
      await dispatch(addRemoveCartItem({ product_id: sub_product ?? product?.id, quantity: count }))
      navigate('/shopping-cart')
    }

  }

  const addFav = () => {
    setProduct({ ...product, favorite: !product?.favorite })

    if (product?.favorite) {
      dispatch(addRemovefavorite({
        product_id: product?.id,
        remove: true
      }));
      toast.info("Product removed from wishlist");
    } else {
      dispatch(addRemovefavorite({
        product_id: product?.id,
        add: true
      }))
      toast.success("Product Added to wishlist");
    }
  }

  const IncNum = () => {
    setCount(count + 1);
  };

  const DecNum = () => {
    if (count > 1) setCount(count - 1);
    else {
      setCount(1);
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem('compare_id');
    if (storedData) {
      setDataArray(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('compare_id', JSON.stringify(dataArray));
  }, [dataArray]);

  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
  }, [slider1, slider2])

  useEffect(() => {
    if (ProductdetailsData) {
      setImages(ProductdetailsData?.product?.images)
      setPrice(ProductdetailsData?.product?.price)
      setprodName(ProductdetailsData?.product?.name)
      setProduct(ProductdetailsData?.product)
    }
    if (ProductdetailsData?.product?.has_sizes === true) {
      setPrice(ProductdetailsData?.product?.size_price?.min + ' - ' + currency_symbol + " " + ProductdetailsData?.product?.size_price?.max)
    }
  }, [ProductdetailsData])

  useEffect(() => {
    if (sub_product !== undefined && sizeid !== undefined) {
      const data = product?.sub_product.filter((item) => item.id === +sub_product)
      setImages(data?.[0]?.images)
      setPrice(data?.[0]?.price)
      // setprodName(sub_product?.name)
    }
    else {
      setImages(ProductdetailsData?.product?.images)
      if (ProductdetailsData?.product?.has_sizes === true) {
        setPrice(ProductdetailsData?.product?.size_price?.min + ' - ' + currency_symbol + " " + ProductdetailsData?.product?.size_price?.max)
      }
      else {
        setPrice(ProductdetailsData?.product?.price)
      }
    }
  }, [sub_product, sizeid, product])

  useEffect(() => {
    if (sizeid) {
      setSub_product(sizeid)
    }
  }, [sizeid])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title >{prodName ?? "Product"}</title>
        {/* <meta name="description" content={getaboutusData?.data.meta_descr} /> */}
      </Helmet>
      <Box className='product-detail-banner'>
        <Container className='container'>
          <Grid container spacing={2} className='product-detail'>
            <Grid item xs={12} md={6} className='product-detail-slider' sx={{ display: 'flex', justifyContent: 'center' }} >
              {!images?.length ?
                <div className="slider-wrapper row">
                  <Typography> No Images Available</Typography>
                </div>
                :
                <div className='slider-wrapper row'>
                  <div className='slider-banner-image big-slider'>
                    <Slider {...settingsMain} asNavFor={nav2} ref={(slider) => setSlider1(slider)}>
                      {images && images.map((img, index) => (
                        <div className='' key={index}>
                          <img className='slick-slide-image detail-big-slider' style={{ maxHeight: "500px" }} src={img?.full_image} />
                          <Typography variant='span' component='span' sx={{ position: 'absolute', right: '20px', top: '20px' }}>
                            <CompareSharp sx={{ color: '#0f51a1', cursor: 'pointer' }} onClick={() => CompareProduct(product.id)} />
                            <button className='fav-btn'
                              onClick={addFav}
                            >
                              {product?.favorite ?
                                <svg width='28' height='24' viewBox='0 0 28 24' fill='red' xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    d='M7.81518 0.498687L7.81739 0.498835C9.89738 0.637366 11.8521 1.51052 13.3166 2.95168L13.6401 3.28707L14.0398 3.70139L14.394 3.24767L14.7076 2.84615C16.0799 1.39703 17.9846 0.530833 20.0108 0.439537C22.0368 0.348244 24.0159 1.03943 25.5199 2.35952C26.2003 3.08639 26.7269 3.93614 27.0702 4.86012C27.4162 5.7912 27.5694 6.7796 27.5212 7.76828C27.4731 8.75696 27.2245 9.72691 26.7894 10.6221C26.3543 11.5173 25.7412 12.3203 24.9849 12.9842L24.9768 12.9913L24.9691 12.9987L23.9032 14.0187L23.9031 14.0187L23.8985 14.0232L14.3903 23.3605C14.2932 23.4524 14.1607 23.5058 14.0205 23.5058C13.88 23.5058 13.7475 23.4523 13.6503 23.3602L4.10373 14.0255L3.02042 12.9286L3.00942 12.9175L2.99774 12.907C1.49633 11.566 0.603286 9.70183 0.509828 7.72375C0.416655 5.75168 1.12551 3.82162 2.48535 2.35392C3.18924 1.70324 4.02483 1.20169 4.93947 0.881822C5.85987 0.559939 6.83947 0.429349 7.81518 0.498687Z'
                                  />
                                </svg>
                                :
                                <svg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    d='M7.81518 0.498687L7.81739 0.498835C9.89738 0.637366 11.8521 1.51052 13.3166 2.95168L13.6401 3.28707L14.0398 3.70139L14.394 3.24767L14.7076 2.84615C16.0799 1.39703 17.9846 0.530833 20.0108 0.439537C22.0368 0.348244 24.0159 1.03943 25.5199 2.35952C26.2003 3.08639 26.7269 3.93614 27.0702 4.86012C27.4162 5.7912 27.5694 6.7796 27.5212 7.76828C27.4731 8.75696 27.2245 9.72691 26.7894 10.6221C26.3543 11.5173 25.7412 12.3203 24.9849 12.9842L24.9768 12.9913L24.9691 12.9987L23.9032 14.0187L23.9031 14.0187L23.8985 14.0232L14.3903 23.3605C14.2932 23.4524 14.1607 23.5058 14.0205 23.5058C13.88 23.5058 13.7475 23.4523 13.6503 23.3602L4.10373 14.0255L3.02042 12.9286L3.00942 12.9175L2.99774 12.907C1.49633 11.566 0.603286 9.70183 0.509828 7.72375C0.416655 5.75168 1.12551 3.82162 2.48535 2.35392C3.18924 1.70324 4.02483 1.20169 4.93947 0.881822C5.85987 0.559939 6.83947 0.429349 7.81518 0.498687Z'
                                    stroke='#0F51A1'
                                  />
                                </svg>
                              }
                            </button>
                          </Typography>
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className='thumbnail-slider-wrap small-slider'>
                    <Slider {...settingsThumbs} asNavFor={nav1} ref={(slider) => setSlider2(slider)}>
                      {images && images?.map((img, index) => (
                        <div className='slick-slide' key={index}>
                          <img className='slick-slide-image detail-small-slider' src={img?.thumbnail} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              }
            </Grid>
            <Grid item xs={12} md={6} className='product-detail-content'>
              <Box className='product-banner-text'>
                <h3 className='main-title'>{prodName}</h3>
                <div dangerouslySetInnerHTML={{ __html: product?.description }} />
                <Typography variant='h4' component='h4' sx={{ py: '15px' }} >
                  {price ? currency_symbol : ""} {price}
                </Typography>
                <div className='shipping-text'>
                  <div>
                    <img src={shippingIcon1} />
                    <span>Same day shipping if ordered before 12pm</span>
                  </div>
                  <div>
                    <img src={shippingIcon2} />
                    <span>100% Satisfaction Guarantee*</span>
                  </div>
                  <div>
                    <img src={shippingIcon3} />
                    <span>Global Delivery with No Restrictions</span>
                  </div>
                </div>
                <div className='select-box-text'>
                  {/* {product && product?.sub_product?.length !== 0 &&
                    <select value={sub_product} name='fullwidth' id='box__1' onChange={(e) => {
                      setSub_product(+e?.target?.value)
                    }
                    }>
                      <option value={""} >Select size</option>
                      {product?.sub_product?.map((sub, index) => (
                        <option value={sub?.id} key={index}>{sub?.name}</option>
                      ))}
                    </select>
                  } */}
                  {product && product?.sub_product?.length !== 0 &&
                    <div>
                      <h4>Select Size</h4>
                      <div className='product-sizes'>
                        {product?.sub_product?.map((item, index) => (
                          <div key={index} className="radio-wrapper">
                            <input
                              type="radio"
                              id={`size_${item.id}`}
                              name="size"
                              value={item?.id}
                              checked={sub_product === item.id}
                              onChange={() => setSub_product(item.id)}
                              className='radio-icon'
                            />
                            <label htmlFor={`size_${item.id}`} className="radio-label">{item?.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                </div>
                
                <div style={{marginTop: "20px"}}>
                  <h4>Quantity</h4>
                <div ref={ref} id='box__2' style={{
                  position: 'relative', userSelect: 'none', display: "flex",
                  maxWidth: "30%",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <button className='qty-btn' disabled={ProductdetailsData?.product?.has_sizes === true && (sub_product == undefined || sub_product == '')}>
                    <span onClick={DecNum} className='input-number-decrement counter-icon'>–</span>
                    <Typography onClick={() => setShowInput(true)} component='div' variant='div' sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', }} className='input-number'>
                      {showInput ?
                        <input 
                        className='counter-input'
                        type='text'
                        value={count} 
                        autoFocus
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            setCount(Number(value));
                          }
                          }} 
                          onBlur={() => {
                            if (count === '') {
                              setCount(1);
                            }
                            setShowInput(false);
                          }}
                          onKeyDown={(e) => {
                            if (!/^\d*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                              e.preventDefault();
                            }
                          }}
                        />
                        : <div style={{padding: "6px 15px", border: "3px solid", borderColor:"#c7c7c7", borderRadius:"2px"}}>{count}</div>}</Typography>
                    <span onClick={IncNum} className='input-number-increment counter-icon'>+</span>
                  </button>
                </div>
                </div>

                <div className='add-cart-btn'>
                  {isaddRemoveCartItemLoading || CreateCartLoading ?
                    <button className='add-cart-loader btn-primary' style={{ minWidth: '100%' }}>
                      <CircularProgress color="inherit" sx={{ width: '16px !important', height: '16px !important' }} />
                    </button>
                    :
                    <button disabled={ProductdetailsData?.product?.has_sizes === true && (sub_product == undefined || sub_product == '')} className={`${ProductdetailsData?.product?.has_sizes === true && (sub_product == undefined || sub_product == '') ? "btn-disabled" : "btn-primary"} cart-btn `} style={{ minWidth: '100%' }} onClick={AddToCart}>Add To Cart</button>
                  }
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box >
    </>
  )
}

