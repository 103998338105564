import * as React from 'react'
import HomeHero from '../homeHero/HomeHero'
import { Box } from '@mui/system'
import './style.css'
import DeliveryIcon from '../DeliverySection/DeliveryIcon'
import BestSeller from '../bestSellerProducts/bestSeller'
import WhoWeAre from '../whoWeAre/whoWE'
import BrowseCategory from '../BrowseCategory/BrowseCategory'
import PopProducts from '../popularProducts/popProducts'
import WaterBased from '../waterbased/waterBased'
import WatchOurvideo from '../watchOurVideo/watchOurVideo'
import WhyCustomer from '../whyCustomer/whyCustomer'
import { useSelector } from 'react-redux';
import { getGeneralSettingData } from '../../selector';
import ClientSays from '../clientSays/clientSays'
import { useNavigate } from 'react-router-dom'


export default function Home() {

  const generalSettingData = useSelector(getGeneralSettingData)
  const [dataArray, setDataArray] = React.useState([])
  const navigate = useNavigate()

  React.useEffect(() => {
    const storedData = localStorage.getItem('compare_id');
    if (storedData) {
      setDataArray(JSON.parse(storedData));
    }
  }, []);

  React.useEffect(() => {
    if (dataArray) {
      localStorage.setItem('compare_id', JSON.stringify(dataArray))
    }
  }, [dataArray]);

  const CompareProduct = async (productId) => {
    const newData = [...dataArray, productId];
    let uniqueArray = [...new Set(newData)];
    await setDataArray(uniqueArray);
    navigate('/compare-product')
  };

  return (
    <>

      <div>
        <HomeHero home_top_banner_content={generalSettingData?.home_top_banner_content} home_top_banner_image={generalSettingData?.home_top_banner_image} />
        <Box className='Safety-first-section'>
          <p>Safety First: The Industry Leader for Nontoxic High-performance Hair Products</p>
        </Box>
        <DeliveryIcon />
        <BestSeller onChildData={CompareProduct} />
        <WhoWeAre />
        <BrowseCategory BrowseCategoryData={generalSettingData?.home_browse_by_categories} home_browse_by_title={generalSettingData?.home_browse_by_title} />
        <PopProducts onChildData={CompareProduct} />
        <WaterBased />
        <clientSays />
        <WatchOurvideo />
        <WhyCustomer />
      </div>
    </>
  )
}
