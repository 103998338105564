import React, { useEffect } from 'react'
import './OrderHistory.css'
import { Link } from 'react-router-dom';
import { Box, Container, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { orderList } from '../../api';
import { getisorderListData, getisorderListLoading, getisorderDetailsLoading } from '../../selector';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import { Helmet } from 'react-helmet';

const OrderHistory = () => {
    const [page, setPage] = React.useState(1);
    const currency_symbol = localStorage.getItem('currency_symbol');

    const dispatch = useDispatch();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isorderListData = useSelector(getisorderListData)
    const isorderListLoading = useSelector(getisorderListLoading)


    const loadPageData = (page) => {
        dispatch(orderList({ params: { page: page } }))
        window.scrollTo(0, 0)
    }
    useEffect(() => {
        loadPageData(page)
    }, [page])

    return (
        <>
        <Helmet>
                <title>Order History</title>
        </Helmet>
            {isorderListLoading ? <Loader /> :
                <Container className='container' sx={{ minHeight: '100vh' }}>
                    <Box className='order-nav'>
                        <ul>
                            <li>
                                <Link to='/' className='category-list'>
                                    Home
                                </Link>
                            </li>
                            <span> / </span>
                            <li>
                                <Link to='/order-history' className='category-list active'>
                                    Order-History
                                </Link>
                            </li>
                        </ul>
                    </Box>
                    <h3 className='main-title' style={{ textTransform: 'uppercase', marginTop: '10px' }}>Order History</h3>
                    <div className="order-history">
                        <TableContainer>
                            <Table sx={{ minWidth: 640 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className='order-history-caption'>
                                        <TableCell sx={{ minWidth: '100px' }}>Order #</TableCell>
                                        <TableCell sx={{ minWidth: '150px' }} align="left">Date</TableCell>
                                        <TableCell sx={{ minWidth: '150px' }} align="left">Ship to</TableCell>
                                        <TableCell sx={{ minWidth: '150px' }} align="left">Order Total</TableCell>
                                        <TableCell sx={{ minWidth: '150px' }} align="left">Status</TableCell>
                                        <TableCell sx={{ minWidth: '150px' }} align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isorderListData?.orders.length === 0 ? (
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell colSpan={6} align="center" sx={{lineHeight:"3rem", fontSize:"1rem"}}>
                                                <p>No orders found</p>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <>
                                            {isorderListData?.orders.map((data, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {data.cart_id}
                                                    </TableCell>
                                                    <TableCell align="left"> {new Date(data?.date).toLocaleString()}</TableCell>
                                                    <TableCell align="left">{data?.ship_to_name}</TableCell>
                                                    <TableCell align="left">{currency_symbol} {data?.total}</TableCell>
                                                    <TableCell align="left">{data?.order_status_text}</TableCell>
                                                    <TableCell align="left" sx={{ py: '20px' }}><Link className='view-order-btn btn-primary' to={`/order-history/${data.cart_id}`}>View Order</Link></TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    )
                                    }
                                </TableBody>
                            </Table>
                            {page > 1 ? < Box className='filter-Products-pagination ctm-pagination'>
                                <Pagination className='product_pagination' page={page} size='large' count={isorderListData?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />
                            </Box> : ""}
                        </TableContainer>
                    </div>
                </Container >
            }
        </>
    )
}

export default OrderHistory