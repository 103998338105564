import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './ResetForm.css'

export default function ResetForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitHandler = (data) => {
    alert('change password successfully')
  }

  const validationSchema = Yup.object({
    password: Yup.string().min(6, 'Must be 6 characters or more').required('Password cannot be blank'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required')
  })

  const initialValues = {
    password: '',
    confirm_password: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler
  });

  return (
    <>
      <div className="reset-password-form">
        <form action className="reset-password" autoComplete="off" onSubmit={formik.handleSubmit}>
          <div className="reset-heading">
            <h3>Reset your password </h3>
            <p></p>
          </div>
          <FormControl fullWidth variant="outlined" className="login-form" sx={{ margin: "10px 0 !important" }}>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error">{formik.errors.password}</div>
            ) : null}
          </FormControl>

          <FormControl fullWidth variant="outlined"  className="login-form" sx={{ margin: "10px 0 !important" }}>
            <InputLabel htmlFor="outlined-adornment-password" sx={{fontSize: '18px'}}>Confirm password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="confirm_password"
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirm_password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
            {formik.touched.confirm_password && formik.errors.confirm_password ? (
              <div className="error">{formik.errors.confirm_password}</div>
            ) : null}
          </FormControl>

          <Box className='login-btn' sx={{ cursor: 'pointer' }}>
            <button type='submit' className='reset-btn'>
              Reset Password
            </button>
          </Box>
          <Link to='/login' className="backtoLogin">
            <ArrowBackIcon />
            Back to log in
          </Link>
        </form>
      </div>
    </>
  );
}
