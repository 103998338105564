import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Search } from '@mui/icons-material';
import { CircularProgress, InputAdornment, List, ListItem, ListItemButton, Skeleton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetails, searchProduct } from '../../api';
import { searchProductData, searchProductLoading } from '../../selector';
import { useNavigate } from 'react-router-dom';

export default function SearchBox(props) {
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [typingTimeout, setTypingTimeout] = React.useState(null);
  const [searchProducts, setsearchProducts] = React.useState(null);

  const dispatch = useDispatch()
  const currency_id = localStorage.getItem('currency_id')
  const issearchProductData = useSelector(searchProductData)
  const issearchProductLoading = useSelector(searchProductLoading)
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
    setsearchProducts(null)
    setSearchValue('')
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchInput = (e) => {
    setSearchValue(e.target.value)
  }

  const listHandler = (item) => {
    navigate(`/product/${item?.slug}`)
    dispatch(getProductDetails({ params: { currency: currency_id } }))
    handleClose()
    setSearchValue('')
  }

  const searchApi = () => {
    if (searchValue !== '') {
      const timeout = setTimeout(() => {
        dispatch(searchProduct({ params: { search: searchValue, currency: currency_id } }))
      }, 2000);
      setTypingTimeout(timeout)
      return () => clearTimeout(timeout)
    }
  }

  React.useEffect(() => {
    searchApi()
  }, [searchValue])

  React.useEffect(() => {
    return () => clearTimeout(typingTimeout);
  }, [typingTimeout])

  React.useEffect(() => {
    setsearchProducts(issearchProductData)
  }, [issearchProductData])

  return (
    <div className='search-box-main'>
      <Search sx={{ color: '#000', cursor: 'pointer',}} onClick={handleClickOpen} />
      <form>
        <Dialog open={open} onClose={handleClose} className='searchDialog'>
          <DialogTitle>
            <TextField
              autoFocus
              id="search"
              type="email"
              fullWidth
              variant="standard"
              placeholder='Search ...'
              value={searchValue}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="end">
                      <Search sx={{ color: '#ccc', mr: '10px' }} />
                    </InputAdornment>
                  </>
                ),
              }}
              onChange={handleSearchInput}
            />
          </DialogTitle>
          <DialogContent className={`search-dialog-content ${searchProducts ? 'showList' : ''}`}>
            <DialogContentText className='serach-content ctm-search-container'>
              {searchProducts === null ?
                <Typography variant='div' className='' sx={{ textAlign: 'center' }}>
                  <img src="https://cdn-icons-png.flaticon.com/512/8676/8676496.png" alt="" height={150} width={150} style={{ opacity: '0.1', display: 'flex', margin: '0 auto ' }} />
                  <h2 className='search-txt'>Search Products</h2>
                </Typography>
                :
                issearchProductLoading ?
                  <>
                    <Skeleton height={35} />
                    <Skeleton height={35} sx={{ my: '10px' }} animation="wave" />
                    <Skeleton height={35} sx={{ my: '10px' }} animation="wave" />
                    <Skeleton height={35} sx={{ mb: '10px' }} animation="wave" />
                    <Skeleton height={35} sx={{ my: '10px' }} animation="wave" />
                    <Skeleton height={35} sx={{ mb: '10px' }} animation="wave" />
                    <Skeleton height={35} sx={{ my: '10px' }} animation="wave" />
                    <Skeleton height={35} sx={{ mb: '10px' }} animation="wave" />
                    <Skeleton animation="wave" height={35}  />
                  </>
                  :
                  <List>
                    {searchProducts?.products?.map((item, index) => (
                      <Typography component='div' sx={{ display: 'flex' }}>
                        <ListItem key={index}>
                          <ListItemButton className='search-suggestion' onClick={() => listHandler(item)}>
                            {item.name}
                          </ListItemButton>
                        </ListItem>
                      </Typography>
                    ))}
                  </List>
              }
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </form>
    </div>
  );
}
