import React, { useEffect } from 'react'
import { getaccessibility } from "../../../api";
import { useDispatch, useSelector } from 'react-redux';
import { accessibilityData, isaccessibilityLoading } from '../../../selector';
import { Container, Typography } from '@mui/material';
import { Helmet } from "react-helmet";
import Loader from '../../Loader/Loader';

export default function Accessibility() {

    const dispatch = useDispatch();
    const getaccessibilityData = useSelector(accessibilityData)
    const accessibilityLoading = useSelector(isaccessibilityLoading)

    useEffect(() => {
        dispatch(getaccessibility());
    }, [])

    return (
        <>
            {accessibilityLoading && <Loader />}

            <Container maxWidth="lg">
                <Helmet>
                    <title>{getaccessibilityData?.data.browser_title}</title>
                    <meta name="description" content={getaccessibilityData?.data.meta_descr} />
                </Helmet>
                <Typography variant='h4' component='h1'>{getaccessibilityData?.data.page}</Typography>
                <div dangerouslySetInnerHTML={{ __html: getaccessibilityData?.data.content }} />
            </Container>
        </>
    )
}
