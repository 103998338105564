import * as React from 'react'
import { Box } from '@mui/system'
import TopMenu from '../Header/header'
import Footer from '../Footer/footer'
import ResetForm from '../ResetForm/ResetForm'

export default function ResetPassword() {
  return (
    <div>
      <ResetForm/>
    </div>
  )
}
