import * as React from 'react'
import { useLocation } from 'react-router-dom'
import ResponsiveAppBar from './navbar'
import LoginHeader from './LoginHeader'
import './header.css'

export default function TopMenu() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <>
      {isLoginPage ? <LoginHeader /> : <ResponsiveAppBar />}
    </>
  );
};
