import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link } from 'react-router-dom'
import './HomeHero.css'
import Grid from '@mui/material/Grid' // Grid version 1
import heroImage from './img/Mask group.png'

export default function HomeHero({ home_top_banner_content, home_top_banner_image }) {

  return (
    <Box className='homeHero-section'>
      <Container className='container'>
        <Grid container spacing={2} className='heroContent_parent'>
          <Grid item md={6} className='mrdAuto banner-custom'>
            <Box className='hero-content-area'>
              {/* <h1>Industry Leaders For High Performing Hair Replacement & Beauty Products</h1>
              <p>High-quality cosmetic design & manufacturing require scientific expertise, vision, and innovation.</p> */}
              <div dangerouslySetInnerHTML={{ __html: home_top_banner_content?.option_value }} />
              <div className='btnWrapper'>
                <Link to='/category' className='btn btnBlue'>
                  Shop Now
                </Link>
              </div>
            </Box>
          </Grid>
          <Grid item md={6} className='grid-right'>
            <Box className='hero-img'>
              <img src={home_top_banner_image?.option_value} alt='homehero' className='imgFluid w-100' />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
