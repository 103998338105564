import { Button, CircularProgress, Container, Grid, TextField } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import './ProductOrders.css'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { addRemoveCartItem, getCartItems, destroyCart, getPopularProduct } from '../../api'
import { getCartItemsData, addRemoveCartItemLoading, destroyCartItemLoading, getCartItemsLoading } from '../../selector'
import AlertDialogBox from '../AlertDialogBox/AlertDialogBox'
import remove from './img/delete.png'
import clear from './img/cartclear.png'
import UpdateIcon from '@mui/icons-material/Update';
import Loader from '../Loader/Loader'
import { Helmet } from 'react-helmet'

export default function ProductOrder() {
  const [cartItem, setCartItem] = React.useState([]);
  const [cartData, setCartData] = React.useState([]);
  const [openRemove, setOpenRemove] = React.useState(false);
  const [openClear, setOpenClear] = React.useState(false);
  const [removeProdId, setremoveProdId] = React.useState();
  const [prod_id, setProd_id] = React.useState()
  const [loading, setLoading] = React.useState()
  const [showInput, setShowInput] = React.useState(false);
  const [openInputID, setOpenInputID] = React.useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const CartItemsData = useSelector(getCartItemsData)
  const isaddRemoveCartItemLoading = useSelector(addRemoveCartItemLoading)
  const getdestroyCartItemLoading = useSelector(destroyCartItemLoading)
  const getCartItemLoading = useSelector(getCartItemsLoading)

  const ref = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowInput(false);
    }
  };

  const currency_id = localStorage.getItem('currency_id');

  const IncNum = (prod) => {
    setCartItem(cartItem?.map(item => (item.id === prod.id ? { ...item, quantity: +prod.quantity + 1 } : item)));
  };

  const DecNum = (prod) => {
    if (prod.quantity > 1)
      setCartItem(cartItem?.map(item => (item.id === prod.id ? { ...item, quantity: +prod.quantity - 1 } : item)));
    else {
      setCartItem(cartItem?.map(item => (item.id === prod.id ? { ...item, quantity: 1 } : item)));
    }
  };

  const updateQty = async (prod) => {
    setProd_id(prod?.id)
    await dispatch(addRemoveCartItem({ product_id: prod?.id, quantity: prod?.quantity, cart: 1 }))
    await dispatch(getCartItems({ params: { currency: currency_id } }))
  }

  const removeToCart = async (prod) => {
    setOpenRemove(true);
    setremoveProdId(prod?.id)
  }

  const removeItemHandler = async () => {
    await dispatch(addRemoveCartItem({ product_id: removeProdId, quantity: 0 }))
    setOpenRemove(false);
    await dispatch(getCartItems({ params: { currency: currency_id } }))
  }

  const ClearCart = () => {
    setOpenClear(true)
  }

  const clearCartHandler = async () => {
    await dispatch(destroyCart())
    setCartData([])
    setCartItem([])
    setOpenClear(false)
  }

  const cartDataHandler = () => {
    setCartData(CartItemsData)
    setCartItem(CartItemsData?.items?.products)
  }

  const goBack = () => {
    navigate('/category')
  }

  React.useEffect(() => {
    cartDataHandler()
  }, [CartItemsData])

  React.useEffect(() => {
    if (!getCartItemLoading) {
      const timer = setTimeout(() => {
        setLoading(false)
      }, 1500);
      return () => clearTimeout(timer)
    } else {
      setLoading(getCartItemLoading)
    }
  }, [getCartItemLoading])

  React.useEffect(() => {
    const cart_key = localStorage.getItem('cart_key');
    if (cart_key) {
      dispatch(getCartItems({ params: { currency: currency_id } }))
    }
    cartDataHandler()
    window.scrollTo(0, 0);
  }, [])

  const handleChange = (event, id) => {
    setCartItem(cartItem?.map(item => (item.id === id ? { ...item, quantity: event.target.value } : item)));
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
    <Helmet>
        <title>Shopping Cart</title>
      </Helmet>
    <Box className='Product-orders'>
      <Container className='container'>
        <Grid item xs={12} md={12} className='filter-products_content'>
          <Box className='Category-top'>
            <Box className='category-breadcrumb'>
              <ul>
                <li>
                  <Link to='/' className='category-list'>
                    Home
                  </Link>
                </li>
                <span>/</span>
                <li>
                  <Link to='/shopping-cart' className='category-list active'>
                    Shopping Cart
                  </Link>
                </li>
              </ul>
              <h1 className='category-list-heading'>Shopping Cart</h1>
              <span>{cartItem?.length} Products</span>
            </Box>
          </Box>
        </Grid>
        {loading ?
          <Loader />
          :
          !cartItem?.length ?
            <Box height='33vw' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography component='h5' variant='h5'> No products available </Typography>
            </Box>
            :
            <>
              {cartItem?.map((prod, index) => (
                <Box className='Product-delivered-details' key={index}>
                  {prod.has_sizes ?
                    prod?.free === false ?
                      <Link to={`/product/${prod?.slug}?id=${prod?.id}`}>
                        <Box className='product-image-content'>
                          <img src={prod?.image_path} className='grid-image' />
                          <Box className='product-image-text'>
                            <h3>{prod?.name}</h3>
                            <span>{prod?.category_name}</span>
                            <p>{prod?.currency}{prod?.price}</p>
                            <p>{prod?.quantity} item</p>
                          </Box>
                        </Box>
                      </Link> :
                      <a >
                        <Box className='product-image-content'>
                          <img src={prod?.image_path} className='grid-image' />
                          <Box className='product-image-text'>
                            <h3>{prod?.name}</h3>
                            <span>{prod?.category_name}</span>
                            <p>{prod?.currency}{prod?.price}</p>
                            <p>{prod?.quantity} item</p>
                          </Box>
                        </Box>
                      </a>

                    :
                    <Link to={`/product/${prod?.slug}`}>
                      <Box className='product-image-content'>
                        <img src={prod?.image_path} className='grid-image' />
                        <Box className='product-image-text'>
                          <h3>{prod?.name}</h3>
                          <span>{prod?.category_name}</span>
                          {/* <p> {prod?.currency}{prod?.price}</p> */}
                          <p> {prod?.price == 0 ? 'FREE' : prod?.currency + prod?.price} </p>
                          <p>{prod?.quantity} item</p>
                        </Box>
                      </Box>
                    </Link>
                  }

                  <Box className='product-order'>
                    <div id='counter__box view-btn' className='counter__box'>
                      {prod?.free === false ?
                        <Button onClick={() => DecNum(prod)}>
                          <Typography variant='div' component='div' sx={{ cursor: 'pointer', m: '15px' }} className='input-number-decrement counter-icon'>–</Typography>
                        </Button> :
                        <Button>
                          <Typography variant='div' component='div' sx={{ m: '15px' }} className='input-number-decrement counter-icon'></Typography>
                        </Button>
                      }
                      {prod.free === false ?
                        openInputID === prod?.id ?
                          <Typography ref={ref} onClick={() => { setShowInput(true); setOpenInputID(prod?.id) }} sx={{ minWidth: "160px", display: "flex", justifyContent: "center" }} variant='span' component='span' className='input-number'>Quantity:   {showInput ?
                            <input style={{ width: "50px", padding: "5px", borderRadius: "5px" }} type='number' value={prod?.quantity}
                              onChange={(e) => handleChange(e, prod?.id)} /> : prod?.quantity}</Typography>
                          :
                          <Typography onClick={() => { setShowInput(true); setOpenInputID(prod?.id) }} sx={{ minWidth: "160px", display: "flex", justifyContent: "center" }} variant='span' component='span' className='input-number'>Quantity: {prod?.quantity}</Typography>
                        :
                        <Typography sx={{ minWidth: "215px", display: "flex", justifyContent: "center", paddingLeft: '30px' }} variant='span' component='span' className='input-number'>Quantity: 1</Typography>}


                      {prod?.free === false ?
                        <Button onClick={() => IncNum(prod)}>
                          <Typography variant='div' component='div' sx={{ cursor: 'pointer', m: '15px' }} className='input-number-increment counter-icon'>+</Typography>
                        </Button> :
                        <Button >
                          <Typography variant='div' component='div' sx={{ m: '15px' }} className='input-number-increment counter-icon'></Typography>
                        </Button>
                      }
                      {prod.free === false ?
                        isaddRemoveCartItemLoading ?
                          <>
                            {prod_id === prod?.id ?
                              <Button variant='div' component='div' sx={{ cursor: 'pointer', ml: '30px' }} className='input-number-increment counter-icon'>
                                <CircularProgress color="inherit" />
                              </Button>
                              :
                              <Button variant='div' component='div' sx={{ cursor: 'pointer', ml: '30px', fontWeight: '600' }} className='input-number-increment counter-icon'
                                onClick={() => updateQty(prod)}
                              >
                                <UpdateIcon sx={{ mr: '10px' }} />
                                Update
                              </Button>
                            }
                          </>
                          :
                          <Button variant='div' component='div' sx={{ cursor: 'pointer', ml: '30px', fontWeight: '600' }} className='input-number-increment counter-icon'
                            onClick={() => updateQty(prod)}
                          >
                            <UpdateIcon sx={{ mr: '10px' }} />
                            Update
                          </Button>
                        :
                        <Button variant='div' component='div' sx={{ ml: '30px', fontWeight: '600' }} className='input-number-increment counter-icon'

                        >
                          <span sx={{ mr: '10px' }} ></span>

                        </Button>
                      }
                    </div>
                    {prod?.free === false &&
                      <Button variant="outlined" color="error" className='product-order-btn' onClick={() => removeToCart(prod)} sx={{ margin: '25px', borderRadius: '15px', height: '60px', flexDirection: 'unset', gap: '20px' }} fullWidth>
                        <img src={remove} alt="remove icon" style={{ height: '20px', width: '20px' }} />
                        <div>Remove from cart</div>
                      </Button>}
                  </Box>
                </Box>
              ))}
              {cartItem?.length ?
                <>
                  <Box>
                    <div className='Checkout-calc-text'>
                      <h4>Sub Total</h4>
                      <p> {cartData?.currency_symbol} {cartData?.sub_total} </p>
                    </div>
                    {cartData?.product_discount > 0 &&
                      // <Box className='Checkout-total-text'>
                      <div className='Checkout-calc-text'>
                        <h4>Product Discount</h4>
                        <p> - {cartData?.currency_symbol} {cartData?.product_discount} </p>
                      </div>
                      // </Box>
                    }
                  </Box>
                  <Box className='Checkout-total-text'>
                    <h4>Total</h4>
                    <p>{cartData?.currency_symbol} {cartData?.total}</p>
                  </Box>
                </> : ""
              }
            </>
        }

        <Box className='checkout-shipping-btn'>
          <Button className='return-cart-btn' onClick={goBack}>
            <i className='fa-solid fa-chevron-left'></i> Return To Shopping
          </Button>
          {cartItem?.length === 0 || cartItem === undefined ?
            ""
            :
            <>
              <Button className='clear-cart-btn' sx={{ gap: '20px' }} variant="contained" color='error' onClick={ClearCart}>
                <img src={clear} alt="clear cart icon" style={{ width: '25px', height: '25px' }} />
                <div>Clear Cart</div>
              </Button>
              <Link to='/checkout' className='continue-to-shipping btn-primary'>
                Continue To Checkout
              </Link>
            </>
          }
        </Box>

        {/* Remove Dialog  */}
        {openRemove &&
          <AlertDialogBox
            open={openRemove}
            setOpen={setOpenRemove}
            title='Remove from cart'
            contenttext='Do you want to remove this item from cart?'
            isLoader={isaddRemoveCartItemLoading}
            actionButton='Remove'
            onActionClick={removeItemHandler}
          />
        }

        {/* Clear cart Dialog  */}
        {openClear &&
          <AlertDialogBox
            open={openClear}
            setOpen={setOpenClear}
            title='Clear cart'
            contenttext='Do you want to remove all item from cart?'
            isLoader={getdestroyCartItemLoading}
            actionButton='Clear all'
            onActionClick={clearCartHandler}
          />
        }

      </Container >
    </Box >
    </>
  )
}
