import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { Link } from 'react-router-dom'
import './watchOurVideo.css'
import Grid from '@mui/material/Grid' // Grid version 1
import VideoImages from './img/video-bgs.png'
import { Player, PosterImage } from 'video-react'
import ReactPlayer from 'react-player'

export default function WatchOurvideo() {
  return (
    <Box className='watch-video-details video-tutorial-custom'>
      <Container className='Container'>
        <h1 className='heading'>Watch Our Video Tutorials</h1>
        <Box className='watch-video-content'>
          <Box className='videoWrapper videoWrapper169 js-videoWrapper'>
            <ReactPlayer
              className='videoPoster'
              url='https://www.youtube.com/watch?v=sRAxVDf7EnI'
              width="100%"
              height={600}
              playing={true}
              loop={true}
              controls={true}
              light={<img src={VideoImages} alt='Thumbnail' />}
            />
            {/* <Player className='videoPoster' playsInline poster={VideoImages} src='https://vimeo.com/262095693/cb02e8f841' /> */}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
