import React, { useEffect, useLayoutEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField';
import acocuntImg from './img/account.png'
import logoImage from './img/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { getCartItems, logOut } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { currencyData, getGeneralSettingData, islogoutLoading } from '../../selector';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { AccountCircle, ArrowDropDown, FavoriteBorder, LockReset, Logout, Padding, PendingActions } from '@mui/icons-material'
import Loader from '../Loader/Loader'
import { getCartItemsData } from '../../selector'
import AlertDialogBox from '../AlertDialogBox/AlertDialogBox'
import SearchBox from './SearchBox'
import MenuDrawer from './MenuDrawer'
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

function LoginHeader() {
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false)
  const [dlt_open, setdlt_Open] = React.useState(false);
  const [currency, setCurrency] = React.useState('');
  const [currency_id, setCurrency_id] = React.useState(localStorage.getItem('currency_id') || 1);
  const [profile, setProfile] = React.useState();
  const [cartLength, setcartLength] = React.useState(0);

  const open = Boolean(profile);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const price = [0, 50]
  const CartItemsData = useSelector(getCartItemsData)
  const generalSettingData = useSelector(getGeneralSettingData)
  const token = localStorage.getItem("token")
  // const getCurrency = useSelector(currencyData)
  const logoutLoading = useSelector(islogoutLoading)
  const [manageInitalCartItem, setManageInitalCartItem] = React.useState(null);
  const cart_key = localStorage.getItem('cart_key');


  const currencyIdHandler = (item) => {
    setCurrency_id(item?.id)
    window.location.reload()
  }

  const handledlt_Close = () => {
    setdlt_Open(false);
  };

  const handleClick = (event) => {
    setProfile(event.currentTarget);
  };

  const handleClose = () => {
    setProfile(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const LogoutHandler = () => {
    dispatch(logOut())
    handledlt_Close()
  }

  const currencyChangeHandler = (e) => {
    setCurrency(e.target.value)
  }

  useEffect(() => {
    if (!token) {
      navigate("/login")
    }
  }, [token])

  useEffect(() => {

    if (CartItemsData?.items?.products?.length !== undefined) {
      setcartLength(CartItemsData?.items?.products?.length)
    }
    if (CartItemsData === null) {
      setcartLength(0)
    }

  }, [CartItemsData])

  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 50)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const cartApi = () => {
    if (cart_key) {
      const timeout = setTimeout(() => {
        dispatch(getCartItems({ params: { currency: currency_id } }))
      }, 500);
      setManageInitalCartItem(timeout)
      return () => clearTimeout(timeout)
    }
  }

  React.useEffect(() => {
    if (!cart_key) {
      cartApi()
    }
  }, [cart_key])

  React.useEffect(() => {
    return () => clearTimeout(manageInitalCartItem);
  }, [manageInitalCartItem])


  return (
    <>
      {(logoutLoading) ? <Loader /> : false}
      <AppBar position='fixed' className={`header ${isScrollValueMoreThanHeaderHeight ? 'activeSticky' : 'false'}`}>
        <Container className='header-container'>
          <Toolbar disableGutters>
            {/* <MenuDrawer /> */}
            {/* <div className='hamburger-menu'>
              <Link to='/'>
                <i className='fa-solid fa-bars'></i>
              </Link>
            </div> */}
            <div className='logo-box'>
              <Link to='/'>
                <img alt='Professional Hair labs' width={230} height={60} src={generalSettingData ? generalSettingData?.header_logo?.option_value : logoImage} />
              </Link>
            </div>
            {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className='navigationArea'>
              {generalSettingData?.header_menu?.option_value.map((page, index) => (
                <Link key={index} to={`${page.slug}`}>
                  <div
                    className='header-menu'
                  >
                    <Link to={`/${page.slug}`}>
                      {page.title_bar}
                    </Link>
                    <MenuItem key={index}
                      sx={{ display: 'flex', flexDirection: 'column' }}
                      className={`subcategor-hover ${page.sub_categories.length === 0 ? 'hidden' : 'block'}`}
                    >
                      {page.sub_categories?.map((subCategory, i) => (
                        <ListItemText
                          key={i}>
                          <Link key={index} to={`/${page.slug}/${subCategory.slug}`}>
                            {subCategory.name}
                          </Link>
                        </ListItemText>
                      ))}
                    </MenuItem>
                  </div>
                </Link>
              ))}
            </Box> */}
            {/* {
              token ?
                <>
                  <div className='contat-detail'>
                    <SearchBox />
                    <div className='desktop-only'>
                      <PersonIcon fontSize="large" sx={{ color: '#000', cursor: 'pointer' }} onClick={handleClick}> </PersonIcon>
                      <Menu
                        className="profile-menu"
                        id="basic-menu"
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <Link to='/profile' className='profile-menu-list'>
                          <MenuItem onClick={handleClose}>
                            <AccountCircle />
                            Profile Information
                          </MenuItem>
                        </Link>
                        <Link to='/change-password' className='profile-menu-list' style={{ display: 'flex', justifyContent: 'start' }}>
                          <MenuItem onClick={handleClose}>
                            <LockReset />
                            Change Password
                          </MenuItem>
                        </Link>
                        <Link to='/order-history' className='profile-menu-list'>
                          <MenuItem onClick={handleClose}>
                            <PendingActions />
                            Order History
                          </MenuItem>
                        </Link>
                        <Link to='/account/wishlist' className='profile-menu-list'>
                          <MenuItem onClick={handleClose}>
                            <FavoriteBorder />
                            Wishlist
                          </MenuItem>
                        </Link>
                        <MenuItem onClick={() => {
                          handleClose()
                          setdlt_Open(true)
                        }}
                        >
                          <Typography variant='span' component='span' className='profile-menu-list-logout'>
                            <Logout />Log Out
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                    <Link to='/shopping-cart' style={{ lineHeight: "0px", padding: "0px" }}>
                      <div className='talk desktop-only'>
                        <ShoppingBagIcon fontSize="large" sx={{ color: '#000', cursor: 'pointer' }}></ShoppingBagIcon>
                        <span className='number'>{cartLength}</span>
                      </div>
                    </Link>
                  </div>
                </>
                : ''
            } */}
          </Toolbar>
        </Container>

        {/* Logout Modal  */}
        {/* <AlertDialogBox
          open={dlt_open}
          setOpen={handledlt_Close}
          title='Do you want to logout ?'
          actionButton='Logout'
          onActionClick={LogoutHandler}
        /> */}
      </AppBar >
    </>
  )
}
export default LoginHeader
