import React, { useEffect } from 'react'
import { getshippingPolicy } from "../../../api";
import { useDispatch, useSelector } from 'react-redux';
import { shippingPolicyData, isshippingPolicyLoading } from '../../../selector';
import { Container, Typography } from '@mui/material';
import { Helmet } from "react-helmet";
import Loader from '../../Loader/Loader';

export default function ShippingPolicy() {

    const dispatch = useDispatch();
    const getshippingPolicyData = useSelector(shippingPolicyData)
    const shippingPolicyLoading = useSelector(isshippingPolicyLoading)

    useEffect(() => {
        dispatch(getshippingPolicy());
    }, [])

    return (
        <>
            {shippingPolicyLoading && <Loader />}

            <Container maxWidth="lg" sx={{pt: "60px"}}>
                <Helmet>
                    <title>{getshippingPolicyData?.data.browser_title}</title>
                    <meta name="description" content={getshippingPolicyData?.data.meta_descr} />
                </Helmet>
                <Typography variant='h4' component='h1'>{getshippingPolicyData?.data.page}</Typography>
                <div dangerouslySetInnerHTML={{ __html: getshippingPolicyData?.data.content }} />
            </Container>
        </>
    )
}
