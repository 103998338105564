import * as React from 'react'
import ProductDetails from '../product-details/ProductDetail'
import Ingredients from '../Ingredients/Ingredients'
import LongTerm from '../longTerm/longTerm'
import HowToUse from '../HowToUse/HowToUse'
import ProductsAccordions from '../productAccordion/productAccordion'
import ProductInformation from '../ProductInformation/ProductInformation'
import ContactForm from '../contactForm/ContactForm'
import { getProductDetails } from '../../api'
import { useDispatch, useSelector } from 'react-redux'
import { getProductdetailsData, getProductdetailsLoading } from '../../selector'
import Loader from '../Loader/Loader'
import RelatedProducts from '../RelatedProduct/RelatedProduct'

export default function Products() {
  const [productId, setProductId] = React.useState()
  const dispatch = useDispatch();
  const ProductdetailsLoading = useSelector(getProductdetailsLoading);
  const ProductdetailsData = useSelector(getProductdetailsData);

  React.useEffect(() => {
    if (ProductdetailsData?.product?.id !== undefined) {
      setProductId(ProductdetailsData.product.id)
    }
  }, [ProductdetailsData])


  const currency_id = localStorage.getItem('currency_id');

  React.useEffect(() => {
    dispatch(getProductDetails({ params: { currency: currency_id } }))
    window.scrollTo(0, 0);
  }, [])
  

  return (
    <>
      {ProductdetailsLoading && <Loader />}

      <div>
        <ProductDetails />
        {/* <Ingredients /> */}
        {/* <LongTerm /> */}
        <HowToUse />
        <ProductsAccordions />
        <RelatedProducts />
        <ProductInformation />
        <ContactForm product_id={productId}  />
      </div>
    </>
  )
}
